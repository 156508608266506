import { createAsyncThunk } from '@reduxjs/toolkit';
import { addFunds as addFundsApi } from 'pages/manual-trading/trading-terminal/api/add-funds';
import { updateExchangesBalances } from './update-exchange-balances';

export const addFundsThunk = createAsyncThunk(
  'trading/addFunds',
  async (formData: any, {
    dispatch, rejectWithValue, 
  }) => {
    try {
      const response = await addFundsApi(formData);
      
      await dispatch(updateExchangesBalances()).unwrap();
      
      return response;
    } catch (error) {
      if (error.response?.data?.message) {
        return rejectWithValue({
          type: 'submit',
          message: error.response.data.message,
        });
      }
      return rejectWithValue({
        type: 'submit',
        message: 'An error occurred while submitting the form',
      });
    }
  },
);

import { CSSProperties } from 'react';

export const actionItem: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  gap: 8,
  padding: '12px 40px',
  width: '100%',
};

import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { Bot } from '../types/bots.types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});
  
export const checkSkyrexCopyBots = async (botId: string): Promise<Bot[]> => {
  const response = await axios.request({
    url: '/skyrexBot/getByFilter',
    data: {
      botUuids: [botId],
    },
  });
  
  return response.data.data.skyrexBots;
};
  

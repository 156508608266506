import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  paddingTop: {
    xs: 1,
    sm: 3,
  },
  gap: {
    xs: '8px',
    md: '24px',
  },
};

import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
  
export const useErrorEffects = () => {
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    formState: {
      errors, 
    }, clearErrors, trigger, watch, 
  } = useFormContext();
  
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  const takeProfitOrderPrice = watch('takeProfitOrderPrice');
  const takeProfitTriggerPrice = watch('takeProfitTriggerPrice');
  
  // Эффект для обработки ошибок дополнительных ордеров
  useEffect(() => {
    if (errors.additionalEntryDuplicatePriceError || errors.additionalEntryInsufficientBalanceError) {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
        
      errorTimeoutRef.current = setTimeout(() => {
        clearErrors('additionalEntryInsufficientBalanceError');
        trigger();
      }, 3000);
    }
  
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, [errors.additionalEntryInsufficientBalanceError]);
  
  // Эффект для очистки ошибок при изменении цен дополнительных ордеров
  useEffect(() => {
    clearErrors('additionalEntryInsufficientBalanceError');
  }, [additionalOrderPrice, additionalTriggerPrice]);
  
  // Эффект для обработки ошибок take profit
  useEffect(() => {
    if (errors.takeProfitInvalidVolumeError) {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
        
      errorTimeoutRef.current = setTimeout(() => {
        clearErrors('takeProfitInvalidVolumeError');
        trigger();
      }, 3000);
    }
  
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, [errors.takeProfitInvalidVolumeError]);
  
  // Эффект для очистки ошибок при изменении цен take profit
  useEffect(() => {
    clearErrors('takeProfitInvalidVolumeError');
  }, [takeProfitOrderPrice, takeProfitTriggerPrice]);
};

import { SxProps, Theme } from '@mui/material';

export const list: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  '&>div:first-child>span': {
    fontWeight: 500,
  },
};

import { SxProps, Theme } from '@mui/material';

export const contentWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'normal',
    md: 'center',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  gap: 5,
};

import { Box } from '@mui/material';
import { Copy } from 'widgets';
import { Text } from 'shared/ui';
import { copyContent, copyLabelText, copyLabelWrapper } from 'shared/ui/modals/add-new-bot/styles';
import { closeTradeCodeItem, openTradeCodeItem } from '../../../consts';
import { ITab } from '../../../interfaces';

export const Indicator = (props: ITab) => {
  return (
    <Box sx={copyContent}>
      <Copy
        label={(
          <Box sx={copyLabelWrapper}>
            <Text
              type='secondary'
              styles={copyLabelText}
            >
              Create alert with the message to open a trade
            </Text>

            <Text
              type='secondary'
              styles={{
                ...copyLabelText,
                fontSize: 10,
              }}
            >
              Replace "entry1" with "entry2", "entry3", etc. and set new alerts for additional entries
            </Text>
          </Box>
        )}
        value={openTradeCodeItem(props.sourceUuid, props.secretToken)}
      />

      <Copy
        label='Create alert with the message to close a trade'
        value={closeTradeCodeItem(props.sourceUuid, props.secretToken)}
      />
    </Box>
  );
};

import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

const defaultStartDate = dayjs().subtract(2, 'day');
const defaultEndDate = dayjs();

export const getTerminalSnapshots = async (skyrexUserUuid: string, exchangeAccountUuid: string) => {
    
  const response = await axios.request({
    url: '/exchangeAccount/getByFilter',
    data: {
      table: 'exchange_account_snapshots',
      skyrexUserUuids: [skyrexUserUuid],
      exchangeAccountUuids: [exchangeAccountUuid],
      createdDateFrom: defaultStartDate,
      createdDateTo: defaultEndDate,
    },
  });
    
  return response.data;
};

import { Box } from '@mui/material';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { SingleSelect, Text } from 'shared/ui';

interface ExchangeSelectProps {
  exchanges: any[];
  selectedExchange: any;
  isLoading: boolean;
  onSelect: (value: string) => void;
}

export const ExchangeSelect = ({ 
  exchanges, 
  selectedExchange, 
  isLoading, 
  onSelect, 
}: ExchangeSelectProps) => {
  return (
    <SingleSelect
      label='Exchange account'
      options={exchanges}
      loading={isLoading}
      select={{
        //@ts-ignore
        value: {
          value: selectedExchange?.label,
          label: selectedExchange?.exchangeCode,
        },
        placeholder: 'Connect account',
        onChange: onSelect,
      }}
      optionRender={(option) => (
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text type='success'>
            {option.label}
          </Text>
          <Box
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <Text type='secondary'>
              {getCapitalizedExchangeTitle(option?.data?.exchangeCode?.split(' ')[0])}
            </Text>
          </Box>
        </Box>
      )}
      labelRender={(props) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap={1}
        >
          <Text type='success'>{props.value?.toString()}</Text>
          <Box
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            {/* @ts-ignore */}
            <Text type='secondary'>{getCapitalizedExchangeTitle(props.label)}</Text>
          </Box>
        </Box>
      )}
    />
  );
};

import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const subTitle: SxProps<Theme> = {
  fontWeight: 500,
  textAlign: 'center',
  '&>span': {
    fontSize: getFontSize(12, 16),
  },
};

import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface UseSymbolEffectsProps {
  currentSymbol: any;
}

export const useSymbolEffects = ({ 
  currentSymbol, 
}: UseSymbolEffectsProps) => {
  const {
    watch, setValue, 
  } = useFormContext();
  
  const selectedSide = watch('selectedSide');
  const prevSymbolRef = useRef(currentSymbol?.symbol);
  const prevSideRef = useRef(selectedSide);
  
  const setIsSkipBaseOrder = useCallback((value: boolean) => {
    setValue('skipBaseOrder', value);
  }, [setValue]);
  
  useEffect(() => {
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
    const isSideChanged = prevSideRef.current !== selectedSide;
    
    if (isSymbolChanged || isSideChanged) {
      setValue('currentSymbol', currentSymbol);
      setValue('addEntryEnabled', false);
      setValue('takeProfitEnabled', false);
      setValue('stopLossEnabled', false);
      setValue('additionalEntries', []);
      setValue('takeProfitEntries', []);
      setValue('baseAsset', currentSymbol.baseAsset);
      setValue('quoteAsset', currentSymbol.quoteAsset);
      setIsSkipBaseOrder(false);
    }

    prevSymbolRef.current = currentSymbol?.symbol;
    prevSideRef.current = selectedSide;
  }, [
    currentSymbol?.symbol,
    selectedSide,
    currentSymbol,
    currentSymbol?.baseAsset, 
    currentSymbol?.quoteAsset, 
    setValue,
    setIsSkipBaseOrder,
  ]);
};

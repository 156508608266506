export const HtxIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_7841_7711)'>
      <path d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='#2483FF'/>
      <path d='M8.89953 6.21204C8.89953 5.06345 8.33969 4.07564 7.91391 3.75392C7.91219 3.75298 7.88125 3.73564 7.88391 3.78157V3.78298C7.8486 6.00079 6.715 6.60189 6.09188 7.41157C4.65438 9.28017 5.99125 11.3289 7.35313 11.7075C8.11516 11.9194 7.17719 11.3325 7.05625 10.0933C6.91016 8.59532 8.9 7.45267 8.9 6.21204M9.55344 6.9672C9.54469 6.96126 9.53235 6.9572 9.52375 6.97142C9.50032 7.25064 9.215 7.84782 8.85344 8.3961C7.62688 10.2555 8.32594 11.1528 8.71891 11.6344C8.94719 11.9139 8.71891 11.6344 9.28938 11.3486C9.33407 11.3263 10.4023 10.7274 10.518 9.36298C10.63 8.04173 9.8361 7.20907 9.5536 6.96704' fill='white'/>
    </g>
    <defs>
      <clipPath id='clip0_7841_7711'>
        <rect width='16' height='16' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);

import { 
  LibrarySymbolInfo, 
  ResolutionString, 
  SubscribeBarsCallback, 
} from '../../charting_library/datafeed-api';
import { createWebSocketHandler } from '../websocket/websocket-factory';
import { CreateDataFeedParams } from './datafeed-factory';
  
export const createSubscriptionMethods = (params: CreateDataFeedParams) => ({
  subscribeBars: (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    subscriberUID: string,
  ): void => {
    params.unsubscribeFromCurrentStream(subscriberUID);
    
    const handler = createWebSocketHandler({
      ...params,
      symbolInfo,
      resolution,
      onTick,
      subscriberUID,
    });
  
    const ws = handler.connect();
    params.subscriptionsRef.current.set(subscriberUID, ws);
    params.wsRef.current = ws;
  },
  
  unsubscribeBars: (subscriberUID: string): void => {
    params.unsubscribeFromCurrentStream(subscriberUID);
  },
});

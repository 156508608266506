import { formatExchangeName } from 'shared/helpers/format-exchange-name';
import { ErrorCallback } from '../../charting_library/datafeed-api';
import { supportedResolutions } from '../constants/chart-constants';
import { ExtendedLibrarySymbolInfo } from '../types/chart-view.types';
import { getExchangeCurrencyCode } from '../utils/chart-utils';
import { getExchangeResolutions } from '../utils/exchange-resolutions';
import { CreateDataFeedParams } from './datafeed-factory';

export const createSymbolMethods = ({ 
  exchangeName, 
  currentSymbol,
  exchangeOptions,
}: CreateDataFeedParams) => ({
  onReady: (callback: any) => {    
    setTimeout(
      () =>
        callback({
          exchanges: exchangeOptions,
          symbols_types: [],
          supported_resolutions: getExchangeResolutions(exchangeName),
          supports_marks: false,
          supports_time: true,
        }),
      0,
    );
  },

  searchSymbols: () => {
    return Promise.resolve();
  },

  resolveSymbol: async (
    symbolName: string, 
    onResolve: (symbolInfo: ExtendedLibrarySymbolInfo) => void, 
    onError: ErrorCallback,
  ) => {
    try {      
      const symbolInfo: ExtendedLibrarySymbolInfo = {
        name: symbolName,
        ticker: symbolName,
        minmov: 1,
        //symbol formatted for cryptocompare fallback
        pro_name: `${currentSymbol.baseAsset}-${currentSymbol.quoteAsset}`,
        pricescale: 10 ** currentSymbol?.chartPrecision,
        session: '24x7',
        timezone: 'Etc/UTC',
        has_intraday: true,
        intraday_multipliers: supportedResolutions,
        visible_plots_set: 'ohlcv',
        has_daily: true,
        has_weekly_and_monthly: true,
        type: 'crypto',
        supported_resolutions: supportedResolutions,
        full_name: getExchangeCurrencyCode(exchangeName, currentSymbol),
        description: `${currentSymbol.baseAsset}/${currentSymbol.quoteAsset}`,
        exchange: formatExchangeName(exchangeName),
        listed_exchange: formatExchangeName(exchangeName),
        format: 'price',
      };

      onResolve(symbolInfo);
    } catch (error) {
      console.error('Ошибка при разрешении символа:', error);
      onError(error);
    }
  },
});

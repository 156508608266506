import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { formatByPrecisionAndTrim } from '../helpers/helpers';

export const useTradingForm = (
  chartLastPrice: number,
  currentSymbol: any,
  userWalletData: any,
  editingTrade: any,
) => {
  
  const defaultSymbol = {
    symbol: '',
    baseAsset: '',
    quoteAsset: '',
    quoteAssetPrecision: 8,
    baseAssetPrecision: 8,
    priceMin: '0',
    priceMax: '999999999',
    lotMin: '0',
    lotMax: '999999999',
    minNotional: '0',
    maxNotional: '999999999',
  };
  
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedSide: 'buy',
      skipBaseOrder: false,
      currentSymbol: {
        ...defaultSymbol,
        ...currentSymbol,
      },
      baseAsset: currentSymbol?.baseAsset,
      quoteAsset: currentSymbol?.quoteAsset,
      // orderPrice: '',
      orderPrice: editingTrade ? editingTrade.orderPrice : '',
      userWalletQuoteAsset: userWalletData,
      orderType: 'limit',
      conditionalOrderType: 'limit',
      units: '',
      total: '',
      slider: 10,
      boughtPrice: '',
      triggerPrice: '',
      triggerPriceType: 'last',
      additionalOrderPrice: '',
      additionalOrderPricePercent: '',
      additionalTriggerPricePercent: '',
      additionalSlider: 10,
      additionalUnits: '',
      additionalTotal: '',
      additionalOrderType: 'limit',
      additionalTriggerPriceType: 'last',
      additionalEntries: [],
      takeProfitEntries: [],
      stopLossPriceRecalculation: 'average',
      takeProfitPriceRecalculation: 'average',
      takeProfitOrderPrice: '',
      takeProfitTriggerPrice: '',
      takeProfitOrderType: 'limit',
      takeProfitOrderPricePercent: '',  
      takeProfitTriggerPricePercent: '',
      takeProfitTriggerPriceType: 'last',
      takeProfitSlider: 100,
      stopLossTriggerPrice: '',
      stopLossOrderPrice: '',
      userWalletBaseAsset: {},
      stopLossOrderPricePercent: '',
      stopLossTriggerPricePercent: '',
      stopLossOrderType: 'cond.limit',
      stopLossTriggerPriceType: 'last',
      conditionalSegment: '',
      additionalEntryDuplicatePriceError: '',
      takeProfitDuplicatePriceError: '',
      additionalTriggerPrice: '',
      addEntryEnabled: false,
      takeProfitEnabled: false,
      stopLossEnabled: false,
      ...(editingTrade || {}),
    },
  });

  const {
    watch, setValue, trigger,
  } = methods;
  
  useEffect(() => {
    setValue('userWalletQuoteAsset', userWalletData);
  }, [userWalletData, setValue]);
  
  const selectedSide = watch('selectedSide');
  
  const isFirstRender = useRef(true);
  const prevSymbolRef = useRef(currentSymbol?.symbol);
  const prevSideRef = useRef(selectedSide);

  const prevChartPriceRef = useRef(chartLastPrice);
  
  useEffect(() => {
    if (editingTrade) {
      console.log('=====editingTrade', editingTrade);
      debugger;
  
      // Устанавливаем значения из editingTrade
      setValue('orderPrice', formatByPrecisionAndTrim(editingTrade.orderPrice.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.priceMin, currentSymbol?.priceMax));
      setValue('units', formatByPrecisionAndTrim(editingTrade.units.toString(), currentSymbol?.baseAssetPrecision, currentSymbol?.lotMin, currentSymbol?.lotMax));
      setValue('total', formatByPrecisionAndTrim(editingTrade.total.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.minNotional, currentSymbol?.maxNotional));
      setValue('slider', editingTrade.slider);
      setValue('selectedSide', editingTrade.selectedSide);
  
      if (editingTrade.addEntryEnabled) {
        setValue('addEntryEnabled', editingTrade.addEntryEnabled);
        setValue('additionalEntries', editingTrade.additionalEntries);
      }
  
      if (editingTrade.takeProfitEnabled) {
        setValue('takeProfitEnabled', editingTrade.takeProfitEnabled);
        setValue('takeProfitEntries', editingTrade.takeProfitEntries);
      }
      
      debugger;
      
  
      if (editingTrade.stopLossEnabled) {
        setValue('stopLossEnabled', editingTrade.stopLossEnabled);
        setValue('stopLossOrderPrice', formatByPrecisionAndTrim(editingTrade.stopLossOrderPrice.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.priceMin, currentSymbol?.priceMax));
        setValue('stopLossTriggerPrice', formatByPrecisionAndTrim(editingTrade.stopLossTriggerPrice.toString(), currentSymbol?.quoteAssetPrecision, currentSymbol?.priceMin, currentSymbol?.priceMax));
        setValue('stopLossOrderPricePercent', editingTrade.stopLossOrderPricePercent);
        setValue('stopLossTriggerPricePercent', editingTrade.stopLossTriggerPricePercent);
        setValue('stopLossTriggerPriceType', editingTrade.stopLossTriggerPriceType);
        setValue('stopLossOrderType', editingTrade.stopLossOrderType);
        
        setValue('stopLossOrderUuid', editingTrade.stopLossOrderUuid);
        setValue('initialStopLossOrderPrice', editingTrade.stopLossOrderPrice);
        setValue('initialStopLossTriggerPrice', editingTrade.stopLossTriggerPrice);
        setValue('initialStopLossOrderPricePercent', editingTrade.stopLossOrderPricePercent);
        setValue('initialStopLossTriggerPricePercent', editingTrade.stopLossTriggerPricePercent);
        setValue('initialStopLossTriggerPriceType', editingTrade.stopLossTriggerPriceType);
        setValue('initialStopLossOrderType', editingTrade.stopLossOrderType); 
      }
      
      // Сохраняем те же значения как изначальные
      setValue('initialOrderPrice', editingTrade.orderPrice);
      setValue('initialUnits', editingTrade.units);
      setValue('initialTotal', editingTrade.total);
      setValue('initialSlider', editingTrade.slider);
      setValue('initialSelectedSide', editingTrade.selectedSide);
      setValue('initialCurrentSymbol', editingTrade.currentSymbol);
      setValue('initialAdditionalEntries', editingTrade.additionalEntries || []);
      setValue('initialTakeProfitEntries', editingTrade.takeProfitEntries || []);
      setValue('initialAddEntryEnabled', editingTrade.addEntryEnabled);
      setValue('initialTakeProfitEnabled', editingTrade.takeProfitEnabled);
      setValue('initialStopLossEnabled', editingTrade.stopLossEnabled);
      setValue('baseOrderUuid', editingTrade.baseOrderUuid);
      
      
      debugger;
      // Устанавливаем UUID для редактирования
      setValue('smartTradeUuid', editingTrade.smartTradeUuid);
      // Установите другие значения из editingTrade, если необходимо
      return; // Выходим из useEffect, чтобы не выполнять остальную логику
    }
    
    
    //@TODO устанавливаем дефолты для формы, если изменились символ или сторона
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
    const isSideChanged = prevSideRef.current !== selectedSide;
    const isValidPrice = chartLastPrice > 0;

    if ((isFirstRender.current || isSymbolChanged || isSideChanged) && isValidPrice) {

      setValue('currentSymbol', {
        ...defaultSymbol,
        ...currentSymbol,
      });
      
      if (!editingTrade) {
        setValue('orderPrice', chartLastPrice.toString());
      }
      
      // setValue('orderPrice', chartLastPrice.toString());
      
      setValue('userWalletQuoteAsset', userWalletData);

      const updateDefaultValues = () => {
        const price = chartLastPrice || 0; 
        const additionalMultiplier = selectedSide === 'buy' ? 0.95 : 1.05;
        
        const precision = currentSymbol?.quoteAssetPrecision || 8;
        const priceMin = Number(currentSymbol?.priceMin || 0);
        const priceMax = Number(currentSymbol?.priceMax || 999999999);

        setValue('additionalOrderPrice', formatByPrecisionAndTrim(
          (price * additionalMultiplier).toString(),
          precision,
          priceMin,
          priceMax,
        ));
        
        // Для take profit - противоположная логика относительно выбранной стороны
        const takeProfitMultiplier = selectedSide === 'buy' ? 1.1 : 0.9;

        setValue('takeProfitOrderPrice', formatByPrecisionAndTrim(
          (price * takeProfitMultiplier).toString(),
          precision,
          priceMin,
          priceMax,
        ));

        setValue('takeProfitTriggerPrice', formatByPrecisionAndTrim(
          (price * takeProfitMultiplier).toString(),
          precision,
          priceMin,
          priceMax,
        ));
        
        // Для stop loss - противоположная логика относительно take profit
        const stopLossMultiplier = selectedSide === 'buy' ? 0.9 : 1.1;

        setValue('stopLossOrderPrice', formatByPrecisionAndTrim(
          (price * stopLossMultiplier).toString(),
          precision,
          priceMin,
          priceMax,
        ));
        
        setValue('stopLossTriggerPrice', formatByPrecisionAndTrim(
          (price * stopLossMultiplier).toString(),
          precision,
          priceMin,
          priceMax,
        ));
        
        setValue('currentSymbol', currentSymbol);
        setValue('additionalEntries', []);
        setValue('takeProfitEntries', []);
        setValue('addEntryEnabled', false);
        setValue('takeProfitEnabled', false);
        setValue('stopLossEnabled', false);
        
      };

      updateDefaultValues();
      isFirstRender.current = false;
    }
    trigger();
    
    prevSymbolRef.current = currentSymbol?.symbol;
    prevSideRef.current = selectedSide;
    prevChartPriceRef.current = chartLastPrice;
  }, [
    currentSymbol,
    userWalletData,
    trigger,
    chartLastPrice,
    currentSymbol?.symbol,
    selectedSide,
    currentSymbol?.quoteAssetPrecision,
    currentSymbol?.priceMin,
    currentSymbol?.priceMax,
    setValue,
    // editingTrade.total,
  ]);
  
  return methods;
};

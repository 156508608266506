export const BitmartIcon = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='8' cy='8' r='8' fill='black'/>
    <g clipPath='url(#clip0_7843_3224)'>
      <path d='M10.7509 7.8773C11.0882 7.45057 11.2713 6.92231 11.2705 6.37836C11.2705 5.04028 10.1863 4 8.84883 4H5.90819V4.30263H8.78363C9.9657 4.30263 10.924 5.21794 10.924 6.4C10.9247 6.85954 10.7769 7.30702 10.5026 7.67573L10.7509 7.8773Z' fill='white'/>
      <path d='M10.4229 7.62675C10.7447 7.29959 10.9248 6.8589 10.9243 6.39999C10.9243 5.93552 10.7398 5.49007 10.4114 5.16162C10.083 4.83317 9.63756 4.6486 9.17309 4.64853H6.68655V4.95145H9.23772C9.42518 4.95133 9.61082 4.98816 9.78404 5.05982C9.95725 5.13148 10.1147 5.23656 10.2472 5.36908C10.3798 5.50159 10.485 5.65893 10.5568 5.8321C10.6285 6.00528 10.6655 6.1909 10.6655 6.37835C10.6657 6.58649 10.6203 6.79215 10.5325 6.98084C10.4446 7.16953 10.3165 7.33666 10.157 7.47045L10.4229 7.62675Z' fill='white'/>
      <path d='M9.9432 7.39843C10.3523 7.21964 10.6655 6.83017 10.6655 6.37836C10.6655 5.75743 10.0756 5.25409 9.45466 5.25409H7.29182V5.4269H9.43246C9.94491 5.4269 10.4485 5.84341 10.4485 6.35672C10.4485 6.77267 10.1189 7.12541 9.72057 7.24356L9.9432 7.39843Z' fill='white'/>
      <path d='M5.04327 11.6973H5.519V12H5.04327V11.6973Z' fill='white'/>
      <path d='M5.519 11.0918H7.42164V11.3079H5.519V11.0918Z' fill='white'/>
      <path d='M5.519 9.75146H7.55146V9.92456H5.519V9.75146Z' fill='white'/>
      <path d='M7.94064 9.75146H9.238V9.92456H7.94064V9.75146Z' fill='white'/>
      <path d='M5.90819 9.1459H6.6V9.319H5.90819V9.1459Z' fill='white'/>
      <path d='M6.98918 9.1459H9.23772V9.319H6.98918V9.1459Z' fill='white'/>
      <path d='M7.72427 6.42163H9.19444V6.59473H7.72427V6.42163Z' fill='white'/>
      <path d='M5.99474 5.25409H6.81637V5.42719H5.99474V5.25409Z' fill='white'/>
      <path d='M6.21082 5.90262H9.23772V6.07571H6.21082V5.90262Z' fill='white'/>
      <path d='M5.43246 6.42163H7.29182V6.59473H5.43246V6.42163Z' fill='white'/>
      <path d='M5.95146 11.6541V11.9567H9.23773C10.5758 11.9567 11.6594 10.9159 11.6594 9.57834C11.6594 8.93609 11.4042 8.32013 10.9501 7.86599C10.4959 7.41184 9.87998 7.15671 9.23773 7.15671H7.11901V7.41635H9.1731C9.74078 7.41643 10.2852 7.64199 10.6866 8.04343C11.088 8.44487 11.3135 8.98931 11.3135 9.55699C11.3135 10.7393 10.3552 11.6544 9.1731 11.6544L5.95146 11.6541Z' fill='white'/>
      <path d='M6.47018 7.80524V8.10815H9.6269C9.81431 8.10812 9.99989 8.145 10.173 8.21669C10.3462 8.28838 10.5035 8.39348 10.6361 8.52598C10.7686 8.65849 10.8737 8.8158 10.9454 8.98894C11.0172 9.16208 11.0541 9.34765 11.0541 9.53506C11.0541 9.91357 10.9037 10.2766 10.6361 10.5442C10.3684 10.8119 10.0054 10.9622 9.6269 10.9622H7.76754V11.3082H9.56114C9.79113 11.3082 10.0189 11.2628 10.2313 11.1748C10.4438 11.0868 10.6369 10.9578 10.7995 10.7951C10.9621 10.6325 11.0911 10.4394 11.1791 10.2269C11.2671 10.0144 11.3123 9.78669 11.3123 9.5567C11.3123 9.09223 11.1278 8.64678 10.7994 8.31833C10.471 7.98987 10.0256 7.80531 9.56114 7.80524H6.47018Z' fill='white'/>
      <path d='M7.37836 8.45407V8.62688H9.82164C10.3341 8.62688 10.8377 9.04339 10.8377 9.5567C10.8377 10.0692 10.3352 10.4865 9.82164 10.4865H5V10.7026H9.84327C10.4642 10.7026 11.0541 10.1993 11.0541 9.57834C11.0541 8.95742 10.4642 8.45407 9.84327 8.45407H7.37836Z' fill='white'/>
    </g>
    <defs>
      <clipPath id='clip0_7843_3224'>
        <rect width='6.65936' height='8' fill='white' transform='translate(5 4)'/>
      </clipPath>
    </defs>
  </svg>

);

import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { exportToCsv } from 'shared/helpers';
import { getUserEmail } from 'shared/helpers/storage-helper';
import { ExportGreen } from 'shared/icons';
import { Loader, MainButton, RangePicker, SingleSelect, Table, Title } from 'shared/ui';
import { getSubscriptions, getTotalSales, getWithDrawals } from '../api';
import { Card, WithDraw } from '../components';
import { columns } from '../consts';
import { IProviderPayout } from '../interfaces';
import { button, inner, tableTitle, tableWrapper, title, titleWrapper, wrapper } from '../styles';

export const PublicBotSales = () => {
  const [providerPayouts, setProviderPayouts] = useState<IProviderPayout[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSalesLoading, setIsSalesLoadingLoading] = useState<boolean>(true);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState<boolean>(true);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [sales, setSales] = useState<any>({
    total: 0,
    withDrawal: 0,
  });
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [subscription, setSubscription] = useState<any>(null);

  const exportHandler = () => {
    exportToCsv(providerPayouts, `${getUserEmail()}-sales`);
  };

  const datesHandler = (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    setDates(date);
  };

  const onSelectChange = async (value: any) => {
    const requireSubscription = subscriptions.find((subscription) => subscription.bot === value);
    setSubscription(requireSubscription);
  };

  const getTotalSalesHandler = async () => {
    const response = await getTotalSales();
    if (!response.success) {
      return;
    }

    setSales({
      total: response.data.totalSales,
      withDrawal: response.data.availableForWithdrawal,
    });
    setIsSalesLoadingLoading(false);
  };

  const getDrawalsHandler = async () => {
    setIsLoading(true);
    const response = await getWithDrawals(dates);
    if (!response.success)  {
      return;
    }

    setProviderPayouts(response.data?.providerPayouts || []);
    setIsLoading(false);
  };

  const getSubscriptionsHandler = async () => {
    const response = await getSubscriptions();
    const subscriptions = response?.data?.stats;
    if (!subscriptions?.length)  {
      return;
    }


    setSubscriptions(subscriptions);
    setSubscription(subscriptions[0]);
    setIsSubscriptionLoading(false);
  };
  
  const fetchData = async () => {
    setIsSalesLoadingLoading(true);
    setIsSubscriptionLoading(true);

    await getTotalSalesHandler();
    await getSubscriptionsHandler();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getDrawalsHandler();
  }, [dates]);

  const options = useMemo(() => {
    const options = subscriptions.map((subscription: any) => {
      return {
        label: subscription.bot,
        value: subscription.bot,
      };
    });

    return options;
  }, [subscriptions]);

  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        My public bots sales
      </Title>

      <Box sx={inner}>
        
        <Box
          position='relative'
          display='flex'
        >
          {isSubscriptionLoading && (
            <Loader isContentOverflow={true} />
          )}

          <Card
            title='Subscriptions'
            items={[{
              title: 'Total',
              value: subscription?.total || 0,
            }, {
              title: 'Active',
              value: subscription?.active || 0,
            }, {
              title: 'Pending',
              value: subscription?.pending || 0,
            }, {
              title: 'Unique users',
              value: subscription?.uniqueUsers || 0,
            }]}
            action={!!subscriptions?.length && (
              <SingleSelect
                select={{
                  value: subscription?.bot,
                  placeholder: '',
                  onChange: onSelectChange,
                }}
                options={options}
                maxWidth={130}
              />
            )}
          />
        </Box>

        <Box
          position='relative'
          display='flex'
        >
          {isSalesLoading && (
            <Loader isContentOverflow={true} />
          )}

          <Card
            title='Sales'
            items={[{
              title: 'Total',
              value: `${(sales?.total || 0).toFixed(2)} USDT`,
            }, {
              title: 'Withdrawn',
              value: `${((sales.total - sales?.withDrawal) || 0).toFixed(2)} USDT`,
            }, {
              title: 'Available',
              value: `${(sales.withDrawal || 0).toFixed(2)} USDT`,
            }]}
          />
        </Box>

        <WithDraw
          available={sales.withDrawal}
          fetchData={() => {
            fetchData();
            getDrawalsHandler();
          }}
        />
      </Box>

      <Box sx={tableWrapper}>
        <Box sx={titleWrapper}>
          <Title styles={tableTitle}>
            Withdrawals
          </Title>

          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            gap='20px'
            flex={1}
            justifyContent='flex-end'
          >
            <RangePicker
              value={dates!}
              handleSetNewDate={datesHandler}
            />
        
            <MainButton
              type='primary'
              ghost={true}
              icon={ExportGreen}
              styles={button}
              onClick={exportHandler}
              size='middle'
            >
              Export
            </MainButton>
          </Box>
        </Box>

        <Box position='relative'>
          {isLoading && (
            <Loader isContentOverflow={true} />
          )}
          
          <Table
            columns={columns}
            items={(providerPayouts || [])}
            itemsCount={10}
          />
        </Box>
      </Box>
    </Box>
  );
};

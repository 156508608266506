import { calculatePercentageWithCeil, calculatePercentDifference, calculateTotal, calculateUnits, formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';

interface Entry {
  additionalOrderType: string;
  price?: number;
  total: number;
  triggerPrice?: number;
  orderPrice?: number;
  units: number;
}

export const useAdditionalEntrySellInputsLogic = (
  currentSymbol: any, 
  userWallet: any, 
  userWalletBaseAsset: any, 
  watch: any, 
  setValue: any,
  getValues: any,
  setError: any,
  isSkipBaseOrder: boolean,
) => {

  console.log(userWalletBaseAsset);

  const orderPrice = watch('orderPrice');
  const isAddEntryEnabled = watch('isAddEntryEnabled');
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  const additionalOrderType = watch('additionalOrderType');
  const lastChangedField = watch('lastChangedField');
  const additionalUnits = watch('additionalUnits');
  const additionalTotal = watch('additionalTotal');
  
  const handleAdditionalTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);
        
    if (!isNaN(triggerPrice)) {
      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('additionalTriggerPricePercent', percentDifference);
      } else {
        setValue('additionalTriggerPricePercent', '0');
      }
    }
        
    if (!isNaN(triggerPrice) && additionalOrderType === 'cond.market') {
      
      
      if (lastChangedField === null || lastChangedField === 'additionalUnits') {
        const newTotal = calculateTotal(parseFloat(additionalUnits), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('additionalTotal', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(additionalTotal), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('additionalUnits', formattedUnits);
            
        const percentageValue = (parseFloat(newUnits) / userWalletBaseAsset.free) * 100;
        setValue('additionalSlider', roundPercentage(percentageValue));
      }      
    }
        
  }, [setValue, additionalTriggerPrice, orderPrice, additionalOrderType, currentSymbol?.quoteAssetPrecision, lastChangedField, additionalUnits, additionalTotal, userWalletBaseAsset.free]);
  
  const handleAdditionalOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
        
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'additionalUnits') {
        const newTotal = calculateTotal(parseFloat(additionalUnits), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('additionalTotal', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(additionalTotal), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('additionalUnits', formattedUnits);
        
        const percentageValue = (parseFloat(newUnits) / userWalletBaseAsset.free) * 100;
        setValue('additionalSlider', roundPercentage(percentageValue));
      }
    }
        
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('additionalOrderPricePercent', percentDifference.toString());
    }
        
  }, [lastChangedField, watch, setValue, orderPrice, additionalUnits, additionalTotal, userWalletBaseAsset.free]);

  
  const handleAdditionalOrderPricePercentChange = (value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newAdditionalOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedAdditionalOrderPrice = formatByPrecisionAndTrim(newAdditionalOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('additionalOrderPrice', formattedAdditionalOrderPrice);
    }
  };
  
  const handleAdditionalUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'additionalUnits');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(additionalOrderPrice), currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('additionalTotal', formattedTotal);

      const userWalletFree = parseFloat(userWalletBaseAsset.free);
      
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newUnits, userWalletFree);
      }
      setValue('additionalSlider', percentageValue);
    }
  }, [setValue, userWalletBaseAsset.free, additionalOrderPrice]);
  
  
  const handleAdditionalTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'additionalTotal');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(additionalOrderPrice), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('additionalUnits', formattedUnits);
      
      const userWalletFree = parseFloat(userWalletBaseAsset.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(parseFloat(formattedUnits), userWalletFree);
      }
      setValue('additionalSlider', percentageValue);
    }
    
  }, [setValue, userWalletBaseAsset.free, additionalOrderPrice]);
  
  const handleAdditionalSliderChange = useCallback((value: number) => {
    
    setValue('lastChangedField', 'additionalUnits');
    const newUnits = (userWalletBaseAsset.free * value) / 100;
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('additionalUnits', formattedUnits);
    
    const newTotal = calculateTotal(newUnits, parseFloat(additionalOrderPrice), currentSymbol.quoteAssetPrecision);
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('additionalTotal', formattedTotal);

  }, [userWalletBaseAsset.free, additionalOrderPrice, currentSymbol?.quoteAssetPrecision, setValue]);
  
  const handleAddEntry = useCallback(() => {
    const {
      additionalOrderType, 
      additionalOrderPrice, 
      additionalTriggerPrice, 
      additionalTotal, 
      additionalUnits, 
      additionalEntries,
      total,
    } = getValues();

    const baseTotal = isSkipBaseOrder ? 0 : parseFloat(total);

    const newEntry: Entry = {
      additionalOrderType,
      total: parseFloat(additionalTotal),
      units: parseFloat(additionalUnits),
    };

    switch (additionalOrderType) {
    case 'cond.market':
      newEntry.price = parseFloat(additionalTriggerPrice);
      newEntry.triggerPrice = parseFloat(additionalTriggerPrice);
      break;
    case 'cond.limit':
      newEntry.price = parseFloat(additionalOrderPrice);
      newEntry.triggerPrice = parseFloat(additionalTriggerPrice);
      newEntry.orderPrice = parseFloat(additionalOrderPrice);
      break;
    case 'limit':
      newEntry.price = parseFloat(additionalOrderPrice);
      newEntry.orderPrice = parseFloat(additionalOrderPrice);
      break;
    default:
      console.error('Неизвестный тип ордера');
      return;
    }

    const priceToCheck = newEntry.price;
    if (additionalEntries.some((entry: Entry) => (entry.price === priceToCheck))) {
      setError('additionalEntryDuplicatePriceError', {
        type: 'manual',
        message: 'Price assigned to another order.',
      });
      return;
    }

    const totalSum = additionalEntries.reduce((sum: number, entry: Entry) => sum + entry.total, 0) + 
      baseTotal + 
      newEntry.total;

    if (totalSum > +userWallet.free) {
      setError('additionalEntryInsufficientBalanceError', {
        type: 'manual',
        message: 'Insufficient balance to add order.',
      });
      return;
    }

    const updatedEntries = [...additionalEntries, newEntry];
    setValue('additionalEntries', updatedEntries, {
      shouldValidate: true, 
    });
    
  }, [getValues, setValue, setError, isSkipBaseOrder, userWallet.free]);

  const handleRemoveEntry = useCallback((index: number) => {
    const {
      additionalEntries, 
    } = getValues();
    const updatedEntries = additionalEntries.filter((_: any, i: number) => i !== index);
    setValue('additionalEntries', updatedEntries, {
      shouldValidate: true, 
    });
  }, [getValues, setValue]);

  
  const updateAdditionalEntryValues = useCallback(() => {
    if (isAddEntryEnabled) {
      return;
    }

    const currentOrderPrice = parseFloat(orderPrice);
    const currentAdditionalOrderPrice = currentOrderPrice * 1.05;

    const formattedAdditionalOrderPrice = formatByPrecisionAndTrim(
      currentAdditionalOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );

    setValue('additionalOrderPrice', formattedAdditionalOrderPrice);
    setValue('additionalTriggerPrice', formattedAdditionalOrderPrice);
  
    const additionalUnitsCalculated = userWalletBaseAsset.free * 0.1;
    
    const formattedAdditionalUnitsRaw = formatByPrecisionAndTrim(
      additionalUnitsCalculated.toString(),
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    );
    
    const additionalTotalCalculated = parseFloat(formattedAdditionalUnitsRaw) * parseFloat(formattedAdditionalOrderPrice);
    const formattedAdditionalTotal = formatByPrecisionAndTrim(
      additionalTotalCalculated.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
    
    const additionalUnits = calculateUnits(parseFloat(formattedAdditionalTotal), parseFloat(formattedAdditionalOrderPrice), currentSymbol.baseAssetPrecision);
    const formattedAdditionalUnits = formatByPrecisionAndTrim(
      additionalUnits.toString(),
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    );
    
    setValue('additionalTotal', formattedAdditionalTotal);
    setValue('additionalUnits', formattedAdditionalUnits);
    
    const percentDifference = calculatePercentDifference(currentAdditionalOrderPrice, currentOrderPrice);
    const roundedPercentDifference = Math.round(percentDifference);
  
    setValue('additionalOrderPricePercent', roundedPercentDifference.toString());
    setValue('additionalTriggerPricePercent', roundedPercentDifference.toString());
    setValue('additionalSlider', 10);
  }, [
    isAddEntryEnabled, 
    orderPrice,
    setValue,
    userWallet.free,
    userWalletBaseAsset.free,
    currentSymbol?.baseAssetPrecision, 
    currentSymbol?.quoteAssetPrecision,
  ]);
  
  return {
    handleAdditionalTriggerPriceChange,
    handleAdditionalOrderPriceChange,
    handleAdditionalOrderPricePercentChange,
    handleAdditionalUnitsChange,
    handleAdditionalTotalChange,
    handleAdditionalSliderChange,
    handleAddEntry,
    handleRemoveEntry,
    updateAdditionalEntryValues,
  };
};

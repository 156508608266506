import { Modal } from 'antd';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { Exchange } from 'widgets/exchanges/exchange/ui/exchange';
import { modal, modalChildren } from '../styles';

export const UpdateExchange = (props: UpdateExchangeProps & {
  platform: string;
  isUpdateModalOpen: boolean;
}) => {

  const {
    platform,
    isUpdateModalOpen, 
    closeExchangeModal, 
    exchangeAccountUuid,
    currentExchangeTitle, 
  } = props;

  const getPlatform = () => {
    const unifiedExchanges = ['binance', 'okx', 'bybit', 'gateio', 'bitmart', 'crypto-com', 'htx'];
    
    if (unifiedExchanges.includes(platform)) {
      return (
        <Exchange
          exchangeType={platform}
          isModalView
          isUpdate
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    }
    
    return null;
  };

  return (
    <Modal
      onCancel={closeExchangeModal}
      destroyOnClose={true}
      open={isUpdateModalOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      width='100%'
      centered={true}
    >
      {getPlatform()}
    </Modal>
  );
};

interface IBaseOrderType {
  label: string;
  value: 'MARKET' | 'LIMIT' | 'CONDITIONAL_MARKET' | 'CONDITIONAL_LIMIT';
};

export const baseOrderTypes: IBaseOrderType[] = [{
  label: 'Market',
  value: 'MARKET',
}, {
  label: 'Limit',
  value: 'LIMIT',
}, {
  label: 'Conditional market',
  value: 'CONDITIONAL_MARKET',
}, {
  label: 'Conditional limit',
  value: 'CONDITIONAL_LIMIT',
}];

export const OkxIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <circle cx='8' cy='8' r='8' fill='black'/>
    <path d='M5.51936 6.00003H2.07999C2.05877 6.00003 2.03843 6.00792 2.02343 6.02195C2.00843 6.03599 2 6.05502 2 6.07487V9.29312C2 9.31297 2.00843 9.33203 2.02343 9.34607C2.03843 9.36008 2.05877 9.36796 2.07999 9.36796H5.51936C5.5406 9.36796 5.56092 9.36008 5.57594 9.34607C5.59094 9.33203 5.59938 9.31297 5.59938 9.29312V6.07487C5.59938 6.05502 5.59094 6.03599 5.57594 6.02195C5.56092 6.00792 5.5406 6.00003 5.51936 6.00003ZM4.39957 8.17049C4.39957 8.19034 4.39115 8.20937 4.37615 8.22341C4.36115 8.23744 4.3408 8.24533 4.31959 8.24533H3.27977C3.25856 8.24533 3.23821 8.23744 3.22321 8.22341C3.20821 8.20937 3.19979 8.19034 3.19979 8.17049V7.19752C3.19979 7.17767 3.20821 7.15864 3.22321 7.1446C3.23821 7.13056 3.25856 7.12268 3.27977 7.12268H4.31959C4.3408 7.12268 4.36115 7.13056 4.37615 7.1446C4.39115 7.15864 4.39957 7.17767 4.39957 7.19752V8.17049Z' fill='white'/>
    <path d='M12.7196 7.12277H11.6797C11.6356 7.12277 11.5998 7.15628 11.5998 7.19762V8.17058C11.5998 8.21191 11.6356 8.24542 11.6797 8.24542H12.7196C12.7638 8.24542 12.7996 8.21191 12.7996 8.17058V7.19762C12.7996 7.15628 12.7638 7.12277 12.7196 7.12277Z' fill='white'/>
    <path d='M11.5201 6.00006H10.4803C10.4361 6.00006 10.4003 6.03357 10.4003 6.0749V7.04787C10.4003 7.0892 10.4361 7.12271 10.4803 7.12271H11.5201C11.5642 7.12271 11.6001 7.0892 11.6001 7.04787V6.0749C11.6001 6.03357 11.5642 6.00006 11.5201 6.00006Z' fill='white'/>
    <path d='M13.92 6.00006H12.8802C12.836 6.00006 12.8002 6.03357 12.8002 6.0749V7.04787C12.8002 7.0892 12.836 7.12271 12.8802 7.12271H13.92C13.9642 7.12271 14 7.0892 14 7.04787V6.0749C14 6.03357 13.9642 6.00006 13.92 6.00006Z' fill='white'/>
    <path d='M11.5201 8.24539H10.4803C10.4361 8.24539 10.4003 8.2789 10.4003 8.32023V9.29321C10.4003 9.33453 10.4361 9.36805 10.4803 9.36805H11.5201C11.5642 9.36805 11.6001 9.33453 11.6001 9.29321V8.32023C11.6001 8.2789 11.5642 8.24539 11.5201 8.24539Z' fill='white'/>
    <path d='M13.92 8.24539H12.8802C12.836 8.24539 12.8002 8.2789 12.8002 8.32023V9.29321C12.8002 9.33453 12.836 9.36805 12.8802 9.36805H13.92C13.9642 9.36805 14 9.33453 14 9.29321V8.32023C14 8.2789 13.9642 8.24539 13.92 8.24539Z' fill='white'/>
    <path d='M9.71894 6.00006H8.67913C8.63495 6.00006 8.59912 6.03357 8.59912 6.0749V7.04787C8.59912 7.0892 8.63495 7.12271 8.67913 7.12271H9.71894C9.76312 7.12271 9.79892 7.0892 9.79892 7.04787V6.0749C9.79892 6.03357 9.76312 6.00006 9.71894 6.00006Z' fill='white'/>
    <path d='M9.71894 8.24539H8.67913C8.63495 8.24539 8.59912 8.2789 8.59912 8.32023V9.29321C8.59912 9.33453 8.63495 9.36805 8.67913 9.36805H9.71894C9.76312 9.36805 9.79892 9.33453 9.79892 9.29321V8.32023C9.79892 8.2789 9.76312 8.24539 9.71894 8.24539Z' fill='white'/>
    <path d='M8.59878 7.19665C8.59878 7.1768 8.59034 7.15776 8.57535 7.14373C8.56036 7.12969 8.54001 7.12181 8.5188 7.12181H7.39899V6.07484C7.39899 6.05499 7.39055 6.03596 7.37555 6.02192C7.36056 6.00789 7.34021 6 7.319 6H6.2792C6.25796 6 6.23764 6.00789 6.22262 6.02192C6.20763 6.03596 6.19919 6.05499 6.19919 6.07484V9.29142C6.19919 9.31127 6.20763 9.3303 6.22262 9.34434C6.23764 9.35838 6.25796 9.36626 6.2792 9.36626H7.319C7.34021 9.36626 7.36056 9.35838 7.37555 9.34434C7.39055 9.3303 7.39899 9.31127 7.39899 9.29142V8.24446H8.5188C8.54001 8.24446 8.56036 8.23657 8.57535 8.22254C8.59034 8.2085 8.59878 8.18946 8.59878 8.16961V7.19665Z' fill='white'/>
  </svg>
);

import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CloseSecondaryIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { closeWrapper, inner, item, itemTitle, itemValue, wrapper } from '../styles';

export const SafetyOrders = ({
  takeProfitEntries,
  onRemoveEntry,
}: {
  takeProfitEntries: Array<{ price: number; volume: number; isCompleted?: boolean; }>;
  onRemoveEntry: (index: number) => void;
}) => {
  const {
    watch, 
  } = useFormContext();
  const currentSymbol = watch('currentSymbol');
  
  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Box sx={item}>
          <Text styles={itemTitle}>Price</Text>
          <Text styles={itemTitle}>Volume</Text>
        </Box>

        {takeProfitEntries.map((entry, index) => (
          <Box key={index} sx={item}>
            <Text styles={itemValue}>{`${entry.price} ${currentSymbol.quoteAsset}`}</Text>
            <Text styles={itemValue}>{`${entry.volume}%`}</Text>

            {!entry?.isCompleted && (
              <div style={closeWrapper} onClick={() => onRemoveEntry(index)}>
                {CloseSecondaryIcon}
              </div>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

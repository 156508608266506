import { Box } from '@mui/material';
import { ArrowLeft } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { IInfoProps } from '../interfaces';
import { backWrapper, title } from '../styles';

export const Info = (props: IInfoProps) => {
  const {
    actionBlock,
    returnToAllExchanges,
  } = props;

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap='24px'
    >
      <Title
        level={4}
        styles={title}
      >
        Connect your exchange account securely with fast OAuth 
      </Title>

      {actionBlock}

      {returnToAllExchanges && (
        <div style={backWrapper} onClick={returnToAllExchanges}>
          {ArrowLeft}
          <Text>Back to exchanges</Text>
        </div>
      )}

      {/* <Text
        type='secondary'
        styles={description}
      >
        Fast connection to verified Broker
        {Verify}
      </Text>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={2.5}
      >
        {steps.map((step: string, index: number) => (
          <Title
            key={step}
            level={5}
            styles={text}
          >
            {++index}. {step}
          </Title>
        ))}
      </Box>

      {actionBlock}

      {returnToAllExchanges && (
        <div style={backWrapper} onClick={returnToAllExchanges}>
          {ArrowLeft}
          <Text>Back to exchanges</Text>
        </div>
      )
      } */}
    </Box>
  );
};

export const CryptoIcon = (
  <svg width='100%' height='100%' viewBox='0 0 90 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7.485 0L0 4.32V12.96L7.485 17.28L14.97 12.96V4.32L7.485 0ZM7.485 0L0 4.32V12.96L7.485 17.28L14.97 12.96V4.32L7.485 0Z' fill='white'/>
    <path d='M10.44 13.905H9.375L8.1 12.735V12.135L9.42 10.875V8.88L11.145 7.755L13.11 9.24L10.44 13.905ZM6.03 10.755L6.225 8.88L5.58 7.2H9.39L8.76 8.88L8.94 10.755H7.47H6.03ZM6.9 12.735L5.625 13.92H4.545L1.86 9.24L3.84 7.77L5.58 8.88V10.875L6.9 12.135V12.735ZM4.53 3.735H10.425L11.13 6.735H3.84L4.53 3.735ZM7.485 0L0 4.32V12.96L7.485 17.28L14.97 12.96V4.32L7.485 0Z' fill='#03316C'/>
    <path d='M23.3698 12.645C21.3898 12.645 19.9348 11.1 19.9348 9.19495C19.9348 7.28995 21.4048 5.69995 23.3848 5.69995C24.6448 5.69995 25.4248 6.16495 26.0398 6.83995L25.0948 7.85996C24.6298 7.36496 24.1348 7.03496 23.3698 7.03496C22.2598 7.03496 21.4498 7.99495 21.4498 9.16495C21.4498 10.365 22.2748 11.325 23.4448 11.325C24.1648 11.325 24.6898 10.98 25.1698 10.5L26.0848 11.415C25.4248 12.12 24.6598 12.645 23.3698 12.645Z' fill='#002E73'/>
    <path d='M28.98 12.6449H27.45V5.71493H28.98V7.33494C29.4 6.34494 30.165 5.65493 31.335 5.71493V7.33494H31.245C29.91 7.33494 28.98 8.20493 28.98 9.97493V12.6449Z' fill='#002E73'/>
    <path d='M39.33 5.68494L35.955 14.5049H34.41L35.145 12.6449L32.25 5.68494H33.885L35.85 10.8299L37.74 5.68494H39.33Z' fill='#002E73'/>
    <path d='M43.8298 7.01994C42.7948 7.01994 41.8948 7.85993 41.8948 9.16493C41.8948 10.4699 42.8098 11.3099 43.8298 11.3099C44.8798 11.3099 45.7348 10.4849 45.7348 9.16493C45.7348 7.84493 44.8648 7.01994 43.8298 7.01994ZM44.1748 12.6299C43.0798 12.6299 42.3898 12.0749 41.9248 11.4599V14.5049H40.3948V5.68494H41.9248V6.92993C42.4198 6.23993 43.1098 5.68494 44.1748 5.68494C45.7498 5.68494 47.2798 6.92993 47.2798 9.14993C47.2798 11.3699 45.7648 12.6299 44.1748 12.6299Z' fill='#002E73'/>
    <path d='M50.6251 7.14V10.41C50.6251 10.995 50.9251 11.235 51.4501 11.235H52.0501V12.6H51.0001C49.8901 12.6 49.0951 12.105 49.0951 10.65V7.14H48.2551V5.83501H49.0951V4.005H50.6251V5.83501H52.0501V7.14H50.6251Z' fill='#002E73'/>
    <path d='M56.8648 7.01995C55.6348 7.01995 54.8548 7.97995 54.8548 9.14995C54.8548 10.335 55.6948 11.31 56.8798 11.31C58.1098 11.31 58.8898 10.35 58.8898 9.17995C58.9198 7.99495 58.0648 7.01995 56.8648 7.01995ZM56.8648 12.645C54.8398 12.645 53.3398 11.1 53.3398 9.19495C53.3398 7.27495 54.8548 5.69995 56.8948 5.69995C58.9348 5.69995 60.4348 7.24495 60.4348 9.16495C60.4348 11.055 58.9348 12.645 56.8648 12.645Z' fill='#002E73'/>
    <path d='M75.0299 7.01995C73.7999 7.01995 73.0199 7.97995 73.0199 9.14995C73.0199 10.335 73.8599 11.31 75.0449 11.31C76.2749 11.31 77.0549 10.35 77.0549 9.17995C77.0699 7.99495 76.2299 7.01995 75.0299 7.01995ZM75.0299 12.645C73.0049 12.645 71.5049 11.1 71.5049 9.19495C71.5049 7.27495 73.0199 5.69995 75.0599 5.69995C77.0999 5.69995 78.5999 7.24495 78.5999 9.16495C78.5999 11.055 77.0849 12.645 75.0299 12.645Z' fill='#002E73'/>
    <path d='M87.6601 5.68494C89.1151 5.68494 90.0001 6.59994 90.0001 8.23494V12.6299H88.4701V8.69993C88.4701 7.63493 87.9751 7.07993 87.1201 7.07993C86.2951 7.07993 85.6951 7.66493 85.6951 8.72994V12.6449H84.1651V8.69993C84.1651 7.66493 83.6551 7.07993 82.8151 7.07993C81.9751 7.07993 81.3901 7.70994 81.3901 8.74494V12.6449H79.8601V5.69993H81.3901V6.83993C81.8251 6.25493 82.3951 5.69993 83.4151 5.69993C84.3751 5.69993 85.0351 6.16494 85.3951 6.86994C85.9501 6.14994 86.6551 5.68494 87.6601 5.68494Z' fill='#002E73'/>
    <path d='M63.2401 11.2349V12.0149L63.1951 12.0899L62.5201 12.4649H62.4301L61.7701 12.0899L61.7251 12.0149V11.2349L61.7701 11.1599L62.4301 10.7849H62.5201L63.1951 11.1599L63.2401 11.2349Z' fill='#002E73'/>
    <path d='M67.875 12.63C65.895 12.585 64.47 11.01 64.5 9.10503C64.53 7.20003 66.03 5.64003 68.025 5.68503C69.285 5.71503 70.065 6.19503 70.665 6.88503L69.705 7.89003C69.255 7.39503 68.76 7.03503 67.995 7.02003C66.885 7.00503 66.06 7.93503 66.045 9.12003C66.015 10.32 66.825 11.295 67.995 11.31C68.715 11.325 69.255 10.995 69.735 10.515L70.635 11.445C69.96 12.15 69.18 12.66 67.875 12.63Z' fill='#002E73'/>
  </svg>
);

import { SxProps, Theme } from '@mui/material';

export const left: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: 3,
    md: '28px',
  },
  width: '100%',
  maxWidth: {
    xs: '100%',
    md: 284,
  },
};

export const HtxLogo = (
  <svg width='513' height='68' viewBox='0 0 513 198' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M84.9184 60.5942C85.5084 31.5242 69.0483 6.1942 60.4683 0.104201C60.4283 0.0642013 59.6684 -0.345796 59.7284 0.794204C59.7284 0.814204 59.7084 0.814197 59.7084 0.834197C58.8284 55.7442 30.5984 70.5442 15.2784 90.6742C-18.7817 135.424 9.63835 186.484 46.4584 196.154C47.0884 196.314 48.7884 196.824 52.0184 197.484C53.6984 197.834 54.1884 196.404 52.9584 194.274C48.5584 186.624 40.7284 173.694 39.1784 157.094C35.6484 118.544 84.2284 94.4242 84.9184 60.5942Z' fill='black'/>
    <path d='M103.478 79.2141C103.188 78.9941 102.778 79.0141 102.738 79.3941C101.958 86.3841 94.7281 100.874 85.2181 114.414C53.1981 160.084 69.3281 180.804 81.8481 195.174C84.1581 197.834 85.3081 197.254 86.5281 195.354C87.6681 193.554 89.3881 191.264 96.7881 187.754C97.9381 187.204 125.898 172.364 128.928 138.574C131.838 105.874 110.878 85.2041 103.478 79.2141Z' fill='#008CD6'/>
    <path d='M269.769 70.894V191.074H243.989V142.524H194.449V191.074H168.019V70.894H194.449V119.444H243.989V70.894H269.769Z' fill='black'/>
    <path d='M388.958 70.894V94.664H376.688H351.948V191.064H325.428V94.664H301.348H289.078V70.894H388.958Z' fill='black'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M482.838 191.194L452.538 149.374L422.468 191.014L393.068 191.054L437.638 129.704L395.568 70.894H424.948L512.098 191.194H482.838Z' fill='black'/>
    <path d='M460.778 97.7843L475.318 117.984L509.768 70.8643H480.548L460.778 97.7843Z' fill='#008CD6'/>
  </svg>
);

import { createSelector } from '@reduxjs/toolkit';
import { getNewTerminal } from './get-new-terminal';

export const getSortedActiveTrades = createSelector(
  getNewTerminal,
  (newTerminal) => ({
    activeTrades: [...newTerminal.activeTrades].sort((a, b) => 
      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
    ),
    isLoading: newTerminal.isLoading,
    error: newTerminal.error,
  }),
);

import { Rule } from 'antd/es/form';

export const tradingBalanceValidator = (minInvestment: number): Rule[] => {
  return [{
    required: true,
    message: 'Please input the trading balance',
  }, {
    validator: (_, value) => {
      if (parseFloat(value) < minInvestment) {
        return Promise.reject(new Error(`Trading balance cannot be less than ${minInvestment}`));
      }
      return Promise.resolve();
    },
  }];
};

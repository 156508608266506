export const CryptoComIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_7841_7706)'>
      <path d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='#002967'/>
      <path d='M11.1594 9.825L8 11.6484L4.84063 9.825V6.17656L8 4.35156L11.1594 6.17656V9.825ZM8 4L4.53594 6V10L8 12L11.4641 10V6L8 4ZM9.35781 5.72656H6.63594L6.32031 7.11406H9.68594L9.35781 5.72656ZM7.11875 9.025V8.10312L6.3125 7.59062L5.4 8.26875L6.64375 10.4312H7.14063L7.72813 9.88437V9.60938L7.11875 9.025ZM8.88438 7.32344H7.12188L7.41875 8.09844L7.32813 8.96875H8L8.67813 8.96562L8.59375 8.1L8.88438 7.32344ZM9.69063 7.58437L8.89375 8.10312V9.025L8.28438 9.60938V9.88437L8.87188 10.425H9.3625L10.6 8.26875L9.69063 7.58437Z' fill='white'/>
    </g>
    <defs>
      <clipPath id='clip0_7841_7706'>
        <rect width='16' height='16' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);

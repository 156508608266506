import { SxProps, Theme } from '@mui/material';

export const topWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'flex-start',
    md: 'center',
  },
  gap: '12px',
  justifyContent: 'space-between',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

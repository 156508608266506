import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const contentButton: CSSProperties = {
  height: 'auto',
  flex: 1,
  fontSize: getFontSize(12, 16),
  fontWeight: 500,
  color: '#2ECD99',
  padding: '12px 45px',
};

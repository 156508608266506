export const items = [{
  icon: '/images/onboarding/copy-bot.png',
  title: 'Copy bot',
  description: 'Copy top performing bots with protected profit sharing',
  link: '/trading-bots/marketplace',
}, {
  icon: '/images/onboarding/alert-bot.png',
  title: 'Alert bot',
  description: 'Turn webhook alerts into automated trades at exchanges',
  link: '/trading-bots/create',
}, {
  icon: '/images/onboarding/terminal.png',
  title: 'Smart terminal',
  description: 'Create multiple order trades and track live performance',
  link: '/manual-trading/trading-terminal',
}];

import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  display: 'flex'  ,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
  gap: {
    xs: 2,
    md: 3.7,
  },
  backgroundColor: '#fff',
  border: '1px solid #E9E9E9',
  borderRadius: 4,
  width: '100%',
  maxWidth: {
    xs: '100%',
    md: 366,
  },
  padding: 2,
};

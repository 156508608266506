import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface ICreateStripeTransaction {
  subscriptionUuid: string;
  table?: 'skyrex_subscriptions' | 'bot_success_subscriptions';
  paymentAsset?: 'usd' | 'usdt.trc20';
}

export const createStripeTransaction = async (params: ICreateStripeTransaction) => {
  const {
    subscriptionUuid,
    table = 'skyrex_subscriptions',
    paymentAsset = 'usd',
  } = params;

  const userId = getSkyrexUuid();

  const data = {
    skyrexUserUuid: userId,
    subscriptionUuid,
    table,
    paymentAsset,
  };

  const response = await axios.request({
    url: 'payment/createStripeTransaction',
    data,
  });

  return response.data;
};

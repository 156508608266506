import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2.5,
  padding: 2,
  backgroundColor: '#F8FBF9',
  borderRadius: 2,
};

import { Box } from '@mui/material';
import { MainButton, Text } from 'shared/ui';
import { getInviteFriendsUrl } from '../api';
import {
  background,
  button,
  left,
  right,
  title,
} from '../styles';

export const Banner = () => {
  const inviteFriends = async () => {
    const inviteFriendsUrlResponse = await getInviteFriendsUrl(window.location.href);
    if (!inviteFriendsUrlResponse.data?.url) {
      return;
    }

    window.open(inviteFriendsUrlResponse.data.url, '_self');
  };

  return (
    <Box sx={background}>
      <Box sx={left}>
        <Text
          type='success'
          styles={title}
        >
          Refer and earn!
        </Text>
        
        <Text type='secondary'>
          Invite friends and earn 25% of fees
        </Text>

        <MainButton
          type='primary'
          size='small'
          styles={button}
          onClick={inviteFriends}
        >
          Invite
        </MainButton>
      </Box>

      <Box sx={right}>
        <img src='/images/banner-bg.png' alt='banner-bg' />
      </Box>
    </Box> 
  );
};

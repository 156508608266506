import { formatReturnPercent } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { Order, SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { SliderMark, SliderMarksReturn } from '../types/active-trade.types';

export const getCurrentExchangePrice = (trade: SmartTrade): number => {
  if (trade.status === 'completed' && !trade.currentExchangePrice) {
    return parseFloat(trade.executedQuoteSecond) / parseFloat(trade.executedBaseSecond);
  }
    
  return parseFloat(trade.currentExchangePrice);
};
  
const getPriceFromOrder = (order: Order): number => {
  return order.orderType === 'LIMIT' || order.orderType === 'CONDITIONAL_LIMIT'
    ? parseFloat(order.limitPrice)
    : parseFloat(order.triggerPrice ?? '0');
};
  
export const getSliderValues = (trade: SmartTrade, showOnProgressBar: boolean): number[] => {
  if (!showOnProgressBar) {
    return [];
  }

  const x = getCurrentExchangePrice(trade);
  const y = getEntryPrice(trade);

  if (isNaN(x) || y === null || isNaN(y)) {
    return [];
  }

  return [x, y];
};

export const getEntryPrice = (trade: SmartTrade): number | null => {
  const baseOrder = trade.orders.find(order => order.viewType === 'base_order');
  const forceOrderStepFirst = trade.orders.find(order => order.viewType === 'force_order_step_first');

  if (!baseOrder) {
    return null;
  }

  if (baseOrder.orderType === 'MARKET' && (parseFloat(trade.executedQuoteFirst) === 0 || parseFloat(trade.executedBaseFirst) === 0)) {
    return null;
  }

  if (baseOrder.status === 'completed' || forceOrderStepFirst?.status === 'completed') {
    return parseFloat(trade.executedQuoteFirst) / parseFloat(trade.executedBaseFirst);
  }

  if (baseOrder.orderType === 'LIMIT' || baseOrder.orderType === 'CONDITIONAL_LIMIT') {
    return parseFloat(baseOrder.limitPrice);
  }

  return parseFloat(baseOrder.triggerPrice ?? '0');
};

export const getSliderMarks = (trade: SmartTrade): SliderMarksReturn => {
  const marks: Record<number, SliderMark> = {};
  const entryHeight = 12;
  let belowEntryHeight = entryHeight + 10;

  const labelMap: Record<string, string> = {
    additional_base_order: 'AE',
    stop_loss: 'SL',
    take_profit: 'TP',
  };

  const entryPrice = getEntryPrice(trade);

  if (entryPrice !== null) {
    marks[entryPrice] = {
      value: entryPrice,
      label: 'Entry',
      distance: entryHeight,
      isTakeProfit: false,
      isEntryPrice: true,
    };
  }

  const filteredOrders = trade.orders.filter(order => 
    order.viewType !== 'base_order' && 
    (order.status === 'new' || order.status === 'active' || order.status === 'await_send'),
  );

  const tpOrders = filteredOrders.filter(order => order.viewType === 'take_profit');
  const aeOrders = filteredOrders.filter(order => order.viewType === 'additional_base_order');
  
  const sortedTp = tpOrders.sort((a, b) => getPriceFromOrder(a) - getPriceFromOrder(b));
  const firstTpPrice = sortedTp.length > 0 ? getPriceFromOrder(sortedTp[0]) : null;
  const lastTpPrice = sortedTp.length > 0 ? getPriceFromOrder(sortedTp[sortedTp.length - 1]) : null;
  
  const sortedAe = aeOrders.sort((a, b) => getPriceFromOrder(a) - getPriceFromOrder(b));
  const firstAePrice = sortedAe.length > 0 ? getPriceFromOrder(sortedAe[0]) : null;
  const lastAePrice = sortedAe.length > 0 ? getPriceFromOrder(sortedAe[sortedAe.length - 1]) : null;

  filteredOrders.forEach(order => {
    const price = getPriceFromOrder(order);
    const isTP = order.viewType === 'take_profit';
    const isAE = order.viewType === 'additional_base_order';
    
    const isFirstOrLastTP = isTP && (price === firstTpPrice || price === lastTpPrice);
    const isFirstOrLastAE = isAE && (price === firstAePrice || price === lastAePrice);

    const isVisible = !isTP 
      && !isAE ||
      (isTP && tpOrders.length >= 2 && isFirstOrLastTP) ||
      (isAE && aeOrders.length >= 2 && isFirstOrLastAE);

    marks[price] = {
      value: price,
      label: labelMap[order.viewType] || order.viewType,
      distance: belowEntryHeight,
      isTakeProfit: order.viewType === 'take_profit',
      visible: isVisible,
    };

    if (isVisible) {
      belowEntryHeight += 10;
    }
  });

  const currentExchangePrice = getCurrentExchangePrice(trade);
  
  marks[currentExchangePrice] = {
    value: currentExchangePrice,
    label: (formatReturnPercent(trade.returnPercent) || '0'),
    distance: 25,
    isTakeProfit: false,
    isExchangePrice: true,
  };

  const prices = Object.keys(marks).map(Number);
  const lowest = Math.min(...prices);
  const highest = Math.max(...prices);

  marks[lowest] = {
    ...marks[lowest],
    exchangeSide: lowest !== currentExchangePrice,
  };

  marks[highest] = {
    ...marks[highest],
    exchangeSide: highest === currentExchangePrice,
  };

  return {
    marks, lowest, highest, entryMark: entryPrice, 
  };
};

import { Box } from '@mui/material';
import { AreaChart, ChartWrapper, PieChart } from 'widgets';
import { Update } from 'shared/icons';
import { Loader, RangePicker, Title } from 'shared/ui';
import { IAggregateBalance } from '../interfaces';
import {
  left,
  titleWrapper,
  wrapper,
} from '../styles';

export const AggregateBalance = (props: IAggregateBalance) => {
  const {
    isAreaChartLoading,
    totalBtc,
    totalUsdt,
    usdtDailyChangePercent,
    btcDailyChangePercent,
    pieData,
    areaData,
    dates,
    isPieChartLoading,
    selectedCurrency,
    handleSetNewDate,
    setCurrency,
    handleUpdateFinancialData,
  } = props;

  const getChart = (selectedSegment: string) => {
    console.log('', selectedSegment);
    
    return (
      <AreaChart
        isMainChart={true}
        data={areaData}
        loading={isAreaChartLoading}
        quoteAsset={selectedCurrency}
      />
    );
  };

  return (
    <Box sx={wrapper}>
      {(isAreaChartLoading || isPieChartLoading) && (
        <Loader isContentOverflow={true} />
      )}

      <Box sx={left}>
        <Box sx={titleWrapper}>
          <Title level={5}>Aggregate balance</Title>

          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={handleUpdateFinancialData}
          >
            {Update}
          </div>
        </Box>

        <PieChart
          data={pieData} 
          selectedCurrency={selectedCurrency}
          totalBtc={totalBtc}
          totalUsdt={totalUsdt}
          usdtDailyChangePercent={usdtDailyChangePercent}
          btcDailyChangePercent={btcDailyChangePercent}
        />
      </Box>

      <ChartWrapper
        selectedSegment={selectedCurrency}
        setSelectedSegment={setCurrency}
        segments={[{
          label: 'USDT',
          value: 'USDT',
        }, {
          label: 'BTC',
          value: 'BTC',
        }]}
        isEmpty={!areaData?.length}
        action={(
          <RangePicker
            value={dates!}
            handleSetNewDate={handleSetNewDate}
          />
        )}
      >
        {(selectedSegment: string) => getChart(selectedSegment)}
      </ChartWrapper>
    </Box>
  );
};

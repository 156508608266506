import { memo, useEffect, useRef } from 'react';
import './index.css';
import { useDispatch } from 'react-redux';
import { ChartingLibraryFeatureset, ChartingLibraryWidgetOptions, widget } from '../charting_library';
import {
  ResolutionString,
  Exchange,
} from '../charting_library/datafeed-api';
import { chartingLibraryPath } from './constants/chart-constants';
import { createCustomDataFeed } from './datafeed/datafeed-factory';

interface TradingViewChartProps {
  exchangeName: string;
  currentSymbol?: any;
}

const arePropsEqual = (prevProps: TradingViewChartProps, nextProps: TradingViewChartProps) => {
  const prevSymbol = `${prevProps.currentSymbol?.baseAsset}${prevProps.currentSymbol?.quoteAsset}`;
  const nextSymbol = `${nextProps.currentSymbol?.baseAsset}${nextProps.currentSymbol?.quoteAsset}`;
  
  const isPrecisionEqual = 
    prevProps.currentSymbol?.quoteAssetPrecision === nextProps.currentSymbol?.quoteAssetPrecision &&
    prevProps.currentSymbol?.baseAssetPrecision === nextProps.currentSymbol?.baseAssetPrecision;
  
  return prevProps.exchangeName === nextProps.exchangeName && 
         prevSymbol === nextSymbol &&
         isPrecisionEqual;
};

export const TradingViewChart = memo(({
  exchangeName, 
  currentSymbol = {
    quoteAsset: 'USDT',
    baseAsset: 'BTC',
    quoteAssetPrecision: 8,
    baseAssetPrecision: 8,
    minNotional: '0',
    maxNotional: '0',
    lotMin: '0',
    lotMax: '0',
    symbol: '',
  },
}: TradingViewChartProps) => {
  
  const dispatch = useDispatch();
  
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const subscriptionsRef = useRef<Map<string, WebSocket>>(new Map());
  
  const createExchange = (value: string): Exchange => ({
    value,
    name: value.charAt(0).toUpperCase() + value.slice(1),
    desc: `${value.charAt(0).toUpperCase() + value.slice(1)} Exchange`,
  });
  
  const exchangeOptions: Exchange[] = ['binance', 'bybit', 'okx', 'gate', 'crypto-com', 'htx'].map(createExchange);

  const unsubscribeFromCurrentStream = (subscriberUID?: string) => {
    if (subscriberUID) {
      const ws = subscriptionsRef.current.get(subscriberUID);
      if (ws) {
        ws.close();
        subscriptionsRef.current.delete(subscriberUID);
        console.log(`WebSocket connection closed for subscriber ${subscriberUID}`);
      }
    } else {
      // Отписываемся от всех подписок
      subscriptionsRef.current.forEach((ws, uid) => {
        ws.close();
        console.log(`WebSocket connection closed for subscriber ${uid}`);
      });
      subscriptionsRef.current.clear();
    }
  };
    
  const customDatafeed = createCustomDataFeed({
    exchangeName,
    currentSymbol,
    dispatch,
    wsRef,
    subscriptionsRef,
    exchangeOptions,
    unsubscribeFromCurrentStream,
  });
  
  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: currentSymbol?.tvFormatSymbol ?? 'BTCUSDT',
      // symbol: currentSymbol?.symbol ?? 'BTCUSDT',
      datafeed: customDatafeed,
      interval: '60' as ResolutionString,
      container: chartContainerRef.current as HTMLDivElement,
      library_path: chartingLibraryPath,
      locale: 'en',
      disabled_features: [
        'use_localstorage_for_settings' as ChartingLibraryFeatureset,
        'header_symbol_search' as ChartingLibraryFeatureset,
      ],
      enabled_features: ['study_templates' as ChartingLibraryFeatureset],
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      debug: true,
    };

    const tvWidget = new widget(widgetOptions);
    
    return () => {
      unsubscribeFromCurrentStream();
      if (tvWidget) {
        tvWidget.remove();
      }
    };
  }, [currentSymbol, exchangeName]);
  
  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
}, arePropsEqual);

TradingViewChart.displayName = 'TradingViewChart';

import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const button: CSSProperties = {
  height: 'auto',
  width: '100%',
  maxWidth: 269,
  padding: '8px 0',
  fontSize: getFontSize(12, 14),
  fontWeight: 500,
  pointerEvents: 'none',
};

import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { Copy } from 'widgets';
import { stringSorter } from 'shared/helpers/sorters';
// import { Copy } from 'shared/icons';
import { SortIcon, Text } from 'shared/ui';

export const columns: TableColumnsType = [{
  title: 'Amount',
  dataIndex: 'payoutAmount',
  key: 'amount',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Transaction',
  dataIndex: 'paymentId',
  key: 'transaction',
  width: 190,
  render: (value: string) => {
    if (!value) {
      return '-';
    }

    return (
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <Text
          styles={{
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Text>
  
        <Copy
          value={value}
          onlyCopy={true}
        />
      </Box>
    );
  },
  showSorterTooltip: false,
}, {
  title: 'Wallet',
  dataIndex: 'paymentMethod',
  key: 'wallet',
  width: 190,
  render: (value: string) => (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Text
        styles={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </Text>

      <Copy
        value={value}
        onlyCopy={true}
      />
    </Box>
  ),
  showSorterTooltip: false,
}, {
  title: 'Created date',
  dataIndex: 'createdDate',
  key: 'createDate',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => (
    <Text>
      {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Paid date',
  dataIndex: 'paymentDate',
  key: 'paidDate',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => {
    if (!value) {
      return '-';
    }

    return (
      <Text>
        {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
      </Text>
    );
  },
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => {
    const color = value === 'paid' ? 'success' : 'warning';
    
    return (
      <Text
        type={color}
        styles={{
          textTransform: 'capitalize',
        }}
      >
        {value}
      </Text>
    );
  },
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}];

import { Text } from 'shared/ui';

export const ErrorMessage = (props: any) => {
  return (
    <Text
      type='danger'
      styles={{
        fontSize: '10px',
      }}
    >
      {props?.message as string}
    </Text>
  );
};

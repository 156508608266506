import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { IMenuItemChildren } from 'widgets/header/interfaces';
import { Text, Title } from 'shared/ui';
import { text, title } from '../../../styles';

export const ItemNavLink = (props: IMenuItemChildren) => {
  const {
    label,
    link,
    icon,
    description,
  } = props;

  return (
    <NavLink to={link}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          padding: '8px 0',
        }}
      >
        {icon}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          <Title
            level={5}
            styles={title}
          >
            {label}
          </Title>

          <Text
            type='secondary'
            styles={text}
          >
            {description}
          </Text>
        </Box>
      </Box>
    </NavLink>
  );
};

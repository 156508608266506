import { CSSProperties } from 'react';

export const closeWrapper: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: 12,
  height: 12,
  position: 'absolute',
  right: 0,
};

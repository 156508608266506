import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface UseFormUpdatesProps {
  updateAdditionalEntryValues: () => void;
  updateTakeProfitValues: () => void;
  updateStopLossValues: () => void;
}

export const useFormUpdates = ({
  updateAdditionalEntryValues,
  updateTakeProfitValues,
  updateStopLossValues,
}: UseFormUpdatesProps) => {
  const {
    watch, trigger, 
  } = useFormContext();
  
  const orderPrice = watch('orderPrice');
  const isAddEntryEnabled = watch('addEntryEnabled');
  const isTakeProfitEnabled = watch('takeProfitEnabled');
  const isStopLossEnabled = watch('stopLossEnabled');

  useEffect(() => {
    if (isAddEntryEnabled) {
      trigger([
        'additionalOrderPrice',
        'additionalTriggerPrice', 
        'additionalUnits',
        'additionalTotal',
        'additionalSlider',
      ]);
    }
  }, [isAddEntryEnabled, trigger]);
  
  useEffect(() => {
    if (!isAddEntryEnabled) {
      updateAdditionalEntryValues();
    }
  }, [isAddEntryEnabled, orderPrice, updateAdditionalEntryValues]);
  
  useEffect(() => {
    if (!isTakeProfitEnabled) {
      updateTakeProfitValues();
    }
  }, [isTakeProfitEnabled, orderPrice, updateTakeProfitValues]);
  
  useEffect(() => {
    if (!isStopLossEnabled) {
      updateStopLossValues();
    }
  }, [isStopLossEnabled, orderPrice, updateStopLossValues]);
};

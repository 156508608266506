import { IPlans } from '../interfaces';

export const getItems = (item: IPlans['items'][0]) => {
  const smartBotCommissionPercent = item.smartBotCommissionPercent;
  const exchangeAccountsConnectedLimit = item.exchangeAccountsConnectedLimit;
  const activeSmartTradesLimit = item.activeSmartTradesLimit;
  const activeBotsLimit = item.activeBotsLimit;

  switch (true) {
  case item.planName === 'basic':
    return [
      `${smartBotCommissionPercent}% sharing fee on Skyrexio bots`,
      `${exchangeAccountsConnectedLimit} exchange account connected`,
      `${activeSmartTradesLimit} active Smart Terminal position`,
      `${activeBotsLimit} active copy / alert bot`,
      'Bot configurator',
      'Demo account',
      'Real-time portfolio dashboard',
      '24 hours response support',
    ];
  case item.planName.includes('advanced'):
    return [
      'Everything from basic',
      null,
      `${exchangeAccountsConnectedLimit} exchange accounts connected`,
      `${activeSmartTradesLimit} active Smart Terminal positions`,
      `${activeBotsLimit} active copy / alert bots`,
      'Access to sharing bots at Marketplace ',
      'Real-time support',
    ];
  case item.planName.includes('pro'):
    return [
      'Everything from Basic and Advanced',
      null,
      `${smartBotCommissionPercent}% sharing fee on Skyrexio bots`,
      `${exchangeAccountsConnectedLimit} exchange accounts connected`,
      `${activeSmartTradesLimit} active Smart Terminal positions`,
      `${activeBotsLimit} active copy / alert bots`,
      'Access to Private TradingView strategies',
      'Premium dedicated support',
    ];
  default:
    return [];
  }
};

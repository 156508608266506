import { SxProps, Theme } from '@mui/material';

export const icon: SxProps<Theme> = {
  // width: 150,
  width: 166,
  height: 77,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  fontSize: 14,
  fontWeight: 500,
  padding: '16px 0',
  textAlign: 'center',
  justifyContent: 'center',
};

export const ByBitIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <circle cx='8' cy='8' r='8' fill='url(#paint0_linear_7843_3225)'/>
    <path d='M10.5529 9.08375V6H11.1727V9.08375H10.5529Z' fill='#F7A600'/>
    <path d='M3.32884 9.99974H2V6.91599H3.2754C3.89526 6.91599 4.25643 7.2538 4.25643 7.78223C4.25643 8.12429 4.02447 8.34534 3.86392 8.41894C4.05556 8.50551 4.30087 8.70037 4.30087 9.11203C4.30087 9.68787 3.89526 9.99974 3.32884 9.99974ZM3.22634 7.45314H2.61985V8.16345H3.22634C3.48939 8.16345 3.63658 8.02049 3.63658 7.80818C3.63658 7.59609 3.48939 7.45314 3.22634 7.45314ZM3.26642 8.70485H2.61985V9.46281H3.26642C3.54743 9.46281 3.68102 9.28967 3.68102 9.0816C3.68102 8.87376 3.54719 8.70485 3.26642 8.70485Z' fill='white'/>
    <path d='M6.19148 8.73505V9.99974H5.57601V8.73505L4.6217 6.91599H5.29499L5.88812 8.15896L6.47227 6.91599H7.14555L6.19148 8.73505Z' fill='white'/>
    <path d='M8.90285 9.99974H7.57401V6.91599H8.84941C9.46926 6.91599 9.83043 7.2538 9.83043 7.78223C9.83043 8.12429 9.59847 8.34534 9.43792 8.41894C9.62956 8.50551 9.87489 8.70037 9.87489 9.11203C9.87489 9.68787 9.46926 9.99974 8.90285 9.99974ZM8.80035 7.45314H8.19385V8.16345H8.80035C9.0634 8.16345 9.21058 8.02049 9.21058 7.80818C9.21058 7.59609 9.0634 7.45314 8.80035 7.45314ZM8.84042 8.70485H8.19385V9.46281H8.84042C9.12144 9.46281 9.25503 9.28967 9.25503 9.0816C9.25503 8.87376 9.12144 8.70485 8.84042 8.70485Z' fill='white'/>
    <path d='M13.1705 7.45314V9.99997H12.5506V7.45314H11.7212V6.91599H13.9999V7.45314H13.1705Z' fill='white'/>
    <defs>
      <linearGradient id='paint0_linear_7843_3225' x1='13.3333' y1='1.6' x2='2.66667' y2='16' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#2B2D3D'/>
        <stop offset='1' stopColor='#1B1F30'/>
      </linearGradient>
    </defs>
  </svg>
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUpdatedTrade } from 'pages/manual-trading/trading-terminal/api/get-updated-trade';
import { SmartTrade } from '../types/new-terminal-schema';

export const updateTrade = createAsyncThunk<SmartTrade, string, { rejectValue: string; }>('newTerminal/updateTrade', 
  async (smartTradeUuid, params) => {
    const {
      rejectWithValue, 
    } = params;
    try {
      const updatedTrade = await getUpdatedTrade(smartTradeUuid);
      return updatedTrade.data.smartTrades[0];
    } catch (error) {
      return rejectWithValue('error');
    }
  });

import { Box } from '@mui/material';
import { Text } from 'shared/ui';
import { ITable } from '../interfaces';
import { headerItem, headerWrapper, inner, row, rowItem, wrapper } from '../styles';

export const Table = (props: ITable) => {
  const {
    headers,
    data,
  } = props;

  return (
    <Box sx={wrapper}>
      <Box sx={headerWrapper}>
        {headers.map((header) => (
          <Box
            key={header}
            sx={headerItem}
          >
            <Text type='secondary'>{header}</Text>
          </Box>
        ))}
      </Box>

      <Box sx={inner}>
        {data.map((item) => (
          <Box key={item[0]} sx={row}>
            <Box sx={rowItem}>
              <Text>{item[0]}</Text>
            </Box>

            <Box sx={rowItem}>
              <Text>{item[1]}</Text>
            </Box>

            <Box sx={rowItem}>
              <Text>{item[2]}</Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

import { SxProps, Theme } from '@mui/material';

export const actionsWrapper: SxProps<Theme> = {
  flex: 1,
  maxWidth: 95,
  display: 'flex',
  alignItems: {
    xs: 'center',
    md: 'flex-start',
  },
  justifyContent: {
    xs: 'flex-end',
    md: 'flex-start',
  },
  gap: 2,
  order: {
    xs: 1,
    sm: 6,
  },
};

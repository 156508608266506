import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';

export const createBinanceHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo, resolution, dispatch, onTick, 
  } = params;
  let ws: WebSocket | null = null;

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    const {
      k: kline, 
    } = message;
    
    if (kline) {
      const bar: Bar = {
        time: +kline.t,
        low: +kline.l,
        high: +kline.h,
        open: +kline.o,
        close: +kline.c,
        volume: +kline.v,
      };
      dispatch(terminalActions.setLimitLastPrice(bar.close));
      onTick(bar);
    }
  };

  const getWebSocketUrl = () => {
    const binanceSymbol = symbolInfo.name.toLowerCase();
    const interval = resolutionToExchangeInterval(resolution as ResolutionString, 'binance');
    return `wss://stream.binance.com:9443/ws/${binanceSymbol}@kline_${interval}`;
  };

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(getWebSocketUrl(), handleMessage);
      return ws;
    },
    disconnect: () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const useEntryEffects = () => {
  const {
    setValue, watch, trigger, 
  } = useFormContext();
    
  const isAddEntryEnabled = watch('addEntryEnabled');
  const isTakeProfitEnabled = watch('takeProfitEnabled');
  
  useEffect(() => {
    if (!isAddEntryEnabled) {
      setValue('additionalEntries', []);
      trigger();
    }
  }, [isAddEntryEnabled, setValue, trigger]);
  
  useEffect(() => {
    if (!isTakeProfitEnabled) {
      setValue('takeProfitEntries', []);
    }
  }, [isTakeProfitEnabled, setValue]);
};

export function sanitizeSymbol(symbol: string) {
  return symbol.replace(/-/g, '');
}
  
export function sanitizeSymbolGate(symbol: string) {
  return symbol.replace(/_/g, '');
}

export function sanitizeSymbolCryptoCom(symbol: string) {
  return symbol.replace(/_/g, '');
}

export function getPrecisionNumber(tickSize: any) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  });
  
  const formattedValue = formatter.format(+tickSize);
  
  const result = formattedValue?.toString().includes('.')
    ? formattedValue?.toString().split('.')[1].length
    : 0;
  
  return result;
}
  

import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';

interface BitmartKlineData {
    candle: [number, string, string, string, string, string]; // [timestamp, open, high, low, close, volume]
    symbol: string;
  }
  
  interface BitmartMessage {
    table: string;
    data: BitmartKlineData[];
  }

export const createBitmartHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo,
    resolution,
    dispatch,
    onTick,
  } = params;

  let ws: WebSocket | null = null;
  let pingInterval: NodeJS.Timeout | null = null;

  const handleMessage = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data) as BitmartMessage;
      console.log('Received Bitmart message:', message);
  
      //@ts-ignore
      if (message.errorCode) {
        console.error('Bitmart WebSocket error:', message);
        return;
      }
  
      // Проверяем, что это сообщение с данными свечи
      if (message.table?.startsWith('spot/kline')) {
        const klineData = message.data?.[0];
        
        if (!klineData || !isValidKline(klineData)) {
          console.warn('Получены неполные данные свечи Bitmart:', klineData);
          return;
        }
  
        const bar: Bar = createBar(klineData);
        console.log('Created bar:', bar); // Для отладки
        dispatch(terminalActions.setLimitLastPrice(bar.close));
        onTick(bar);
      }
    } catch (error) {
      console.error('Ошибка обработки сообщения Bitmart:', error);
    }
  };

  const isValidKline = (klineData: BitmartKlineData): boolean => {
    return Boolean(
      klineData.candle &&
      klineData.candle.length === 6 &&
      klineData.symbol,
    );
  };

  const createBar = (klineData: BitmartKlineData): Bar => {
    const [timestamp, open, high, low, close, volume] = klineData.candle;
    
    return {
      time: timestamp * 1000,
      open: parseFloat(open),
      high: parseFloat(high),
      low: parseFloat(low),
      close: parseFloat(close),
      volume: parseFloat(volume),
    };
  };

  const getSubscriptionMessage = () => {

    return {
      op: 'subscribe',
      args: [`spot/kline${resolutionToExchangeInterval(resolution as ResolutionString, 'bitmartws')}:${symbolInfo?.full_name}`] ,
    };
  };

  const startPingPong = (ws: WebSocket) => {
    // Отправляем ping каждые 20 секунд
    pingInterval = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ 
          op: 'ping',
        }));
      }
    }, 20000);
  };

  const handleOpen = (ws: WebSocket) => {
    console.log('Connecting to Bitmart WebSocket...');
    
    const subscriptionMsg = getSubscriptionMessage();
    console.log('Sending subscription message:', subscriptionMsg);
    ws.send(JSON.stringify(subscriptionMsg));
    
    startPingPong(ws);
  };

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://ws-manager-compress.bitmart.com/api?protocol=1.1',
        handleMessage,
        null, // Не отправляем subscription message автоматически
        handleOpen,
      );
      return ws;
    },
    disconnect: () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        // Отправляем unsubscribe перед закрытием
        
        ws.send(JSON.stringify({
          op: 'unsubscribe',
          args: [`spot/kline${resolutionToExchangeInterval(resolution as ResolutionString, 'bitmartws')}:${symbolInfo?.full_name}`],
        }));
      }

      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }

      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

import { Box } from '@mui/material';
import { Table as RootTable } from 'antd';
import { TABLE_ROW_HEIGHT } from 'shared/consts';
import { Title } from 'shared/ui';
import { ITable } from '../interfaces';
import { background, inner, titleWrapper, wrapper } from '../styles';

export const Table = <T,>(props: ITable<T>) => {
  const {
    title,
    action,
    columns,
    items,
    graphic,
    itemsCount,
    horizontalAligment,
    headerRender,
    headerBreakPoint,
  } = props;

  const getTitle = () => {
    return (
      <Box sx={titleWrapper(headerBreakPoint)}>
        <Title level={5}>
          {title}
        </Title>

        {action}
      </Box>
    );
  };

  return (
    <Box sx={background}>
      {headerRender && (
        getTitle()
      )}

      <Box sx={inner(horizontalAligment)}>
        {graphic}

        <Box sx={wrapper}>
          <RootTable
            dataSource={items}
            columns={columns}
            title={(!headerRender && (title || action)) ? getTitle : undefined}
            rowHoverable={false}
            size='small'
            style={{
              flex: 1,
              width: '100%',
              maxWidth: '100%',
              overflowX: 'auto',
            }}
            scroll={{
              y: itemsCount ? itemsCount * TABLE_ROW_HEIGHT : 396,
            }}
            pagination={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

import { useState } from 'react';

export type ModalType = 'tradeActions' | 'actionResult';
export type ModalStatus = 'success' | 'error' | null;

export interface ModalState {
  isOpen: boolean;
  type: ModalType | null;
  status: ModalStatus;
  message: string;
  additionalData?: Record<string, any>;
}

interface OpenModalParams {
  type: ModalType;
  status?: ModalStatus;
  message?: string;
  additionalData?: Record<string, any>;
}

interface UseModalState {
  modalState: ModalState;
  openModal: (params: OpenModalParams) => void;
  closeModal: () => void;
}

const initialModalState: ModalState = {
  isOpen: false,
  type: null,
  status: null,
  message: '',
  additionalData: {},
};

export const useModalState = (): UseModalState => {
  const [modalState, setModalState] = useState<ModalState>(initialModalState);

  const openModal = ({
    type,
    status = null,
    message = '',
    additionalData = {},
  }: OpenModalParams) => {
    setModalState({
      isOpen: true,
      type,
      status,
      message,
      additionalData,
    });
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  return {
    modalState,
    openModal,
    closeModal,
  };
};

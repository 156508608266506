import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const button: CSSProperties = {
  height: 'auto',
  padding: '12px 40px',
  fontSize: getFontSize(16, 18),
  fontWeight: 400,
  lineHeight: 'normal',
};

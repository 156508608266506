import { createAsyncThunk } from '@reduxjs/toolkit';
import { setShortExchangesData } from 'entities/exchange/helpers/set-short-exchanges-data';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

export const updateExchangesBalances = createAsyncThunk(
  'trading/updateExchangesBalances',
  async (_, {
    rejectWithValue, 
  }) => {
    try {
      const skyrexUserUuid = getSkyrexUuid();
      
      ///@TODO !!!!!!
      //   const isDemoMode = getDemoMode();
        
      // TODO: поменять название метода, 
      //оптимизировать с остальными похожими методами в платформе
      const exchangesResponse = await setShortExchangesData(skyrexUserUuid ?? '');
  
      const filteredAccounts = getDemoAccount(exchangesResponse, false);
  
      const allExchangeAccountUuids = filteredAccounts.map((exchange: any) => exchange.exchangeAccountUuid);
        
      const allExchangesFinanceData = await getExchanges(allExchangeAccountUuids);
      
      const balancesMap = allExchangesFinanceData.data.accounts.reduce((acc: any, account: any) => {
        acc[account.exchangeAccountUuid] = account.balances;
        return acc;
      }, {});

      return balancesMap;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

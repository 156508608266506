import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getExchangeAccount = async (exchangeAccountUuid: string) => {
  const skyrexUserUuid = getSkyrexUuid();
  
  const response = await axios.request({
    url: '/exchangeAccount/GetByFilter',
    data: {
      table: 'exchange_accounts',
      skyrexUserUuids: [skyrexUserUuid],
      exchangeAccountUuids: [exchangeAccountUuid],
    },
  });
    
  return response.data;
};

import { Stack } from '@mui/material';
import { ErrorMessage } from 'pages/manual-trading/trading-terminal/components/error-message';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LabelInput } from 'shared/ui';
import { useOrderPrice } from '../../hooks/use-order-price';

interface OrderPriceFieldProps {
    currentSymbol: any;
    onOrderPriceChange: (value: string) => void;
    disabled?: boolean;
    forceUpdating?: boolean;
  }
  
export const OrderPriceField = memo(({ 
  currentSymbol, 
  onOrderPriceChange,
  disabled,
  forceUpdating,
}: OrderPriceFieldProps) => {
  const {
    control,
    trigger,
  } = useFormContext();
  
  useOrderPrice({ 
    currentSymbol, 
    onOrderPriceChange, 
    forceUpdating,
  });
  
  return (
    <Controller
      name='orderPrice'
      control={control}
      rules={{
        required: 'This field is required',
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) return 'Value should be a number';
          if (numValue < Number(currentSymbol.priceMin)) 
            return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
          if (numValue > Number(currentSymbol.priceMax)) 
            return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
          return true;
        },
      }}
      render={({
        field,
        fieldState: {
          error, 
        },
      }) => (
        <Stack gap={0.5} height={81}>
          <LabelInput
            label='Order price'
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onOrderPriceChange(value);
              }
              trigger('orderPrice');
              trigger('onAddFundsTotal');
              trigger('onAddFundsUnits');
              trigger('onAddFundsSlider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value,
                currentSymbol.quoteAssetPrecision,
                Number(currentSymbol.priceMin),
                Number(currentSymbol.priceMax),
              );
              field.onChange(formattedValue);
              onOrderPriceChange(formattedValue);
              trigger('orderPrice');
              trigger('onAddFundsSlider');
              trigger('onAddFundsTotal');
              trigger('onAddFundsUnits');
            }}
            icon={currentSymbol.quoteAsset}
            disabled={disabled}
            status={error ? 'error' : undefined}
          />
          <ErrorMessage message={error?.message} />
        </Stack>
      )}
    />
  );
});

OrderPriceField.displayName = 'OrderPriceField';

import { SxProps, Theme } from '@mui/material';

export const wrapper = (styles?: SxProps<Theme>) => {
  const wrapper: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    ...(styles && styles),
  };

  return wrapper;
};

import React from 'react';

interface ProgressMarkProps {
  value: number;
  label: string;
  distance: number;
  isTakeProfit: boolean;
  isExchangePrice?: boolean;
  exchangeSide?: boolean;
  labelColor?: string;
  valueColor?: string;
  visible?: boolean;
  isEntryPrice?: boolean;
}

const staticStyles = {
  mark: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'default',
  },
  line: {
    width: '1px',
    backgroundColor: '#9E9E9E',
    position: 'relative' as const,
    zIndex: 0,
  },
  labelValue: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'absolute' as const,
    zIndex: 1,
  },
  label: {
    fontSize: '9px',
    whiteSpace: 'nowrap' as const,
  },
  value: {
    fontSize: '9px',
    marginLeft: '3px',
  },
};

/**
 * SliderMark component for displaying marks on a slider.
 * @param {SliderMarkProps} props - The props for the SliderMark component.
 */
const ProgressMark: React.FC<ProgressMarkProps> = React.memo(({
  label,
  value,
  isExchangePrice = false,
  labelColor,
  valueColor,
  distance,
  isTakeProfit = true,
  exchangeSide = false,
  visible = true,
  isEntryPrice = false,
}) => {

  const markStyles: React.CSSProperties = {
    ...staticStyles.mark,
    flexDirection: isExchangePrice ? 'column-reverse' : 'column',
    position: isExchangePrice ? 'absolute' : 'relative',
    top: isExchangePrice ? '-35px' : '0px',
  };

  const lineStyles: React.CSSProperties = {
    ...staticStyles.line,
    height: (visible || isEntryPrice) ? `${distance - 8}px` : '5px',
    top: (!visible || isEntryPrice) ? '-15px' : '-5px',
  };

  const labelValueContainerStyles: React.CSSProperties = {
    ...staticStyles.labelValue,
    marginTop: !visible ? '4px' : isEntryPrice ? '-6px' : '-3px',
    top: isExchangePrice
      ? '-15px' 
      : visible
        ? `${distance - 15}px`
        : '-15px',
    ...(isExchangePrice
      ? {
        [exchangeSide ? 'right' : 'left']: '3px', 
      }
      : visible 
        ? {
          [isTakeProfit ? 'right' : 'left']: '3px',
        }
        : {}),
  };

  const labelStyles: React.CSSProperties = {
    ...staticStyles.label,
    color: labelColor || (label === 'SL' ? 'red' : '#2ECD99'),
  };

  const valueStyles: React.CSSProperties = {
    ...staticStyles.value,
    color: valueColor || 'black',
  };

  return (
    <div style={markStyles}>
      <div style={lineStyles} />
      <div style={labelValueContainerStyles}>
        <span style={labelStyles}>
          {isExchangePrice ? `${label} %` : label}
        </span>
        {visible && <span style={valueStyles}>{value}</span>}
      </div>
    </div>
  );
});

ProgressMark.displayName = 'ProgressMark';

export default ProgressMark;

import { Dispatch } from 'redux';
import {
  IExternalDatafeed,
  IDatafeedChartApi,
  Exchange,
} from '../../charting_library/datafeed-api';
import { createBarsMethods } from './bars-methods';
import { createSubscriptionMethods } from './subscription-methods';
import { createSymbolMethods } from './symbol-methods';

export interface CreateDataFeedParams {
  exchangeName: string;
  currentSymbol: any; // TODO: добавить правильный тип
  dispatch: Dispatch;
  wsRef: React.MutableRefObject<WebSocket | null>;
  subscriptionsRef: React.MutableRefObject<Map<string, WebSocket>>;
  exchangeOptions: Exchange[];
  unsubscribeFromCurrentStream: (subscriberUID?: string) => void;
}

export type CustomDataFeed = IExternalDatafeed & IDatafeedChartApi;

export const createCustomDataFeed = (params: CreateDataFeedParams): CustomDataFeed => {
  return {
    ...createSymbolMethods(params),
    ...createBarsMethods(params),
    ...createSubscriptionMethods(params),
  };
};

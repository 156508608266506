import { SegmentedLabeledOption } from 'antd/es/segmented';

export const segments: SegmentedLabeledOption<string>[] = [{
  label: 'Limit',
  value: 'limit',
}, {
  label: 'Cond.limit',
  value: 'cond.limit',
}, {
  label: 'Cond.market',
  value: 'cond.market',
}];

import { 
  CreateExchangeProps, 
  ExchangeProps, 
  UpdateExchangeProps,
} from 'entities/exchange/model/types/exchange-connect-update.types';
import React from 'react';
import { CryptoIcon, GateLogo } from 'widgets/choose-exchange/components/tabs/icons';
import { CreateExchange } from 'widgets/exchanges/connect-exchange/ui/connect-exchange';
import { UpdateExchange } from 'widgets/exchanges/update-exchange/ui/update-exchange';
import { BinanceLogo, BitmartIcon, BybitLogo, OkxLogo } from 'shared/icons';

interface ExchangeConfig {
  name: string;
  logo: React.ReactNode;
}

const exchangeConfigs: Record<string, ExchangeConfig> = {
  bybit: {
    name: 'Bybit',
    logo: BybitLogo,
  },
  binance: {
    name: 'Binance',
    logo: BinanceLogo,
  },
  okx: {
    name: 'OKX',
    logo: OkxLogo,
  },
  gateio: {
    name: 'Gate',
    logo: GateLogo,
  },
  crypto: {
    name: 'Crypto',
    logo: CryptoIcon,
  },
  bitmart: {
    name: 'BitMart',
    logo: BitmartIcon,
  },
};

export const Exchange: React.FC<ExchangeProps & { exchangeType: keyof typeof exchangeConfigs; }> = (props) => {
  const {
    isUpdate,
    isModalView,
    handleClose,
    exchangeType,
  } = props;

  const render = () => {
    if (isUpdate) {
      const {
        exchangeAccountUuid,
        currentExchangeTitle,
        closeExchangeModal,
      } = props as UpdateExchangeProps;
      
      return (
        <UpdateExchange
          exchangeType={exchangeType}
          handleClose={handleClose}
          isModalView={isModalView} 
          exchangeAccountUuid={exchangeAccountUuid}
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    }

    const {
      returnToAllExchanges,
    } = props as CreateExchangeProps;
    
    return (
      <CreateExchange
        exchangeType={exchangeType}
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges} 
        isModalView={isModalView} 
      />
    );
  };

  return render();
};

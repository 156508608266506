import { Breakpoint, SxProps, Theme } from '@mui/material';

export const titleWrapper = (breakPoint?: Breakpoint) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 3,
    flexDirection: {
      xs: 'column',
      [breakPoint || 'sm']: 'row',
    },
    alignItems: {
      xs: 'initial',
      [breakPoint || 'sm']: 'center',
    },
    '&>h5': {
      whiteSpace: 'nowrap',
      wordBreak: 'keep-all',
    },
  };
  
  return styles;
};

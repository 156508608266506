export const KucoinIcon = (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7429_9091)'>
      <path d='M8.73999 16.74C13.1583 16.74 16.74 13.1583 16.74 8.73999C16.74 4.32171 13.1583 0.73999 8.73999 0.73999C4.32171 0.73999 0.73999 4.32171 0.73999 8.73999C0.73999 13.1583 4.32171 16.74 8.73999 16.74Z' fill='#23AF91'/>
      <path d='M7.39624 8.74155L9.76499 11.1119L11.2603 9.61655C11.5275 9.37592 11.9369 9.38686 12.1916 9.64155C12.4462 9.89623 12.4572 10.3056 12.2165 10.5728L10.2431 12.5447C9.97749 12.8056 9.55249 12.8056 9.28842 12.5447L6.44155 9.69623V11.3884C6.44155 11.7619 6.13842 12.065 5.76499 12.065C5.39155 12.065 5.08842 11.7619 5.08842 11.3884V6.08998C5.08842 5.71655 5.39155 5.41342 5.76499 5.41342C6.13842 5.41342 6.44155 5.71655 6.44155 6.08998V7.78217L9.28842 4.9353C9.55405 4.67436 9.97905 4.67436 10.2431 4.9353L12.2181 6.90873C12.4587 7.17592 12.4478 7.5853 12.1931 7.83998C11.9384 8.09467 11.5291 8.10561 11.2619 7.86498L9.76655 6.36967L7.39624 8.74155ZM9.76811 8.06342C9.49467 8.06342 9.2478 8.22748 9.14155 8.48061C9.03686 8.73373 9.09467 9.02436 9.28842 9.21811C9.48217 9.41186 9.7728 9.46967 10.0259 9.36498C10.279 9.2603 10.4447 9.01342 10.4447 8.73998C10.4447 8.5603 10.3728 8.38842 10.2462 8.2603C10.1197 8.1353 9.9478 8.06342 9.76811 8.06342Z' fill='white'/>
    </g>
    <defs>
      <clipPath id='clip0_7429_9091'>
        <rect width='16' height='16' fill='white' transform='translate(0.73999 0.73999)'/>
      </clipPath>
    </defs>
  </svg>
);

import { SxProps, Theme } from '@mui/material';

export const item: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 1.5,
  position: 'relative',
  '&>span': {
    flex: 1,
  },
};

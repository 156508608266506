import { Typography } from '@mui/material';
import { SmartTrade } from 'entities/new-terminal/model/types/new-terminal-schema';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { FundsFormWrapper } from '../actions-modal-content/components/funds-form-wrapper/funds-form-wrapper';

interface TradeActionsContentProps {
  type: string;
  chartLastPrice: number;
  tradeSymbol: CurrentSymbol;
  trade: SmartTrade;
  exchangeAccount: any;
  onClose: () => void;
}

export const getTradeActionsContent = ({
  type,
  chartLastPrice,
  tradeSymbol,
  trade,
  exchangeAccount,
  onClose,
}: TradeActionsContentProps) => {
  const getTitle = () => {
    switch (type) {
    case 'addFunds': return 'Add Funds';
    case 'reduceFunds': return 'Reduce Funds';
    case 'closeByMarket': return 'Close by Market';
    case 'closeByLimit': return 'Close by Limit';
    case 'openByMarket': return 'Open by Market';
    case 'openByLimit': return 'Open by Limit';
    case 'edit': return 'Edit';
    case 'share': return 'Share';
    case 'cancel': return 'Cancel Position';
    default: return '';
    }
  };

  const getDescription = () => {
    switch (type) {
    case 'addFunds': return null;
    case 'reduceFunds': return null;
    case 'closeByMarket': return 'Description for closing by market';
    case 'closeByLimit': return 'Description for closing by limit';
    case 'openByMarket': return 'Description for opening by market';
    case 'openByLimit': return 'Description for opening by limit';
    case 'edit': return 'Description for editing';
    case 'cancel': return 'This action will cancel the position on Skyrexio, cancel unfilled orders, and leave assets on the exchange';
    case 'share': return 'Share';
    default: return '';
    }
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        {getTitle()}
      </Typography>
      
      {(type === 'addFunds' || type === 'reduceFunds') && (
        <FundsFormWrapper 
          isAddFunds={type === 'addFunds'}
          tradeSymbol={tradeSymbol}
          trade={trade}
          exchangeAccount={exchangeAccount}
          onClose={onClose}
        />)}
      
      {(type === 'closeByLimit' || type === 'openByLimit') && (
        <Typography variant='body2' gutterBottom>
          Current exchange price: {chartLastPrice}
        </Typography>
      )}
      
      <Typography variant='body1' sx={{
        my: 2, 
      }}>
        {getDescription()}
      </Typography>
    </>
  );
};

import { Modal } from 'antd';
import { WhatIncluded } from 'widgets';
import { getTitle } from 'shared/helpers';
import { descriptions, getItems } from '../consts';
import { IPlan } from '../interfaces';
import { modal, modalChildren } from '../styles';
import { TPlan } from '../types';

export const Plan = (props: IPlan) => {
  const {
    plan,
    isOpen,
    closeHandler,
  } = props;

  if (!plan) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <WhatIncluded
        title={getTitle(plan.planName)}
        description={descriptions[getTitle(plan.planName).toLowerCase() as TPlan]}
        price={null}
        items={getItems(plan)}
        button={null}
      />
    </Modal>
  );
};

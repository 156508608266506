import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const editTrade = async (data: any) => {
  const response = await axios.request({
    url: '/smartTrade/update',
    data,
  });

  return response.data;
};

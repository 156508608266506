import { SxProps, Theme } from '@mui/material';

export const action: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  gap: 1,
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  '&>div': {
    '&:first-of-type': {
      maxWidth: {
        xs: '100%',
        md: 480,
      },
    },
    '&:last-of-type': {
      maxWidth: {
        xs: '100%',
        md: 'max-content',
      },
    },
  },
};

import { SxProps, Theme } from '@mui/material';

export const item: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  justifyContent: 'space-between',
  '&>span': {
    display: 'block',
    fontSize: 8,
    '&:first-child': {  
      width: 72,
    },
    '&:nth-child(2)': {  
      width: 54,
    },
    '&:nth-child(3)': {  
      width: 30,
      textTransform: 'capitalize',
    },
    '&:last-child': {  
      width: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};

import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';
    
export const createCryptoComHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo, resolution, dispatch, onTick, 
  } = params;
  let ws: WebSocket | null = null;

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    if (message.method === 'public/heartbeat') {
      // Отправляем ответ на heartbeat
      ws?.send(JSON.stringify({
        id: message.id,
        method: 'public/respond-heartbeat',
      }));
    } else if (message.result?.data?.[0]) {
      const kline = message.result.data[0];
      const bar: Bar = {
        time: kline.t,
        open: parseFloat(kline.o),
        high: parseFloat(kline.h),
        low: parseFloat(kline.l),
        close: parseFloat(kline.c),
        volume: parseFloat(kline.v),
      };
      dispatch(terminalActions.setLimitLastPrice(bar.close));
      onTick(bar);
    }
  };

  const getSubscriptionMessage = () => ({
    id: 1,
    method: 'subscribe',
    params: {
      channels: [
        `candlestick.${resolutionToExchangeInterval(
          resolution as ResolutionString,
          'crypto-com',
        )}.${symbolInfo?.full_name}`,
      ],
    },
    nonce: Date.now(),
  });

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://stream.crypto.com/exchange/v1/market',
        handleMessage,
        getSubscriptionMessage(),
      );
      return ws;
    },
    disconnect: () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

import { SxProps, Theme } from '@mui/material';

export const back: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  '&>span': {
    fontSize: 12,
    fontWeight: 500,
  },
};

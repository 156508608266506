import { ResolutionString } from '../../charting_library/datafeed-api';

export const createResolution = (resolution: string): ResolutionString => {
  return resolution as ResolutionString;
};

export type ExchangeName = 'binance' | 'bybit' | 'okx' | 'gate' | 'crypto-com';

export const supportedResolutions: ResolutionString[] = [
  '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
].map(createResolution);

type ResolutionMap = { [K in ResolutionString]: string };

interface ExchangeResolutionMap {
    [key: string]: Partial<ResolutionMap>;
}

export const exchangeResolutionMaps: ExchangeResolutionMap = {
  binance: {
    [createResolution('1')]: '1m',
    [createResolution('3')]: '3m',
    [createResolution('5')]: '5m',
    [createResolution('15')]: '15m',
    [createResolution('30')]: '30m',
    [createResolution('60')]: '1h',
    [createResolution('120')]: '2h',
    [createResolution('240')]: '4h',
    [createResolution('360')]: '6h',
    [createResolution('720')]: '12h',
    [createResolution('1D')]: '1d',
    [createResolution('1W')]: '1w',
    [createResolution('1M')]: '1M',
  },
    
  bybit: {
    [createResolution('1')]: '1',
    [createResolution('3')]: '3',
    [createResolution('5')]: '5',
    [createResolution('15')]: '15',
    [createResolution('30')]: '30',
    [createResolution('60')]: '60',
    [createResolution('120')]: '120',
    [createResolution('240')]: '240',
    [createResolution('360')]: '360',
    [createResolution('720')]: '720',
    [createResolution('1D')]: 'D',
    [createResolution('1W')]: 'W',
    [createResolution('1M')]: 'M',
  },
    
  okx: {
    [createResolution('1')]: '1m',
    [createResolution('3')]: '3m',
    [createResolution('5')]: '5m',
    [createResolution('15')]: '15m',
    [createResolution('30')]: '30m',
    [createResolution('60')]: '1H',
    [createResolution('120')]: '2H',
    [createResolution('240')]: '4H',
    [createResolution('360')]: '6H',
    [createResolution('720')]: '12H',
    [createResolution('1D')]: '1D',
    [createResolution('1W')]: '1W',
    [createResolution('1M')]: '1M',
  },
    
  gate: {
    [createResolution('1')]: '1m',
    [createResolution('3')]: '3m',
    [createResolution('5')]: '5m',
    [createResolution('15')]: '15m',
    [createResolution('30')]: '30m',
    [createResolution('60')]: '1h',
    [createResolution('120')]: '2h',
    [createResolution('240')]: '4h',
    [createResolution('360')]: '6h',
    [createResolution('720')]: '12h',
    [createResolution('1D')]: '1d',
    [createResolution('1W')]: '7d',
    [createResolution('1M')]: '30d',
  },
    
  bitmart: {
    [createResolution('1')]: '1',
    [createResolution('3')]: '3',
    [createResolution('5')]: '5',
    [createResolution('15')]: '15',
    [createResolution('30')]: '30',
    [createResolution('60')]: '60',
    [createResolution('120')]: '120',
    [createResolution('240')]: '240',
    [createResolution('360')]: '360',
    [createResolution('720')]: '720',
    [createResolution('1D')]: '1440',
    [createResolution('1W')]: '10080',
    [createResolution('1M')]: '43200',
  },
    
  bitmartws: {
    [createResolution('1')]: '1min',
    [createResolution('3')]: '3min',
    [createResolution('5')]: '5min',
    [createResolution('15')]: '15min',
    [createResolution('30')]: '30min',
    [createResolution('60')]: '1H',
    [createResolution('120')]: '2H',
    [createResolution('240')]: '4H',
    [createResolution('360')]: '6H',
    [createResolution('720')]: '12H',
    [createResolution('1D')]: '1D',
    [createResolution('1W')]: '1W',
    [createResolution('1M')]: '1month',
  },
  
  okxws: {
    [createResolution('1')]: 'candle1m',
    [createResolution('3')]: 'candle3m',
    [createResolution('5')]: 'candle5m',
    [createResolution('15')]: 'candle15m',
    [createResolution('30')]: 'candle30m',
    [createResolution('60')]: 'candle1H',
    [createResolution('120')]: 'candle2H',
    [createResolution('240')]: 'candle4H',
    [createResolution('360')]: 'candle6H',
    [createResolution('720')]: 'candle12H',
    [createResolution('1D')]: 'candle1D',
    [createResolution('1W')]: 'candle1W',
    [createResolution('1M')]: 'candle1M',
  },
  
  htx: {
    [createResolution('1')]: '1min',
    [createResolution('5')]: '5min',
    [createResolution('15')]: '15min',
    [createResolution('30')]: '30min',
    [createResolution('60')]: '60min',
    [createResolution('240')]: '4hour',
    [createResolution('1D')]: '1day',
    [createResolution('1W')]: '1week',
    [createResolution('1M')]: '1mon',
  },
};

export const chartingLibraryPath = '/charting_library/';

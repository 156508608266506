import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const contentLinks: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  textAlign: 'center',
  justifyContent: 'center',
  '&>span': {
    fontSize: getFontSize(12, 16),
    fontWeight: 400,
    '&>a': {
      textDecoration: 'underline',
      color: '#2ECD99',
      fontSize: getFontSize(12, 16),
      fontWeight: 400,
    },
  },
};

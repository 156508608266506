import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const checkTradeCreated = async (data: any) => {
  const skyrexUserUuid = getSkyrexUuid();
  
  const response = await axios.request({
    url: 'smartTrade/getByFilter',
    data: {
      skyrexUserUuids: [skyrexUserUuid],
      smartTradeUuids: data.smartTradeUuids,
      table: 'terminal_smart_trades',
      page: 1,
    },
  });

  return response.data;
};

export const getTradeRichInfo = async (data: any) => {
  const response = await axios.request({
    url: 'smartTrade/getByFilterTerminal',
    data: {
      smartTradeUuids: data.smartTradeUuids,

      page: 1,
    },
  });

  return response.data;
};

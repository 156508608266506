import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getActiveTrades = async (accounts: string[]) => {  
  const skyrexUserUuid = getSkyrexUuid();
  
  const responseFromSmartTrade = await axios.request({
    url: '/smartTrade/getByFilter',
    data: {
      skyrexUserUuids: [skyrexUserUuid],
      exchangeAccountUuids: accounts,
      table: 'terminal_smart_trades',
      statuses: ['active'],
      page: 1,
    },
  });
  
  if (responseFromSmartTrade.data.success && responseFromSmartTrade.data.data.smartTrades.length) {
    const smartTradeUuids = responseFromSmartTrade.data.data.smartTrades.map((smartTrade: any) => smartTrade.smartTradeUuid);
    const responseFromTerminal = await axios.request({
      url: '/smartTrade/getByFilterTerminal',
      data: {
        smartTradeUuids: smartTradeUuids,
        page: 1,
      },
    });
    
    return responseFromTerminal.data.data.smartTrades;
  }

  return [];
};

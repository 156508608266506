export const conditionalPriceTypes = [{
  label: 'Last',
  value: 'LAST',
}, {
  label: 'Bid',
  value: 'BID',
}, {
  label: 'Ask',
  value: 'ASK',
}];

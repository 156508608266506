import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getExchangeAccount = async (uuid: string) => {
  const response = await axios.request({
    url: '/exchangeAccount/terminalGetByFilter',
    data: {
      exchangeAccountUuids: [uuid],
      page: 1,
    },
  });

  return response.data?.data?.accounts;
};

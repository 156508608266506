import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  padding: '2px 15px',
  width: '100%',
  fontSize: 18,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '27px',
};

import { Box } from '@mui/material';
import { Dropdown } from 'antd';
import { useCallback } from 'react';
import { UserMenuDropdown } from 'widgets/header/components';
import { IUserMenuProps } from 'widgets/header/interfaces';
import {
  Account,
  ArrowDown,
} from 'shared/icons';
import { Wrapper } from '../styles';

export const UserMenu = (props: IUserMenuProps) => {
  const {
    handleOpenChangePasswordModal,
  } = props;

  const getMenu = useCallback(() => {
    return (
      <UserMenuDropdown
        handleOpenChangePasswordModal={handleOpenChangePasswordModal}
      />
    );
  }, []);

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={getMenu}
    >
      <Box sx={Wrapper}>
        {Account}
        {ArrowDown}
      </Box>
    </Dropdown>
  );
};

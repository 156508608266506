import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  padding: '16px 0',
  width: '100%',
  maxWidth: 203,
  alignSelf: 'center',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 'normal',
};

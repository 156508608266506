import { SxProps, Theme } from '@mui/material';

export const titleWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&>div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
  },
};

import { Box } from '@mui/material';
import { Copy } from 'widgets';
import { Text } from 'shared/ui';
import { copyContent, copyLabelText, copyLabelWrapper } from 'shared/ui/modals/add-new-bot/styles';
import { otherSendRequest } from '../../../consts';
import { ITab } from '../../../interfaces';
import { Table } from '../components';

export const Other = (props: ITab) => {
  return (
    <Box sx={copyContent}>
      <Table
        headers={[
          'Field',
          'Type',
          'Description',
        ]}
        data={[[
          'base',
          'String',
          'Symbol to trade for example ADA',
        ], [
          'quote',
          'String',
          'Market of your bot for example USDT ',
        ], [
          'position',
          'String',
          '"entry1", "entry2", etc. for entries, "close" for closure',
        ], [
          'price',
          'String',
          'Execution price, for Market orders use current price',
        ], [
          'sourceUuid',
          'String',
          'Unique identifier of your bot, see below',
        ], [
          'secretToken',
          'String',
          'Protection token for your bot, see below',
        ], [
          'timestamp',
          'String',
          'Current time in UNIX format',
        ]]}
      />

      <Copy
        label='Source UUID'
        value={props.sourceUuid}
      />

      <Copy
        label='Secret Token'
        value={props.secretToken}
      />

      <Copy
        label={(
          <Box sx={copyLabelWrapper}>
            <Text
              type='secondary'
              styles={copyLabelText}
            >
              Send a POST request with JSON body to execute bot action
            </Text>

            <Text
              type='secondary'
              styles={{
                ...copyLabelText,
                fontSize: 10,
              }}
            >
              Replace "entry1" with "entry2", "entry3", etc. for additional entries, and with “close” to exit trade
            </Text>
          </Box>
        )}
        value={otherSendRequest()}
      />

      <Text>
        Note: send requests using your scripts or manually with Postman
      </Text>
    </Box>
  );
};

import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CloseSecondaryIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { closeWrapper, inner, item, itemTitle, itemValue, title, wrapper } from '../styles';

export const SafetyOrders = ({
  additionalEntries, onRemoveEntry, 
}: { additionalEntries: any[]; onRemoveEntry: (index: number) => void; }) => {

  const {
    watch, 
  } = useFormContext();
  const currentSymbol = watch('currentSymbol');
  
  return (
    <Box sx={wrapper}>
      <Text
        styles={title}
        type='success'
      >
        Safety orders
      </Text>
      {additionalEntries.length > 0 && (
        <Box sx={inner}>
          <Box sx={item}>
            <Text styles={itemTitle}>Price</Text>
            <Text styles={itemTitle}>Total</Text>
          </Box>

          {additionalEntries?.map((entry, index) => (
            <Box sx={item} key={index}>
              <Text styles={itemValue}>{`${entry.price} ${currentSymbol.quoteAsset}`}</Text>
              <Text styles={itemValue}>{`${entry.total} ${currentSymbol.quoteAsset}`}</Text>

              {!entry?.isCompleted && (
                <div
                  style={closeWrapper}
                  onClick={() => onRemoveEntry(index)}
                >
                  {CloseSecondaryIcon}
                </div>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

import { Box } from '@mui/material';
import { Dropdown } from 'antd';
import { More, Update } from 'shared/icons';
import { actionsWrapper } from '../styles';
import { TradeActionsProps } from '../types/active-trade.types';

export const TradeActions = ({
  menuItems, onUpdate, 
}: TradeActionsProps) => (
  <Box sx={actionsWrapper}>
    <Dropdown
      menu={{
        items: menuItems.map(item => ({
          key: item.key,
          label: item.label,
          icon: item.icon,
          onClick: item.onClick,
        })),
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
        }}
      >
        {More}
      </div>
    </Dropdown>

    <div
      onClick={onUpdate}
      style={{
        cursor: 'pointer',
        display: 'flex',
      }}
    >
      {Update}
    </div>
  </Box>
);

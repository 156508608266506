import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';

interface GateIOKline {
  t: string; // timestamp
  o: string; // open
  c: string; // close
  h: string; // high
  l: string; // low
  v: string; // volume
}

export const createGateHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo,
    resolution, 
    dispatch, 
    onTick,
  } = params;
  
  let ws: WebSocket | null = null;

  const handleMessage = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data);
      if (message.event === 'update' && message.channel === 'spot.candlesticks') {
        const kline = message.result;
        const bar: Bar = createBar(kline);
        dispatch(terminalActions.setLimitLastPrice(bar.close));
        onTick(bar);
      }
    } catch (error) {
      console.error('Ошибка обработки сообщения Gate.io:', error);
    }
  };

  const createBar = (kline: GateIOKline): Bar => ({
    time: parseInt(kline.t, 10) * 1000,
    open: parseFloat(kline.o),
    close: parseFloat(kline.c),
    high: parseFloat(kline.h),
    low: parseFloat(kline.l),
    volume: parseFloat(kline.v),
  });

  const getSubscriptionMessage = () => {
    return {
      time: Math.floor(Date.now() / 1000),
      channel: 'spot.candlesticks',
      event: 'subscribe',
      payload: [
        resolutionToExchangeInterval(resolution as ResolutionString, 'gateio'),
        symbolInfo?.full_name,
      ],
    };
  };

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://api.gateio.ws/ws/v4/',
        handleMessage,
        getSubscriptionMessage(),
      );
      return ws;
    },
    disconnect: () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

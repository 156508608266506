import { calculatePercentageWithCeil, formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface UsePriceEffectsProps {
  chartLastPrice: number;
  limitLastPrice: number;
  currentSymbol: any;
  userWallet: any;
  userWalletBaseAsset: any;
}

export const usePriceEffects = ({
  chartLastPrice,
  limitLastPrice,
  currentSymbol,
  userWallet,
  userWalletBaseAsset,
}: UsePriceEffectsProps) => {
  const {
    setValue, watch, 
  } = useFormContext();

  
  const selectedSide = watch('selectedSide');
  const orderType = watch('orderType');
  const isSkipBaseOrder = watch('skipBaseOrder');
  
  const isFirstRender = useRef(true);
  const prevSymbolRef = useRef(currentSymbol?.symbol);
  const prevSideRef = useRef(selectedSide);
  
  // Вспомогательная функция для обновления units и total
  const updateUnitsAndTotal = (orderPrice: number) => {
    const units = watch('units');
    if (!isNaN(+units)) {
      const total = userWallet.free * 0.1;

      if (total > 0) {
        const newUnits = total / orderPrice;
        const formattedUnits = formatByPrecisionAndTrim(
          newUnits.toString(),
          currentSymbol.baseAssetPrecision,
          Number(currentSymbol.lotMin),
          Number(currentSymbol.lotMax),
        );
        setValue('units', formattedUnits);

        const newTotal = parseFloat(formattedUnits) * orderPrice;
        setValue('total', formatByPrecisionAndTrim(
          newTotal.toString(),
          currentSymbol.quoteAssetPrecision,
          Number(currentSymbol.minNotional),
          Number(currentSymbol.maxNotional),
        ));
        setValue('slider', 10);
      } else {
        setValue('units', '0');
        setValue('total', '0');
        setValue('slider', 0);
      }
    }
  };
  
  
  const updateBaseFieldsValues = () => {
    if (isSkipBaseOrder) {
      const formattedOrderPrice = formatByPrecisionAndTrim(
        chartLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      );
      
      setValue('boughtPrice', formattedOrderPrice);
      
      const initialSkipBaseUnits = formatByPrecisionAndTrim(
        userWalletBaseAsset?.free?.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      
      const initialSkipBaseTotal = formatByPrecisionAndTrim(
        (parseFloat(initialSkipBaseUnits) * parseFloat(formattedOrderPrice)).toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
      
      setValue('skipBaseUnits', initialSkipBaseUnits);
      setValue('skipBaseTotal', initialSkipBaseTotal);
      setValue('skipBaseSlider', 100);
      
    } else {
      updatePrices();
    }
  };
  
  
  const updatePrices = () => {
  
    if (orderType !== 'market') {
      const newOrderPrice = chartLastPrice;
      setValue('orderPrice', formatByPrecisionAndTrim(
        newOrderPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      ));

      if (orderType === 'conditional') {
        setValue('triggerPrice', formatByPrecisionAndTrim(
          newOrderPrice.toString(),
          currentSymbol.quoteAssetPrecision,
          Number(currentSymbol.priceMin),
          Number(currentSymbol.priceMax),
        ));
      }

      updateUnitsAndTotal(newOrderPrice);
    }
  };
  
  useEffect(() => {
    updateBaseFieldsValues();
  }, [isSkipBaseOrder]);
  
  useEffect(() => {
    if (isSkipBaseOrder) {
      const formattedOrderPrice = formatByPrecisionAndTrim(
        chartLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      );
      
      setValue('boughtPrice', formattedOrderPrice);
      
      const initialSkipBaseUnits = formatByPrecisionAndTrim(
        userWalletBaseAsset?.free?.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      
      const initialSkipBaseTotal = formatByPrecisionAndTrim(
        (parseFloat(initialSkipBaseUnits) * parseFloat(formattedOrderPrice)).toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
      
      setValue('skipBaseUnits', initialSkipBaseUnits);
      setValue('skipBaseTotal', initialSkipBaseTotal);
      setValue('skipBaseSlider', 100);
      
      return;
    }

    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
    const isSideChanged = prevSideRef.current !== selectedSide;

    if (isFirstRender.current || isSymbolChanged || isSideChanged) {
  
      const updatePrices = () => {
        if (orderType !== 'market') {
          const newOrderPrice = chartLastPrice;
          setValue('orderPrice', formatByPrecisionAndTrim(
            newOrderPrice.toString(),
            currentSymbol.quoteAssetPrecision,
            Number(currentSymbol.priceMin),
            Number(currentSymbol.priceMax),
          ));
  
          if (orderType === 'conditional') {
            setValue('triggerPrice', formatByPrecisionAndTrim(
              newOrderPrice.toString(),
              currentSymbol.quoteAssetPrecision,
              Number(currentSymbol.priceMin),
              Number(currentSymbol.priceMax),
            ));
          }
  
          updateUnitsAndTotal(newOrderPrice);
        }
      };
  
      updatePrices();
      isFirstRender.current = false;
    }
    
    prevSymbolRef.current = currentSymbol?.symbol;
    prevSideRef.current = selectedSide;
  }, [
    currentSymbol?.symbol,
    selectedSide,
    chartLastPrice,
    orderType,
    isSkipBaseOrder,
    currentSymbol?.quoteAssetPrecision,
    currentSymbol?.priceMin,
    currentSymbol?.priceMax,
  ]);
  
  
  const updateMarketValues = (orderPrice: number) => {
    const lastChangedField = watch('lastChangedField');
    const total = watch('total');
    const units = watch('units');
    const userWalletFree = parseFloat(userWallet.free);
    
    if (lastChangedField == null || lastChangedField === 'total') {
      const newUnits = parseFloat(total) / orderPrice;
      setValue('units', formatByPrecisionAndTrim(
        newUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      ));
    }
    
    if (lastChangedField === 'units') {
      const newTotal = parseFloat(units) * orderPrice;
      setValue('total', formatByPrecisionAndTrim(
        newTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      ));
      
      let percentageValue = 0;
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newTotal, userWalletFree);
      }
      setValue('slider', percentageValue);
    }
  };
  
  
  useEffect(() => {
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
    const isSideChanged = prevSideRef.current !== selectedSide;
  
    if (orderType === 'market' && !isSkipBaseOrder) {
      const newOrderPrice = limitLastPrice;
      setValue('orderPrice', formatByPrecisionAndTrim(
        newOrderPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      ));
      if (isFirstRender.current || isSymbolChanged || isSideChanged) {
        updateUnitsAndTotal(newOrderPrice);
      } else {
        updateMarketValues(newOrderPrice);
      }
      
      isFirstRender.current = false;
    }
    
    prevSymbolRef.current = currentSymbol?.symbol;
    prevSideRef.current = selectedSide;
  }, [
    currentSymbol?.symbol,
    selectedSide,
    limitLastPrice,
    orderType,
    isSkipBaseOrder,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.priceMin,
    currentSymbol.priceMax,
    setValue,
    userWallet.free,
  ]);
};

import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  width: '100%',
  flex: 1,
  padding: '0 6px',
  maxWidth: {
    xs: '100%',
    md: 317,
  },
  maxHeight: 661,
  overflowX: 'hidden',
};

export const EXCHANGE_NAMES = {
  BINANCE: 'binance',
  BYBIT: 'bybit',
  OKX: 'okx',
  GATEIO: 'gateio',
  CRYPTO_COM: 'crypto-com',
  BITMART: 'bitmart',
  HTX: 'htx',
  CRYPTO_COMPARE: 'crypto-compare',
} as const;

export type ExchangeName = typeof EXCHANGE_NAMES[keyof typeof EXCHANGE_NAMES];

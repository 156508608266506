import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedSmartTrades } from 'shared/api/axios/helpers/fetch-paginated';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IData {
  botUuid: string;
  closedDateFrom?: string;
  closedDateTo?: string;
  page: number;
}

export const getPublicBotDeals = async (botUuid: string, tradingHistoryDates?: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => {  
  const endPoint = 'bot/getPublicBotTradesLandingMarketplace';
  // const endPoint = 'bot/getPublicBotTrades';

  const requestData: IData = {
    botUuid,
    page: 1,
  };

  if (tradingHistoryDates?.[0] && tradingHistoryDates?.[1]) {
    requestData.closedDateFrom = dayjs(tradingHistoryDates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    requestData.closedDateTo = dayjs(tradingHistoryDates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
    
  return fetchPaginatedSmartTrades(axios, endPoint, requestData);
};

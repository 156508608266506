import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { sliderWrapper } from 'pages/manual-trading/trading-terminal/components/fields/styles';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CloseSecondaryIcon, PlusGreen } from 'shared/icons';
import { Input, MainButton, SingleSelect, Slider, Switch, Text, Title } from 'shared/ui';
import { SafetyOrders } from '../../buy/components/take-profit/components';
import { segments } from '../../buy/components/take-profit/consts';
import { ITakeProfit } from '../../buy/components/take-profit/interfaces';
import { button, buttonTitle, header, headerTitle, priceInner, priceTitle, priceWrapper, wrapper } from '../../buy/components/take-profit/styles';
import { ErrorMessage } from '../../error-message';

export const TakeProfitSell = (props: ITakeProfit) => {
  const {
    setIsSkipTakeProfit,
    onTakeProfitOrderPriceChange,
    onTakeProfitOrderPricePercentChange,
    onTakeProfitTriggerPriceChange,
    onAddEntry,
    onRemoveEntry,
  } = props;

  const {
    control,
    formState: {
      errors, 
    },
    trigger,
    setValue,
    watch,
    setError: setFormError,
    clearErrors,
  } = useFormContext();
  
  const [splitTarget, setSplitTarget] = useState<boolean>(false);
 
  const isTakeProfitEnabled = watch('takeProfitEnabled');
  const takeProfitOrderType = watch('takeProfitOrderType');
  const currentSymbol = watch('currentSymbol');
  const quoteAsset = watch('quoteAsset');
  const takeProfitTriggerPricePercent = watch('takeProfitTriggerPricePercent');
  const takeProfitEntries = watch('takeProfitEntries');
  
  const takeProfitPriceRecalculation = watch('takeProfitPriceRecalculation');
  const changePriceRecalculationHandler = (value: string) => {
    setValue('takeProfitPriceRecalculation', value as 'average' | 'fixed');
  };

  const totalVolume = useMemo(() => {
    return takeProfitEntries.reduce((sum: number, entry: { volume: number; }) => sum + entry.volume, 0);
  }, [takeProfitEntries]);

  const isAllTargetsSet = totalVolume >= 100;
  
  useEffect(() => {
    if (splitTarget && takeProfitEntries?.length > 0 && totalVolume < 100) {
      setFormError('takeProfitEntries', {
        type: 'custom',
        message: 'Targets volume sum should be 100%',
      });
    } else {
      clearErrors('takeProfitEntries');
    }
  }, [splitTarget, totalVolume, setFormError, clearErrors]);

  
  useEffect(() => {
    setValue('takeProfitSlider', 100);
  }, [setValue]);
  
  const changeSegmentHandler = (value: string) => {
    setValue('takeProfitOrderType', value);
  };

  const closeSplitTargetHandler = () => {
    setSplitTarget(false);
    setValue('takeProfitEntries', []);
  };

  const handleButtonClick = () => {
    if (splitTarget) {
      onAddEntry();
    } else {
      setSplitTarget(true);
    }
  };
  
  const renderOrderPriceField = () => {
    if (takeProfitOrderType === 'cond.market') {
      return null;
    }

    return (
      <Stack
        gap={0.5}
        height={81}
      >
        <Box sx={priceWrapper}>
          <Text
            type='secondary'
            styles={priceTitle}
          >
            {'Order price'}
          </Text>

          <Box sx={priceInner}>
          
            <Controller
              name='takeProfitOrderPrice'
              control={control}
              rules={{
                required: 'This field is required', 
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  return true;
                },
              }}
              render={({
                field, 
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onTakeProfitOrderPriceChange(value);
                    }
                    trigger('takeProfitOrderPrice');
                  }}
                  onBlur={(value) => {
                    const formattedValue = formatByPrecisionAndTrim(
                      value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onTakeProfitOrderPriceChange(formattedValue);
                    trigger('takeProfitOrderPrice');
                  }}
                  icon={quoteAsset}
                  status={errors.takeProfitOrderPrice ? 'error' : undefined}
                />
              )}
            />
          
            <Controller
              name='takeProfitOrderPricePercent'
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field, 
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onTakeProfitOrderPricePercentChange(value);
                      trigger('takeProfitOrderPricePercent');
                      trigger('takeProfitOrderPrice');
                    }
                  }}
                  maxWidth={58}
                  icon='%'
                  status={errors.takeProfitOrderPricePercent ? 'error' : undefined}
                />
              )}
            />
          </Box>
        </Box>
        <ErrorMessage message={errors?.takeProfitOrderPrice?.message} />
      </Stack>
    );
  };
  
  const renderTriggerPriceField = () => {
    if (takeProfitOrderType === 'limit') {
      return null;
    }

    return (
      <Stack
        gap={0.5}
        height={81}
      >
        <Box sx={priceWrapper}>
          <Text
            type='secondary'
            styles={priceTitle}
          >
            {'Trigger price'}
          </Text>

          <Box sx={priceInner}>
            <Controller
              name='takeProfitTriggerPrice'
              control={control}
              rules={{
                required: 'Это поле обязательно',
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  return true;
                },
              }}
              render={({
                field,
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onTakeProfitTriggerPriceChange(value);
                    }
                    trigger('takeProfitTriggerPrice');
                  }}
                  onBlur={(value) => {
                    const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onTakeProfitTriggerPriceChange(formattedValue);
                    trigger('takeProfitTriggerPrice');
                  }}
                  icon={quoteAsset}
                  addonBefore={`${takeProfitTriggerPricePercent}%`}
                  status={errors.takeProfitTriggerPrice ? 'error' : undefined}
                />
              )}
            />
          
            <Controller
              name='takeProfitTriggerPriceType'
              control={control}
              render={({
                field, 
              }) => (
                <SingleSelect
                  maxWidth='max-content'
                  select={{
                    value: field.value,
                    placeholder: 'Last',
                    onChange: (value) => {
                      field.onChange(value);
                      trigger('takeProfitTriggerPriceType');
                    },
                  }}
                  options={[
                    {
                      label: 'Last', value: 'last', 
                    },
                    {
                      label: 'Bid', value: 'bid', 
                    },
                    {
                      label: 'Ask', value: 'ask', 
                    },
                  ]}
                />
              )}
            />
          </Box>
        </Box>
        <ErrorMessage message={errors?.takeProfitTriggerPrice?.message} />
      </Stack>
    );
  };
  
  const renderSlider = () => {
    
    return (
      <Stack
        gap={0.5}
        height={53}
      >
        <Box sx={sliderWrapper}>
          <Controller
            name='takeProfitSlider'
            control={control}
            rules={{
              max: {
                value: 100,
                message: 'Cannot be more than 100%',
              },
              min: {
                value: 1,
                message: 'Should be more than 0%',
              },
            }}
            render={({
              field, 
            }) => (
              <Slider
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  trigger('takeProfitSlider');
                }}
                max={100 - totalVolume}
              />
            )}
          />
          <Controller
            name='takeProfitSlider'
            control={control}
            render={({
              field, 
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  if (/^[0-9]+$/.test(value) || value === '') {
                    field.onChange(value);
                    trigger('takeProfitSlider');
                  }
                }}
                placeholder='100%'
                maxWidth={58}
                icon='%'
                status={errors.takeProfitSlider ? 'error' : undefined}
              />
            )}
          />
        </Box>
        <ErrorMessage message={errors?.takeProfitSlider?.message} />
      </Stack>
    );
  };
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          value={isTakeProfitEnabled}
          onChange={setIsSkipTakeProfit}
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              Take profit
            </Title>
          )}
          size='small'
        />

        <Segmented
          value={takeProfitPriceRecalculation}
          onChange={changePriceRecalculationHandler}
          options={[{
            label: 'Average',
            value: 'average',
          }, {
            label: 'Fixed',
            value: 'fixed',
          }]}
        />
      </Box>

      {isTakeProfitEnabled && (
        <>
          <Segmented
            options={segments}
            value={takeProfitOrderType}
            onChange={changeSegmentHandler}
            block={true}
            disabled={isAllTargetsSet}
          />

          {takeProfitEntries.length > 0 && (
            <SafetyOrders 
              takeProfitEntries={takeProfitEntries}
              onRemoveEntry={onRemoveEntry}
            />
          )}

          {!isAllTargetsSet && (
            <>
              {renderOrderPriceField()}
              {renderTriggerPriceField()}
            </>
          )}

          {splitTarget && !isAllTargetsSet && (
            renderSlider()
          )}

          {errors.takeProfitDuplicatePriceError && (
            <Text type='danger' styles={{
              fontSize: '12px', marginTop: '8px',
            }}>
              {errors.takeProfitDuplicatePriceError.message as string}
            </Text>
          )}

          <Box
            display='flex'
            alignItems='center'
            gap={1}
          >
            {isAllTargetsSet ? (
              <Text>All targets are set</Text>
            ) : (
              <>
                <MainButton
                  icon={PlusGreen}
                  styles={button}
                  onClick={handleButtonClick}
                  disabled={isAllTargetsSet}
                >
                  <Title
                    level={5}
                    styles={buttonTitle}
                  >
                    {splitTarget ? 'Add target' : 'Split target'} 
                  </Title>
                </MainButton>

                {splitTarget && (
                  <MainButton onClick={closeSplitTargetHandler}>
                    {CloseSecondaryIcon}
                  </MainButton>
                )}
              </>
            )}
          </Box>
          {errors.takeProfitEntries && (
            <Text type='danger' styles={{
              fontSize: '12px', marginTop: '8px',
            }}>
              {errors.takeProfitEntries.message as string}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

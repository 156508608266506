import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar } from '../../charting_library/datafeed-api';
import { WebSocketHandler, WebSocketHandlerParams } from '../types/chart-view.types';

export const createCryptoCompareHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo, dispatch, onTick, 
  } = params;
  let ws: WebSocket | null = null;
  
  const handleMessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data);
      
    if (data.TYPE === '959') {
      const bar: Bar = {
        time: data.PRICE_LAST_UPDATE_TS * 1000,
        open: +data.CURRENT_HOUR_OPEN,
        high: +data.CURRENT_HOUR_HIGH,
        low: +data.CURRENT_HOUR_LOW,
        close: +data.PRICE,
        volume: +data.CURRENT_HOUR_VOLUME,
      };
  
      dispatch(terminalActions.setLimitLastPrice(bar.close));
      onTick(bar);
    }
  };
  
  const getSubscriptionMessage = () => ({
    action: 'SUBSCRIBE',
    type: 'spot_v1_latest_tick',
    groups: [
      'VALUE',
      'CURRENT_HOUR',
    ],
    market: params.exchangeName.toLowerCase(),
    instruments: [symbolInfo.pro_name],
  });
  
  return {
    connect: () => {
      ws = new WebSocket('wss://data-streamer.cryptocompare.com');
  
      ws.onopen = () => {
        console.log('CryptoCompare WebSocket connected');
        const subRequest = getSubscriptionMessage();
        //@ts-ignore
        ws.send(JSON.stringify(subRequest));
      };
  
      ws.onmessage = handleMessage;
  
      ws.onerror = (error) => {
        console.error('CryptoCompare WebSocket Error:', error);
      };
  
      ws.onclose = () => {
        console.log('CryptoCompare WebSocket connection closed');
      };
  
      return ws;
    },
    disconnect: () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

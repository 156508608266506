import { SxProps, Theme } from '@mui/material';

export const platformLogo: SxProps<Theme> = {
  height: 64,
  width: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&>svg': {
    width: '100%',
    height: '100%',
    flexShrink: 0,
  },
};

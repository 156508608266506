import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { sliderWrapper } from 'pages/manual-trading/trading-terminal/components/fields/styles';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { PlusGreen } from 'shared/icons';
import {
  Input,
  LabelInput,
  MainButton,
  SingleSelect,
  Slider,
  Switch,
  Text,
  Title,
} from 'shared/ui';
import { SafetyOrders } from '../../buy/components/additional-entry/components';
import { segments } from '../../buy/components/additional-entry/consts';
import { IAdditionalEntry } from '../../buy/components/additional-entry/interfaces';
import { actions, action, actionText, buttonTitle, suffixText, wrpaper, button } from '../../buy/components/additional-entry/styles';
import { ErrorMessage } from '../../error-message';

export const AdditionalEntrySell = (props: IAdditionalEntry) => {
  const {
    onAdditionalOrderPriceChange,
    onAdditionalUnitsChange,
    onAdditionalTotalChange,
    onAdditionalSliderChange,
    onAdditionalTriggerPriceChange,
    setIsSkipAdditionalEntry,
    onAdditionalOrderPricePercentChange,
    onAddEntry,
    onRemoveEntry,
  } = props;
  
  const {
    control,
    formState: {
      errors, 
    },
    trigger,
    setValue,
    watch,
  } = useFormContext();
    
  const additionalOrderType = watch('additionalOrderType');
  const isAddEntryEnabled = watch('addEntryEnabled');
  const currentSymbol = watch('currentSymbol');
  const baseAsset = watch('baseAsset');
  const quoteAsset = watch('quoteAsset');
  const additionalTriggerPricePercent = watch('additionalTriggerPricePercent');  
  const additionalEntries = watch('additionalEntries');
  
  const changeSegmentHandler = (value: string) => {
    setValue('additionalOrderType', value);
  };

  const renderOrderPriceField = () => {
    if (additionalOrderType === 'cond.market') {
      return null;
    }

    return (
      <Stack
        gap={0.5}
        height={81}
      >
        <Box
          sx={action}
          key={'Order price'}
        >
          <Text
            type='secondary'
            styles={actionText}
          >
            {'Order price'}
          </Text>
  
          <Box sx={actions}>
            <Controller
              name='additionalOrderPrice'
              control={control}
              rules={{
                required: 'This field is required', 
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  return true;
                },
              }}
              render={({
                field, 
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onAdditionalOrderPriceChange(value);
                    }
                    trigger('additionalOrderPrice');
                    trigger('additionalTotal');
                    trigger('additionalUnits');
                    trigger('additionalSlider');
                  }}
                  icon={quoteAsset}
                  status={errors.additionalOrderPrice ? 'error' : undefined}
                  onBlur={() => {
                    const formattedValue = formatByPrecisionAndTrim(
                      field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onAdditionalOrderPriceChange(formattedValue);
                    trigger('additionalOrderPrice');
                    trigger('additionalTotal');
                    trigger('additionalUnits');
                    trigger('additionalSlider');
                  }}
                />
              )}
            />
  
            <Controller
              name='additionalOrderPricePercent'
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field, 
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                  // @TODO separate validations in helpers (dirrerent rules for percent and etc)
                    if (/^-?[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onAdditionalOrderPricePercentChange(value);
                      trigger('additionalOrderPricePercent');
                      trigger('additionalOrderPrice');
                      trigger('additionalTotal');
                      trigger('additionalUnits');
                      trigger('additionalSlider');
                    }
                  }}
                  maxWidth={58}
                  icon='%'
                  status={errors.additionalOrderPricePercent ? 'error' : undefined}
                />
              )}
            />
          </Box>
        </Box>

        <ErrorMessage message={errors?.additionalOrderPrice?.message} />
      </Stack>
    );
  };
  
  const renderUnitsField = () => {
    return (
      <Controller
        name='additionalUnits'
        control={control}
        rules={{
          required: 'This field is required',
          validate: (value) => {
            const numValue = Number(value);
            if (isNaN(numValue)) return 'Value should be a number';
            if (numValue < Number(currentSymbol.lotMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.lotMin)}`;
            if (numValue > Number(currentSymbol.lotMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.lotMax)}`;
            return true;
          },
        }}
        render={({
          field,
        }) => (
          <Stack
            gap={0.5}
            height={81}
          >
            <LabelInput
              label='Units'
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalUnitsChange(value);
                }
                trigger('additionalUnits');
                trigger('additionalTotal');
                trigger('additionalSlider');
              }}
              icon={baseAsset}
              onBlur={() => {
                const formattedValue = formatByPrecisionAndTrim(
                  field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
                field.onChange(formattedValue);
                onAdditionalUnitsChange(formattedValue);
                trigger('additionalUnits');
                trigger('additionalTotal');
                trigger('additionalSlider');
              }}
              status={errors.additionalUnits ? 'error' : undefined}
            />
            <ErrorMessage message={errors?.additionalUnits?.message} />
          </Stack>
        )}
      />
    );
  };
  
  const renderTotalField = () => (
    <Controller
      name='additionalTotal'
      control={control}
      rules={{
        required: 'This field is required', 
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) return 'Value should be a number';
          if (numValue < Number(currentSymbol.minNotional)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.minNotional)}`;
          if (numValue > Number(currentSymbol.maxNotional)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.maxNotional)}`;
          return true;
        },
      }}
      render={({
        field, 
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label='Total'
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onAdditionalTotalChange(value);
              }
              trigger('additionalTotal');
              trigger('additionalUnits');
              trigger('additionalSlider');
            }}
            icon={quoteAsset}
            status={errors.additionalTotal ? 'error' : undefined}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value,
                currentSymbol.quoteAssetPrecision,
                Number(currentSymbol.minNotional),
                Number(currentSymbol.maxNotional),
              );
              field.onChange(formattedValue);
              onAdditionalTotalChange(formattedValue);
              trigger('additionalTotal');
              trigger('additionalUnits');
              trigger('additionalSlider');
            }}
          />
          <ErrorMessage message={errors?.additionalTotal?.message} />
        </Stack>
      )}
    />
  );
  
  const renderSlider = () => (
    <Stack
      gap={0.5}
      height={53}
    >
      <Box sx={sliderWrapper}>
        <Controller
          name='additionalSlider'
          control={control}
          rules={{
            max: {
              value: 100,
              message: 'Cannot be more than 100%',
            },
            min: {
              value: 1,
              message: 'Should be more than 0%',
            },
          }}
          render={({
            field, 
          }) => (
            <Slider
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onAdditionalSliderChange(value);
                trigger('additionalSlider');
                trigger('additionalTotal');
                trigger('additionalUnits');
              }}
            />
          )}
        />
        <Controller
          name='additionalSlider'
          control={control}
          render={({
            field, 
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]+$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalSliderChange(parseFloat(value));
                  trigger('additionalSlider');
                  trigger('additionalTotal');
                  trigger('additionalUnits');
                }
              }}
              placeholder='100%'
              maxWidth={58}
              icon='%'
              status={errors.additionalSlider ? 'error' : undefined}
            />
          )}
        />
      </Box>
      <ErrorMessage message={errors?.additionalSlider?.message} />
    </Stack>
  );
  
  const renderTriggerPriceField = () => {
    if (additionalOrderType !== 'cond.market' && additionalOrderType !== 'cond.limit') {
      return null;
    }

    return (
      <Stack
        gap={0.5}
        height={81}
      >
        <Box
          key={'Trigger price'}
          sx={action}
        >
          <Text
            type='secondary'
            styles={actionText}
          >
            Trigger price
          </Text>

          <Box sx={actions}>
            <Controller
              name='additionalTriggerPrice'
              control={control}
              rules={{
                required: 'Это поле обязательно',
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  return true;
                },
              }}
              render={({
                field,
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onAdditionalTriggerPriceChange(value);
                    }
                    trigger('additionalTriggerPrice');
                  }}
                  onBlur={() => {
                    const formattedValue = formatByPrecisionAndTrim(
                      field.value,
                      currentSymbol.quoteAssetPrecision,
                      Number(currentSymbol.priceMin),
                      Number(currentSymbol.priceMax),
                    );
                    field.onChange(formattedValue);
                    onAdditionalTriggerPriceChange(formattedValue);
                    trigger('additionalTriggerPrice');
                  }}
                  icon={quoteAsset}
                  addonBefore={`${additionalTriggerPricePercent}%`}
                  status={errors.additionalTriggerPrice ? 'error' : undefined}
                />
              )}
            />

            <Controller
              name='additionalTriggerPriceType'
              control={control}
              render={({
                field, 
              }) => (
                <SingleSelect
                  maxWidth='max-content'
                  select={{
                    value: field.value,
                    placeholder: 'Last',
                    onChange: (value) => {
                      field.onChange(value);
                      trigger('additionalTriggerPriceType');
                    },
                  }}
                  options={[
                    {
                      label: 'Last', value: 'last', 
                    },
                    {
                      label: 'Bid', value: 'bid', 
                    },
                    {
                      label: 'Ask', value: 'ask', 
                    },
                  ]}
                />
              )}
            />
          </Box>
        </Box>
        <ErrorMessage message={errors?.additionalTriggerPrice?.message} />
      </Stack>
    );
  };
  
  return (
    <Box sx={wrpaper}>
      <Switch
        value={isAddEntryEnabled}
        onChange={setIsSkipAdditionalEntry}
        suffixText={(
          <Title
            level={5}
            styles={suffixText}
          >
            Additional entry
          </Title>
        )}
        size='small'
      />

      {isAddEntryEnabled && (
        <>
          <Segmented
            value={additionalOrderType}
            onChange={changeSegmentHandler}
            options={segments}
            block={true}
          />
  
          {additionalEntries.length > 0 && (
            <SafetyOrders
              additionalEntries={additionalEntries}
              onRemoveEntry={onRemoveEntry}
            />
          )}

          {renderTriggerPriceField()}
          {renderOrderPriceField()}
          {renderTotalField()}
          {renderUnitsField()}
          {renderSlider()}

          {errors.additionalEntryDuplicatePriceError && (
            <Text type='danger' styles={{
              fontSize: '12px', 
              marginTop: '4px',
              marginBottom: '8px',
            }}>
              {errors.additionalEntryDuplicatePriceError.message as string}
            </Text>
          )}
          
          {errors.additionalEntryInsufficientBalanceError && (
            <Text type='danger' styles={{
              fontSize: '12px', 
              marginTop: '4px',
              marginBottom: '8px',
            }}>
              {errors.additionalEntryInsufficientBalanceError.message as string}
            </Text>
          )}

          <MainButton
            icon={PlusGreen}
            styles={button}
            onClick={onAddEntry}
          >
            <Title
              level={5}
              styles={buttonTitle}
            >
              Add entry
            </Title>
          </MainButton>
        </>
      )}
    </Box>
  );
};

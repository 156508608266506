import { Box } from '@mui/material';
import { Progress } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import { getBinanceSymbols } from 'entities/terminal/model/selectors/get-binance-symbols.ts/get-binance-symbols';
import { formatterByQuote } from 'pages/home/helpers/formatter-by-quote';
import { useSelector } from 'react-redux';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Text } from 'shared/ui';
import { IWinRate } from '../interfaces';
import { background, left, progressText, right, row, text, title } from '../styles';

export const WinRate = (props: IWinRate) => {
  const {
    percent,
    items,
  } = props;
  const binanceSymbols = useSelector(getBinanceSymbols);

  const renderListItem = (item: IWinRate['items'][0]) => {
    let type: BaseType = 'secondary';
    let value = `${item.value}`;

    const isFundsValue = item.type === 'funds';
    const isCalculatedItem = item.type === 'total' || item.type === 'unrealized';

    if (isCalculatedItem) {
      if (+item.value > 0) {
        type = 'success';
        value = `+${currencyFormatter(+formatterByQuote(+value, item.currency!, binanceSymbols), 2)} ${item.currency?.toUpperCase() ?? ''}`;
      } else if (+item.value < 0) {
        type = 'danger';
        value = `${currencyFormatter(+formatterByQuote(+value, item.currency!, binanceSymbols), 2)} ${item.currency?.toUpperCase() ?? ''}`;
      } else {
        type = 'secondary';
        value = `${value} ${item.currency?.toUpperCase() ?? ''}`;
      }
    }

    if (isFundsValue) {
      value = `${formatterByQuote(+value, item.currency!, binanceSymbols)} ${item.currency?.toUpperCase() ?? ''}`;
    }

    return (
      <Box
        key={item.title}
        sx={row}
      >
        <Text
          type='secondary'
          styles={title}
        >
          {item.title}
        </Text>

        <Text styles={text} type={type}>
          {value ?? '-'}
        </Text>
      </Box>
    );
  };

  return (
    <Box sx={background}>
      <Box sx={left}>
        {items?.map((item: IWinRate['items'][0]) => (renderListItem(item)))}
      </Box>

      <Box sx={right}>
        <Progress
          percent={percent || 0}
          type='circle'
          status='normal'
          size={93}
        />

        <Text styles={progressText}>Win Rate</Text>
      </Box>
    </Box>
  );
};

import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { getExchangeCodeByExchangeUid } from '../api/get-exchange-code-by-exchange-uid';
import { Order, OrderStatus, OrderType, SmartTrade, SmartTradeStatus, ViewType } from '../types/smart-trade.types';
import { formatByPrecisionAndTrim } from './helpers';

export const getConditionalPrice = (order: Order) => {
  return order.triggerPrice ?? null;
};
  
export const isCompletedStatus = (status: OrderStatus | SmartTradeStatus) => {
  return status === 'completed';
};

export const isCancelledStatus = (status: OrderStatus | SmartTradeStatus) => {
  return status === 'cancelled';
};

export function getPrice(entity: Order | SmartTrade): string | null {
  if ('orderType' in entity) {
    if (!isCompletedStatus(entity.status)) {
      if (entity.orderType === 'LIMIT' || entity.orderType === 'CONDITIONAL_LIMIT') {
        return entity.limitPrice ?? null;
      } else if (entity.orderType === 'CONDITIONAL_MARKET') {
        return entity.triggerPrice ?? null;
      } else if (entity.orderType === 'MARKET') {
        return entity.executionPrice ?? null;
      }
    } else if (isCompletedStatus(entity.status)) {
      return entity.executionPrice ?? null;
    }
  } else if ('status' in entity) {
    if (!isCompletedStatus(entity.status) && !isCancelledStatus(entity.status)) {
      return entity.currentExchangePrice ?? null;
    }
    if (isCompletedStatus(entity.status)) {
      return null;
    }
  }
  return null;
}

export const showActionButtons = (trade: SmartTrade) => {
  return !isCompletedStatus(trade.status) && !isCancelledStatus(trade.status);
};

export const getUnits = (order: Order) => {
  if (!isCompletedStatus(order.status)) {
    return order.originalBase ?? null;
  } else if (isCompletedStatus(order.status)) {
    return order.executionBase ?? null;
  }
  return null;
};

export const orderTypeMapping: Record<OrderType, string> = {
  'CONDITIONAL_LIMIT': 'Cond.limit',
  'CONDITIONAL_MARKET': 'Cond.market',
  'LIMIT': 'Limit',
  'MARKET': 'Market',
  'SKIP_FIRST_STEP': 'Skip first step',
};
  
export const statusMapping: Record<OrderStatus, string> = {
  'active': 'active',
  'completed': 'completed',
  'new': 'pending',
  'await_send': 'pending',
  'request_active': 'pending',
  'cancel_after_active': 'pending',
  'request_cancel': 'pending',
  'cancelled': 'cancelled',
  'create_failed': 'failed',
  'cancel_failed': 'failed',
};
  
export const viewTypeMapping: Record<ViewType, string> = {
  'base_order': 'Base order',
  'additional_base_order': 'Additional base orders',
  'take_profit': 'Take profit targets',
  'stop_loss': 'Stop loss',
  'reduce_funds': 'Reduce funds',
  'force_order_step_second': 'Force order',
  'force_order_step_first': 'Force order',
};

export const orderPriority: ViewType[] = [
  'base_order',
  'additional_base_order',
  'reduce_funds',
  'take_profit',
  'stop_loss',
  'force_order_step_second',
  'force_order_step_first',
];

export const formatPrice = (price: number | null, asset: string) => {
  return price !== null ? `${price} ${asset}` : '';
};
  
export const formatUnits = (units: number | null, asset: string) => {
  return units !== null ? `${units} ${asset}` : '';
};
  
export const formatTotal = (price: number | null, units: number | null) => {
  if (price === null || units === null) return '';
  const total = price * units;
  return total.toString();
};
  
export const formatConditionalPrice = (conditionalPrice: number | null, asset: string) => {
  return conditionalPrice !== null ? `${conditionalPrice} ${asset}` : '';
};

export const sortOrders = (orders: Order[]) => {
  return [...orders].sort((a, b) => {
    const priorityA = orderPriority.indexOf(a.viewType);
    const priorityB = orderPriority.indexOf(b.viewType);
    if (priorityA !== priorityB) return priorityA - priorityB;

    const priceA = parseFloat(a.limitPrice ?? '') || parseFloat(a.triggerPrice ?? '') || parseFloat(a.executionPrice ?? '') || 0;
    const priceB = parseFloat(b.limitPrice ?? '') || parseFloat(b.triggerPrice ?? '') || parseFloat(b.executionPrice ?? '') || 0;

    return priceA - priceB;
  });
};
  
export const groupOrders = (orders: Order[]): Record<ViewType, Order[]> => {
  return [...orders].reduce<Record<ViewType, Order[]>>((acc, order) => {
    if (!acc[order.viewType]) acc[order.viewType] = [];
    acc[order.viewType].push(order);
    return acc;
  }, {} as Record<ViewType, Order[]>);
};
  
export const isReturnEmpty = (trade: SmartTrade): boolean => {
  return trade.returnPercent === null && trade.returnQuote === null && trade.returnBase === null;
};

export const getColorByReturnPercent = (returnPercent: string) => {
  return Number(returnPercent) >= 0 ? '#2ECD99' : '#F32222';
};

export const showProgressOnBar = (trade: SmartTrade) => {
  const baseOrderCompleted = trade.returnPercent;

  return (baseOrderCompleted && trade.status !== 'cancelled') ? true : false;
};

export const getTextColorByReturn = (returnPercent: string | null) => returnPercent && parseFloat(returnPercent) > 0 ? 'success' : 'danger';

export const getReturnValue = (trade: SmartTrade) => {
  const tradeSide = trade.sideFirstStep;
  
  return {
    value: tradeSide === 'BUY' ? trade.returnQuote : trade.returnBase,
    symbol: tradeSide === 'BUY' ? trade.quoteSymbol : trade.baseSymbol,
  };
};

export const getTextColorBySide = (side: string) => side === 'BUY' ? 'success' : 'danger';

export const getExchangeCode = async (trade: SmartTrade) => {
  const exchangeAccountUuid = trade.exchangeAccountUuid;
  const responseFromGetExchangeCode = await getExchangeCodeByExchangeUid(exchangeAccountUuid);
  
  const exchangeCode = responseFromGetExchangeCode.data.accounts[0].exchangeCode;
  
  return exchangeCode;
};

export const getFormattedAndTrimmedReturnValue = (trade: SmartTrade, tradeSymbol: CurrentSymbol): string => {
  const {
    chartPrecision, baseAssetPrecision, priceMin, priceMax, 
  } = tradeSymbol;
  
  const {
    value, symbol,
  } = getReturnValue(trade);
  
  if (value === null) return '0';

  const isBuySide = trade.sideFirstStep === 'BUY';
  const precisionToUse = isBuySide ? chartPrecision : baseAssetPrecision;
  const isNegative = Number(value) < 0;
  
  const formattedValue = formatByPrecisionAndTrim(
    value, 
    precisionToUse, 
    Number(priceMin), 
    Number(priceMax),
  );
  
  return `${isNegative ? '-' : ''}${formattedValue} ${symbol}`;
};

export const formatReturnPercent = (percent: string | null): string => {
  if (percent === null) return '0';
  return Number(percent).toFixed(2);
};

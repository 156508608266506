import { Box } from '@mui/material';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { MainButton, Text, WithDrawRequest } from 'shared/ui';
import { IWithDraw } from '../interfaces';
import { button, inner, wrapper } from '../styles';

export const WithDraw = (props: IWithDraw) => {
  const {
    fetchData,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const modalHandler = () => {
    setIsModalOpen((prev) => !prev);
  };
  
  return (
    <Fragment>
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Text>
            Create withdrawal request
          </Text>

          <Text>
            Your request will be processed within 24 hours
          </Text>
        </Box>

        <MainButton
          type='primary'
          ghost={true}
          styles={button}
          onClick={modalHandler}
        >
          Withdraw
        </MainButton>
      </Box>

      <WithDrawRequest
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        available={props.available}
        fetchData={fetchData}
      />
    </Fragment>
  );
};

import { preCode } from '../styles/pre-code';

export const renderJson = (data: any) => {
  let row = '';

  const isString = typeof data === 'string';
  if (isString) {
    row = data;
  } else {
    row = JSON.stringify(data, null, 2);
  }

  return (
    <pre style={{
      ...preCode,
      ...(isString && {
        whiteSpace: 'pre-line',
      }),
    }}>
      {row}
    </pre>
  );
};

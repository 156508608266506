import { Suspense } from 'react';
import { Loader } from 'shared/ui';
import { SuspenseWrapperProps } from '../model/types/suspense-wrapper';

const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({
  children,
}) => (
  <Suspense
    fallback={(
      <Loader isContentOverflow={true} />
    )}
  >
    {children}
  </Suspense>
);

export default SuspenseWrapper;

import {
  Discord,
  FaceBook,
  LinkedIn,
  Telegram,
  TikTok,
  Tw,
  Twitter,
  Youtube,
} from '../../../shared/icons';
import { ISocial } from '../interfaces';

export const socials: ISocial[] = [{
  link: 'https://www.linkedin.com/company/skyrexio',
  icon: LinkedIn,
}, {
  link: 'https://www.youtube.com/@skyrexio',
  icon: Youtube,
}, {
  link: 'https://x.com/skyrexio',
  icon: Twitter,
}, {
  link: 'https://t.me/skyrexio',
  icon: Telegram,
}, {
  link: 'https://tiktok.com/@skyrexio',
  icon: TikTok,
}, {
  link: 'https://www.facebook.com/people/Skyrexio/100095353624295',
  icon: FaceBook,
}, {
  link: 'https://discord.com/invite/X4cveyW',
  icon: Discord,
}, {
  link: 'https://www.tradingview.com/u/Skyrexio',
  icon: Tw,
}];

import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBotInfoParams {
  botUuid: string;
}

export const getBotInfo = async (params: GetBotInfoParams) => {
  const {
    botUuid, 
  } = params;
  
  const skyrexUserUuid = getSkyrexUuid();

  const response = await axios.request({
    url: '/bot/getByFilterBot',
    data: {
      skyrexUserUuids: [skyrexUserUuid],
      botUuids: [botUuid],
    },
  });

  return response.data.data.bots;
};

import { Box } from '@mui/material';
import { Modal } from 'antd';
import { Copy, Tabs } from 'widgets';
import { Text, Title } from 'shared/ui';
import { IAlertSource } from '../interfaces';
import {
  description,
  modal,
  modalChildren,
  title,
  titleWrapper,
  wrapper,
} from '../styles';
import { Indicator, Other, Strategy } from '../views';

export const AlertSource = (props: IAlertSource) => {
  const {
    isOpen,
    handleClose,
    sourceUuid,
    secretToken,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Title styles={title}>
            Alert messages
          </Title>

          <Text styles={description}>
            Control your bot with TradingView or other webhook alerts
          </Text>
        </Box>

        <Copy
          label='Webhook URL'
          value='https://panel.skyrexio.com/api/v1/deal/alert'
        />

        <Tabs
          tabs={[{
            label: 'Indicator',
            value: 0,
          }, {
            label: 'Strategy',
            value: 1,
          }, {
            label: 'Other',
            value: 2,
          }]}
          content={[(
            <Indicator
              sourceUuid={sourceUuid!}
              secretToken={secretToken!}
            />
          ), (
            <Strategy
              sourceUuid={sourceUuid!}
              secretToken={secretToken!}
            />
          ), (
            <Other
              sourceUuid={sourceUuid!}
              secretToken={secretToken!}
            />
          )]}
        />
      </Box>
    </Modal>
  );
};

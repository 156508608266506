import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import pako from 'pako';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';

interface HTXKline {
  id: number;        // Время закрытия свечи
  open: string;      // Цена открытия
  close: string;     // Цена закрытия
  low: string;       // Минимальная цена
  high: string;      // Максимальная цена
  amount: string;    // Объем в базовой валюте
  vol: string;       // Объем в котируемой валюте
  count: number;     // Количество сделок
}

interface HTXMessage {
  ch: string;        // Канал
  ts: number;        // Временная метка
  tick: HTXKline;    // Данные свечи
  ping?: number;     // Пинг
  subbed?: string;   // Подтверждение подписки
  error?: string;    // Ошибка
}

const handleBinaryMessage = async (data: Blob): Promise<string> => {
  const arrayBuffer = await data.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  const decompressed = pako.inflate(uint8Array, {
    to: 'string', 
  });
  return decompressed;
};

export const createHTXHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo,
    resolution,
    dispatch,
    onTick,
  } = params;
  
  let ws: WebSocket | null = null;
  let pingInterval: NodeJS.Timeout | null = null;

  const handleMessage = async (event: MessageEvent) => {
    try {
      let message: HTXMessage;
      
      if (event.data instanceof Blob) {
        const decompressed = await handleBinaryMessage(event.data);
        message = JSON.parse(decompressed);
      } else {
        message = JSON.parse(event.data);
      }

      console.log('Received HTX message:', message);
      
      if (message.ping) {
        ws?.send(JSON.stringify({
          pong: message.ping, 
        }));
        return;
      }

      if (message.subbed) {
        console.log('Successfully subscribed to HTX channel:', message.subbed);
        return;
      }

      if (message.error) {
        console.error('HTX WebSocket error:', message);
        return;
      }

      if (message.ch && message.tick) {
        const kline = message.tick;
        
        if (!isValidKline(kline)) {
          console.warn('Получены неполные данные свечи HTX:', kline);
          return;
        }

        const bar: Bar = createBar(kline);
        console.log('Created bar:', bar);
        dispatch(terminalActions.setLimitLastPrice(bar.close));
        onTick(bar);
      }
    } catch (error) {
      console.error('Ошибка обработки сообщения HTX:', error);
    }
  };

  const isValidKline = (kline: HTXKline): boolean => {
    return Boolean(
      kline.id &&
      kline.open &&
      kline.close &&
      kline.high &&
      kline.low &&
      kline.amount,
    );
  };

  const createBar = (kline: HTXKline): Bar => ({
    time: kline.id * 1000,
    open: parseFloat(kline.open),
    high: parseFloat(kline.high),
    low: parseFloat(kline.low),
    close: parseFloat(kline.close),
    volume: parseFloat(kline.amount),
  });

  const getSubscriptionMessage = () => {
    const interval = resolutionToExchangeInterval(resolution as ResolutionString, 'htx');
    const symbol = symbolInfo.full_name;
    
    return {
      sub: `market.${symbol}.kline.${interval}`,
      id: `kline_${symbol}_${interval}`,
    };
  };

  const startPingPong = (ws: WebSocket) => {
    if (pingInterval) {
      clearInterval(pingInterval);
    }
    
    pingInterval = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ 
          ping: Date.now(),
        }));
      }
    }, 20000);
  };

  const handleOpen = (ws: WebSocket) => {
    console.log('Connecting to HTX WebSocket...');
    
    const subscriptionMsg = getSubscriptionMessage();
    console.log('Sending subscription message:', subscriptionMsg);
    ws.send(JSON.stringify(subscriptionMsg));
    
    startPingPong(ws);
  };

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://api.huobi.pro/ws',
        handleMessage,
        null,
        handleOpen,
      );
      
      if (ws) {
        ws.binaryType = 'blob';
      }
      
      return ws;
    },
    disconnect: () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        const interval = resolutionToExchangeInterval(resolution as ResolutionString, 'htx');
        const symbol = symbolInfo.full_name;
        
        ws.send(JSON.stringify({
          unsub: `market.${symbol}.kline.${interval}`,
          id: `kline_${symbol}_${interval}`,
        }));
      }

      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }

      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

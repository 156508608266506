import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { ICheckBot } from '../interfaces';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const checkBot = async (params: ICheckBot) => {
  const response = await axios.request({
    url: '/bot/getByFilterBot',
    data: params,
  });

  return response.data.data.bots;
};

import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  padding: {
    xs: '48px 0',
    md: '90px 0',
  },
};

import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});
  
//@TODO move to new terminal
export const manualTradeCloseByLimit = async (data: any) => {
    
    
  const response = await axios.request({
    url: '/smartTrade/closeByLimitPrice',
    data: {
      smartTradeUuids: [
        data,
      ],
    },
  });
  
  return response.data;
};
  
export const manualTradeCloseByMarket = async (data: any) => {
    
  const response = await axios.request({
    url: '/smartTrade/closeByMarketPrice',
    data: {
      smartTradeUuids: [data],
    },
  });
  
  return response.data;
};
  
export const manualTradeOpenByLimit = async (data: any) => {
    
  const response = await axios.request({
    url: '/smartTrade/openByLimitPrice',
    data: {
      smartTradeUuids: [
        data,
      ],
    },
  });
  
  return response.data;
};
  
export const manualTradeOpenByMarket = async (data: any) => {
    
  const response = await axios.request({
    url: '/smartTrade/openByMarketPrice',
    data: {
      smartTradeUuids: [
        data,
      ],
    },
  });
  
  return response.data;
};

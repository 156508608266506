import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveTrades } from 'pages/trading-terminal-page/trading-chart/api/get-active-trades';
import { SmartTrade } from '../types/new-terminal-schema';

export const setActiveTrades = createAsyncThunk<SmartTrade[], string[], { rejectValue: string; }>(
  'newTerminal/setActiveTrades',
  async (accounts: string[], params) => {
    const {
      rejectWithValue, 
    } = params;

    try {
      const trades = await getActiveTrades(accounts);
      
      return trades as SmartTrade[];
    } catch (error) {
      return rejectWithValue('error');
    }
  },
);

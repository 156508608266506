import { SegmentedLabeledOption } from 'antd/es/segmented';

export const segments: SegmentedLabeledOption<string>[] = [{
  label: 'Limit',
  value: 'limit',
}, {
  label: 'Market',
  value: 'market',
}, {
  label: 'Conditional',
  value: 'conditional',
}];

import { configureStore } from '@reduxjs/toolkit';
import { botReducer } from 'entities/bot/model/slices/bot-slice';
import { exchangeReducer } from 'entities/exchange/model/slices/exchange-slice';
import { newTerminalReducer } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { terminalReducer } from 'entities/terminal/model/slices/terminal-slice';
import { userReducer } from 'entities/user/model/slices/user-slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { StateSchema } from '../types/state-schema';

export const store = configureStore<StateSchema>({
  reducer: {
    user: userReducer,
    exchange: exchangeReducer,
    terminal: terminalReducer,
    bot: botReducer,
    newTerminal: newTerminalReducer,
  },
  devTools: true,
});

export function createReduxStore(initialState?: StateSchema) {
  return configureStore<StateSchema>({
    reducer: {
      user: userReducer,
      exchange: exchangeReducer,
      terminal: terminalReducer,
      bot: botReducer,
      newTerminal: newTerminalReducer,
    },
    devTools: true,
    preloadedState: initialState,
  });
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import axios from 'axios';
import { ENV } from 'shared/config/environment';
import { EXCHANGE_NAMES, ExchangeName } from 'shared/consts/available-exchanges';

interface FetchExchangeProps {
  symbol: string;
  fromDate: number;
  toDate: number;
  chartInterval: string;
  limit: number;
  instId?: string;
  gateSymbol?: string;
  cryptoComSymbol?: string;
  bitmartSymbol?: string;
  exchange?: string;
  cryptoCompareSymbol: string;
}

interface ExchangeConfig {
  url: string;
  formatParams: (props: FetchExchangeProps) => URLSearchParams | {
    endpoint: string;
    params: URLSearchParams;
  };
  formatResponse: (data: any) => any[];
}

const exchangeConfigs: Record<ExchangeName, ExchangeConfig> = {
  [EXCHANGE_NAMES.BINANCE]: {
    url: 'https://api.binance.com/api/v3/klines',
    formatParams: (props) => new URLSearchParams({
      symbol: props.symbol,
      interval: props.chartInterval,
      startTime: props.fromDate.toString(),
      endTime: props.toDate.toString(),
      limit: props.limit.toString(),
    }),
    formatResponse: (data) => data.map(formatOHLCV),
  },

  [EXCHANGE_NAMES.BYBIT]: {
    url: 'https://api.bybit.com/v5/market/kline',
    formatParams: (props) => new URLSearchParams({
      symbol: props.symbol,
      category: 'spot',
      interval: props.chartInterval,
      start: props.fromDate.toString(),
      end: props.toDate.toString(),
      limit: props.limit.toString(),
    }),
    formatResponse: (data) => data.result.list.map(formatOHLCV).reverse(),
  },

  [EXCHANGE_NAMES.OKX]: {
    url: 'https://www.okx.com/api/v5/market/candles',
    formatParams: (props) => new URLSearchParams({
      instId: props.symbol,
      bar: props.chartInterval,
      after: props.toDate.toString(),
      limit: props.limit.toString(),
    }),
    formatResponse: (data) => data.data.map(formatOHLCV).reverse(),
  },

  [EXCHANGE_NAMES.GATEIO]: {
    url: `${ENV['ENV'] === 'development' ? 'https://cors-anywhere.herokuapp.com/https://api.gateio.ws/api/v4/spot/candlesticks' : `${window.location.origin}/gateio-api/api/v4/spot/candlesticks`}`,
    formatParams: (props) => {
      return new URLSearchParams({
        currency_pair: props.symbol,
        from: Math.floor(props.fromDate / 1000).toString(),
        to: Math.floor(props.toDate / 1000).toString(),
        interval: props.chartInterval,
        limit: props.limit.toString(),
      });
    },
    formatResponse: (data) => data.map((item: any) => ({
      time: Number(item[0]) * 1000,
      open: Number(parseFloat(item[5]).toFixed(8)),
      high: Number(parseFloat(item[3]).toFixed(8)),
      low: Number(parseFloat(item[4]).toFixed(8)),
      close: Number(parseFloat(item[2]).toFixed(8)),
      volume: Number(parseFloat(item[1]).toFixed(8)),
    })),
  },

  [EXCHANGE_NAMES.CRYPTO_COM]: {
    url: 'https://api.crypto.com/exchange/v1/public/get-candlestick',
    formatParams: (props) => {
      return new URLSearchParams({
        instrument_name: props.symbol,
        timeframe: props.chartInterval,
        count: props.limit.toString(),
        start_ts: props.fromDate.toString(),
        end_ts: props.toDate.toString(),
      });
    },
    formatResponse: (data) => {
      if (!data.result?.data) throw new Error('Wrong format of response from API Crypto.com');
      return data.result.data.slice(0, data.limit).map((item: any) => ({
        time: Number(item.t),
        open: Number(parseFloat(item.o).toFixed(8)),
        high: Number(parseFloat(item.h).toFixed(8)),
        low: Number(parseFloat(item.l).toFixed(8)),
        close: Number(parseFloat(item.c).toFixed(8)),
        volume: Number(parseFloat(item.v).toFixed(8)),
      }));
    },
  },
  
  [EXCHANGE_NAMES.BITMART]: {
    url: 'https://api-cloud.bitmart.com/spot/quotation/v3/klines',
    formatParams: (props) => new URLSearchParams({
      symbol: props.symbol,
      step: props.chartInterval,
      limit: (props.limit >= 200 ? 200 : props.limit).toString(),
      before: Math.floor(props.toDate / 1000).toString(),
    }),
    formatResponse: (data) => {
      if (!data.data?.length) throw new Error('Wrong format of response from API Bitmart');
      return data.data.map((item: any[]) => ({
        time: Number(item[0]) * 1000, // умножаем на 1000 для перевода в миллисекунды
        open: Number(parseFloat(item[1]).toFixed(8)),
        high: Number(parseFloat(item[2]).toFixed(8)),
        low: Number(parseFloat(item[3]).toFixed(8)),
        close: Number(parseFloat(item[4]).toFixed(8)),
        volume: Number(parseFloat(item[5]).toFixed(8)),
      }));
    },
  },
  [EXCHANGE_NAMES.HTX]: {
    url: 'https://api.huobi.pro/market/history/kline',
    formatParams: (props) => new URLSearchParams({
      symbol: props.symbol.toLowerCase(),
      period: props.chartInterval,
      size: props.limit.toString(),
      from: Math.floor(props.fromDate / 1000).toString(),
      to: Math.floor(props.toDate / 1000).toString(),
    }),
    formatResponse: (data) => {
      if (!data.data) throw new Error('Wrong format of response from API HTX');
      return data.data.map((item: any) => ({
        time: item.id * 1000,
        open: Number(parseFloat(item.open).toFixed(8)),
        high: Number(parseFloat(item.high).toFixed(8)),
        low: Number(parseFloat(item.low).toFixed(8)),
        close: Number(parseFloat(item.close).toFixed(8)),
        volume: Number(parseFloat(item.amount).toFixed(8)),
      }));
    },
  },
  [EXCHANGE_NAMES.CRYPTO_COMPARE]: {
    url: 'https://data-api.cryptocompare.com/spot/v1/historical',
    formatParams: (props) => {
      const getTimeframeInMinutes = (interval: string): number => {
        const value = parseInt(interval);
        if (interval.includes('d')) return value * 24 * 60;     // дни в минуты
        if (interval.includes('h')) return value * 60;          // часы в минуты
        if (interval.includes('m')) return value;               // минуты
        return value;                                           // если просто число, считаем минутами
      };

      const minutes = getTimeframeInMinutes(props.chartInterval);
      const endpoint = minutes >= 24 * 60 ? 'days' :    // >= 1 день
        minutes >= 60 ? 'hours' :         // >= 1 час
          'minutes';                        // < 1 час

      return {
        endpoint,
        params: new URLSearchParams({
          market: props.exchange?.toLowerCase() || 'cccagg',
          instrument: props?.cryptoCompareSymbol,
          limit: props.limit.toString(),
          aggregate: '1',
          fill: 'true',
          apply_mapping: 'true',
          response_format: 'JSON',
          to_ts: Math.floor(props.toDate / 1000).toString(),
        }),
      };
    },
    formatResponse: (data) => {
      if (!data.Data?.length) throw new Error('Wrong format of response from CryptoCompare');
      return data.Data.map((item: any) => ({
        time: item.TIMESTAMP * 1000,
        open: Number(parseFloat(item.OPEN).toFixed(8)),
        high: Number(parseFloat(item.HIGH).toFixed(8)),
        low: Number(parseFloat(item.LOW).toFixed(8)),
        close: Number(parseFloat(item.CLOSE).toFixed(8)),
        volume: Number(parseFloat(item.VOLUME).toFixed(8)),
      }));
    },
  },
};

const formatOHLCV = (item: any) => ({
  time: Number(item[0]),
  open: Number(parseFloat(item[1]).toFixed(8)),
  high: Number(parseFloat(item[2]).toFixed(8)),
  low: Number(parseFloat(item[3]).toFixed(8)),
  close: Number(parseFloat(item[4]).toFixed(8)),
  volume: Number(parseFloat(item[5]).toFixed(8)),
});

const fetchExchangeData = async (exchange: ExchangeName, props: FetchExchangeProps) => {
  const config = exchangeConfigs[exchange];

  if (!config) throw new Error(`Exchange ${exchange} is not supported`);

  try {
    const params = config.formatParams(props);
    console.log('===params', params);
    
    const response = await axios.get(config.url, {
      params: config.formatParams(props),
    });
    return config.formatResponse(response.data);
  } catch (error) {
    console.error(`Error fetching data from ${exchange}:`, error);
    
    // Пробуем получить данные через CryptoCompare
    try {
      console.log(`Attempting fallback to CryptoCompare for ${exchange}`);
      const headers = ENV.CRYPTOCOMPARE_API_KEY 
        ? {
          'Authorization': `Apikey ${ENV.CRYPTOCOMPARE_API_KEY}`, 
        }
        : {};
      
      const formattedData = exchangeConfigs[EXCHANGE_NAMES.CRYPTO_COMPARE].formatParams({
        ...props,
        exchange: exchange.toUpperCase(),
      }) as { endpoint: string; params: URLSearchParams; };

      const baseUrl = exchangeConfigs[EXCHANGE_NAMES.CRYPTO_COMPARE].url;
      const fullUrl = `${baseUrl}/${formattedData.endpoint}`;
      
      console.log(`Making request to: ${fullUrl}`);
      
      const response = await axios.get(fullUrl, {
        params: formattedData.params,
        headers,
      });
      
      const data = exchangeConfigs[EXCHANGE_NAMES.CRYPTO_COMPARE].formatResponse(response.data);
      
      return data;
    } catch (fallbackError) {
      console.error('Fallback to CryptoCompare failed:', fallbackError);
      throw error;
    }
  }
};

export const fetchBinanceBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.BINANCE, props);
export const fetchBybitBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.BYBIT, props);
export const fetchOkxBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.OKX, props);
export const fetchGateBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.GATEIO, props);
export const fetchCryptocomBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.CRYPTO_COM, props);
export const fetchBitmartBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.BITMART, props);
export const fetchHtxBars = (props: FetchExchangeProps) => fetchExchangeData(EXCHANGE_NAMES.HTX, props);

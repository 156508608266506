import { getPlatform } from '../helpers';
import { IExchanges } from '../interfaces';

export const Exchanges = (props: IExchanges) => {
  const {
    platform,
    isModalOpen,
    returnToAllExchanges,
    handleClose,
  } = props;

  return getPlatform({
    platform,
    isModalOpen: !!isModalOpen,
    returnToAllExchanges,
    handleClose,
  });
};

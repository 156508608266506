import { EXCHANGE_NAMES, ExchangeName } from 'shared/consts/available-exchanges';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { normalizeExchangeName } from '../utils/chart-utils';
import { createBinanceHandler } from './binance-handler';
import { createBitmartHandler } from './bitmart-handler';
import { createBybitHandler } from './bybit-handler';
import { createCryptoComHandler } from './crypto-com-handler';
import { createCryptoCompareHandler } from './cryptocompare-handler';
import { createGateHandler } from './gateio-handler';
import { createHTXHandler } from './htx-handler';
import { createOkxHandler } from './okx-handler';

const handlers: Record<ExchangeName, (params: WebSocketHandlerParams) => WebSocketHandler> = {
  [EXCHANGE_NAMES.BINANCE]: createBinanceHandler,
  [EXCHANGE_NAMES.BYBIT]: createBybitHandler,
  [EXCHANGE_NAMES.OKX]: createOkxHandler,
  [EXCHANGE_NAMES.GATEIO]: createGateHandler,
  [EXCHANGE_NAMES.CRYPTO_COM]: createCryptoComHandler,
  [EXCHANGE_NAMES.BITMART]: createBitmartHandler,
  [EXCHANGE_NAMES.HTX]: createHTXHandler,
  [EXCHANGE_NAMES.CRYPTO_COMPARE]: createCryptoCompareHandler,
};

// Создаем обертку для WebSocket хендлера с поддержкой fallback
const createFallbackHandler = (
  originalHandler: (params: WebSocketHandlerParams) => WebSocketHandler,
  params: WebSocketHandlerParams,
): WebSocketHandler => {
  let primaryWs: WebSocket | null = null;
  let fallbackWs: WebSocket | null = null;
  let isUsingFallback = false;

  const handlePrimaryError = () => {
    console.warn(`Primary WebSocket failed for ${params.exchangeName}, switching to CryptoCompare`);
    if (primaryWs) {
      primaryWs.close();
      primaryWs = null;
    }
    
    // Подключаемся к CryptoCompare
    if (!isUsingFallback) {
      isUsingFallback = true;
      const cryptoCompareHandler = createCryptoCompareHandler(params);
      fallbackWs = cryptoCompareHandler.connect();
    }
  };

  const originalInstance = originalHandler(params);

  return {
    //@ts-ignore
    connect: () => {
      try {
        primaryWs = originalInstance.connect();
        
        // Добавляем обработчик ошибок для переключения на fallback
        if (primaryWs) {
          const originalOnError = primaryWs.onerror;
          primaryWs.onerror = (error) => {
            if (originalOnError) originalOnError.call(primaryWs, error);
            handlePrimaryError();
          };

          // Также проверяем состояние через таймаут
          setTimeout(() => {
            if (primaryWs && primaryWs.readyState !== WebSocket.OPEN) {
              handlePrimaryError();
            }
          }, 5000); // 5 секунд на установку соединения
        }

        return primaryWs;
      } catch (error) {
        console.error('Error connecting to primary WebSocket:', error);
        handlePrimaryError();
        return fallbackWs;
      }
    },
    disconnect: () => {
      if (primaryWs) {
        primaryWs.close();
        primaryWs = null;
      }
      if (fallbackWs) {
        fallbackWs.close();
        fallbackWs = null;
      }
      isUsingFallback = false;
    },
  };
};

// Модифицируем основную фабрику
export const createWebSocketHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const exchange = normalizeExchangeName(params.exchangeName) as ExchangeName;
  const createHandler = handlers[exchange];
  
  if (!createHandler) {
    throw new Error(`Unsupported exchange: ${exchange}`);
  }

  // Оборачиваем оригинальный хендлер в fallback обертку
  return createFallbackHandler(createHandler, params);
};

import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: {
    xs: '32px 10px',
    md: '32px',
  },
  border: '1px solid #E9E9E9',
  borderRadius: '16px',
  backgroundColor: '#fff',
};

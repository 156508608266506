import { createAsyncThunk } from '@reduxjs/toolkit';
import { editTrade } from 'pages/manual-trading/trading-terminal/api/edit-update-trade';
import { checkTradeCreated, getTradeRichInfo } from 'pages/trading-terminal-page/order-panel/ExchangeForm/api/check-trade-created';
import { delay } from 'shared/helpers/delay';
import { SmartTrade } from '../types/new-terminal-schema';

export const editTradeUpdate = createAsyncThunk<SmartTrade, any, { rejectValue: string; }>(
  'newTerminal/editTradeUpdate',
  async (collectedDataForSubmit: any, {
    rejectWithValue, 
  }) => {
    try {
    //   const preparedData = prepareDataForUpdateTrade(collectedDataForSubmit);
      const response = await editTrade(collectedDataForSubmit);
        
      if (!response.success) {
        return rejectWithValue('Oops error when creating trade');
      }
        
      const dataForCheckRequest = {
        smartTradeUuids: [response.data.smartTradeUuid],
      };
        
      await delay(2000);
        
      const responseFromCheckTrade = await checkTradeCreated(dataForCheckRequest);
        
      if (responseFromCheckTrade.data.smartTrades[0].status === 'cancelled') {
        return rejectWithValue('Trade was cancelled');
      }
        
      const responseTradeRichInfo = await getTradeRichInfo(dataForCheckRequest);
      return responseTradeRichInfo.data.smartTrades[0];
    } catch (error) {
      return rejectWithValue('error');
    }
  },
);

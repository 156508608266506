import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createTrade = async (data: any) => {
  const response = await axios.request({
    url: '/smartTrade/create',
    data: {
      form: data,
    },
  });

  return response.data;
};

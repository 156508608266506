import { getExchangesIds } from '../../../widgets/exchanges/api/get-exchanges';

export async function setShortExchangesData(skyrexUserUuid: string) {
  const exchangeDataResponse = await getExchangesIds(skyrexUserUuid);
  if (!exchangeDataResponse.success) {
    throw new Error(exchangeDataResponse.data.message);
  }

  const {
    accounts,
  } = exchangeDataResponse.data;

  return accounts;
}

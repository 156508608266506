import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
import { createBaseWebSocketHandler } from './websocket-utils';

export const createOkxHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo, resolution, dispatch, onTick, 
  } = params;
  
  let ws: WebSocket | null = null;

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    if (message.data) {
      const kline = message.data[0];
      if (kline) {
        const bar: Bar = {
          time: +kline[0],
          open: +kline[1],
          high: +kline[2],
          low: +kline[3],
          close: +kline[4],
          volume: +kline[5],
        };
        dispatch(terminalActions.setLimitLastPrice(bar.close));
        onTick(bar);
      }
    }
  };

  const getSubscriptionMessage = () => ({
    op: 'subscribe',
    args: [{
      channel: resolutionToExchangeInterval(resolution as ResolutionString, 'okxws'),
      instId: symbolInfo?.full_name,
    }],
  });

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://ws.okx.com:8443/ws/v5/business',
        handleMessage,
        getSubscriptionMessage(),
      );
      return ws;
    },
    disconnect: () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBotStatisticParams {
  botUuid: string;
  dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
}

export const getBotStatistic = async (params: GetBotStatisticParams) => {
  const { 
    botUuid, 
    dates,
  } = params;

  const formattedDateCloseFrom = dates && dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD 00:00:00') : null;
  const formattedDateCloseTo = dates && dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD 23:59:59') : null;
  
  const response = await axios.request({
    url: '/bot/calculateStatistic',
    data: {
      botUuid,
      closedDateFrom: formattedDateCloseFrom,
      closedDateTo: formattedDateCloseTo,
    },
  });

  return response.data.data;
};

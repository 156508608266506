export const createBaseWebSocketHandler = (
  url: string,
  onMessage: (event: MessageEvent) => void,
  subscriptionMessage?: any,
  onOpen?: (ws: WebSocket) => void,
) => {
  const ws = new WebSocket(url);

  ws.onopen = () => {
    console.log('WebSocket connection established');
    if (subscriptionMessage) {
      ws.send(JSON.stringify(subscriptionMessage));
    }
    if (onOpen) {
      onOpen(ws);
    }
  };

  ws.onmessage = onMessage;

  ws.onerror = (error) => {
    console.error('WebSocket Error:', error);
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed');
  };

  return ws;
};

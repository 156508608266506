import { calculatePercentDifference, calculateTotal, calculateUnits, formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

interface Entry {
  additionalOrderType: string;
  price?: number;
  total: number;
  triggerPrice?: number;
  orderPrice?: number;
  units: number;
  typeTriggerPrice?: string;
}

export const useAdditionalEntryInputsLogic = (
  currentSymbol: any, 
  userWallet: any, 
) => {
  
  const {
    watch, setValue, getValues, setError, 
  } = useFormContext();
  
  const isSkipBaseOrder = watch('isSkipBaseOrder');

  const orderPrice = watch('orderPrice');
  const isAddEntryEnabled = watch('isAddEntryEnabled');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  const additionalOrderType = watch('additionalOrderType');
  const lastChangedField = watch('lastChangedField');
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalUnits = watch('additionalUnits');
  const additionalTotal = watch('additionalTotal');
  
  const handleAdditionalTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);
        
    if (!isNaN(triggerPrice)) {
      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('additionalTriggerPricePercent', percentDifference);
      } else {
        setValue('additionalTriggerPricePercent', '0');
      }
    }
        
    if (!isNaN(triggerPrice) && additionalOrderType === 'cond.market') {
      if (lastChangedField === null || lastChangedField === 'additionalTotal') {
        const newUnits = calculateUnits(parseFloat(additionalTotal), additionalTriggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('additionalUnits', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(additionalUnits), additionalTriggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('additionalTotal', formattedTotal);
      }
    }
        
  }, [
    setValue, 
    orderPrice, 
    additionalOrderType, 
    lastChangedField,
    additionalTotal,
    additionalUnits,
    additionalTriggerPrice,
    currentSymbol?.quoteAssetPrecision,
    currentSymbol?.baseAssetPrecision,
    currentSymbol?.lotMin,
    currentSymbol?.lotMax,
    currentSymbol?.minNotional,
    currentSymbol?.maxNotional,
  ]);
  
  const handleAdditionalOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
        
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'additionalTotal') {
        const newUnits = calculateUnits(parseFloat(additionalTotal), price, currentSymbol.baseAssetPrecision);
        setValue('additionalUnits', newUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(additionalUnits), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('additionalTotal', formattedTotal);
            
        const percentageValue = (parseFloat(formattedTotal) / userWallet.free) * 100;
        setValue('additionalSlider', roundPercentage(percentageValue));
            
      }
    }
        
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('additionalOrderPricePercent', percentDifference.toString());
    }
        
  }, [
    lastChangedField,
    additionalUnits,
    additionalTotal,
    setValue, 
    orderPrice,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    userWallet.free,
  ]);

  const handleAdditionalOrderPricePercentChange = useCallback((value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newAdditionalOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedAdditionalOrderPrice = formatByPrecisionAndTrim(newAdditionalOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('additionalOrderPrice', formattedAdditionalOrderPrice);
    }
  }, [
    orderPrice,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.priceMin,
    currentSymbol.priceMax,
    setValue,
  ]);
  
  const handleAdditionalUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'additionalUnits');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(additionalOrderPrice), currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('additionalTotal', formattedTotal);

      const percentageValue = (parseFloat(formattedTotal) / userWallet.free) * 100;
      setValue('additionalSlider', roundPercentage(percentageValue));
    }
  }, [ 
    setValue, 
    additionalOrderPrice,
    userWallet.free,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
  ]);
  
  const handleAdditionalTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'additionalTotal');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(additionalOrderPrice), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('additionalUnits', formattedUnits);
    }
  }, [
    setValue,
    additionalOrderPrice,
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
  ]);
  
  const handleAdditionalSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'additionalTotal');
    const newTotal = (userWallet.free * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('additionalTotal', formattedTotal);
    
    const newUnits = calculateUnits(newTotal, parseFloat(additionalOrderPrice), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('additionalUnits', formattedUnits);
  }, [
    userWallet.free, 
    additionalOrderPrice,
    currentSymbol?.quoteAssetPrecision, 
    currentSymbol?.baseAssetPrecision, 
    currentSymbol?.lotMin, 
    currentSymbol?.lotMax, 
    currentSymbol?.minNotional,
    currentSymbol?.maxNotional,
    setValue,
  ]);
  
  const handleAddEntry = useCallback(() => {
    const {
      additionalOrderType, 
      additionalOrderPrice, 
      additionalTriggerPrice, 
      additionalTriggerPriceType,
      additionalTotal, 
      additionalUnits, 
      additionalEntries,
      total,
    } = getValues();

    if (parseFloat(additionalTotal) < parseFloat(currentSymbol.minNotional)) {
      setError('additionalEntryMinNotionalError', {
        type: 'manual',
        message: `Order value must be greater than ${currentSymbol.minNotional}`,
      });
      return;
    }
    
    const baseTotal = isSkipBaseOrder ? 0 : parseFloat(total);

    const newEntry: Entry = {
      additionalOrderType,
      total: parseFloat(additionalTotal),
      units: parseFloat(additionalUnits),
    };

    switch (additionalOrderType) {
    case 'cond.market':
      newEntry.price = parseFloat(additionalTriggerPrice);
      newEntry.triggerPrice = parseFloat(additionalTriggerPrice);
      newEntry.typeTriggerPrice = additionalTriggerPriceType;
      break;
    case 'cond.limit':
      newEntry.price = parseFloat(additionalOrderPrice);
      newEntry.triggerPrice = parseFloat(additionalTriggerPrice);
      newEntry.typeTriggerPrice = additionalTriggerPriceType;
      newEntry.orderPrice = parseFloat(additionalOrderPrice);
      break;
    case 'limit':
      newEntry.price = parseFloat(additionalOrderPrice);
      newEntry.orderPrice = parseFloat(additionalOrderPrice);
      break;
    default:
      console.error('Неизвестный тип ордера');
      return;
    }

    const priceToCheck = newEntry.price;
    if (additionalEntries.some((entry: Entry) => (entry.price === priceToCheck))) {
      toast.error('Price assigned to another order.');
      return;
    }

    const totalSum = additionalEntries.reduce((sum: number, entry: Entry) => sum + entry.total, 0) + 
      baseTotal + 
      newEntry.total;

    if (totalSum > +userWallet.free) {
      setError('additionalEntryInsufficientBalanceError', {
        type: 'manual',
        message: 'Insufficient balance to add order.',
      });
      return;
    }

    const updatedEntries = [...additionalEntries, newEntry];
    setValue('additionalEntries', updatedEntries, {
      shouldValidate: true, 
    });
    
  }, [getValues, setValue, setError, isSkipBaseOrder, userWallet.free, currentSymbol.minNotional, currentSymbol.maxNotional]);

  const handleRemoveEntry = useCallback((index: number) => {
    const {
      additionalEntries, 
    } = getValues();
    const updatedEntries = additionalEntries.filter((_: any, i: number) => i !== index);
    setValue('additionalEntries', updatedEntries, {
      shouldValidate: true, 
    });
  }, [getValues, setValue]);

  
  const updateAdditionalEntryValues = useCallback(() => {
    if (isAddEntryEnabled) {
      return;
    }

    const currentOrderPrice = parseFloat(orderPrice);
    const currentAdditionalOrderPrice = currentOrderPrice * 0.95;

    const formattedAdditionalOrderPrice = formatByPrecisionAndTrim(
      currentAdditionalOrderPrice.toString(),
      currentSymbol.chartPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );

    setValue('additionalOrderPrice', formattedAdditionalOrderPrice);
    setValue('additionalTriggerPrice', formattedAdditionalOrderPrice);
  
    const additionalTotalCalculated = userWallet.free * 0.1;
    const formattedAdditionalTotalRaw = formatByPrecisionAndTrim(
      additionalTotalCalculated.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
  
    const additionalUnitsCalculated = calculateUnits(+formattedAdditionalTotalRaw, currentAdditionalOrderPrice, currentSymbol.baseAssetPrecision);
    const formattedAdditionalUnits = formatByPrecisionAndTrim(
      additionalUnitsCalculated.toString(),
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    );
    
    const additionalTotal = parseFloat(formattedAdditionalUnits) * parseFloat(formattedAdditionalOrderPrice);
    const formattedAdditionalTotal = formatByPrecisionAndTrim(
      additionalTotal.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
  
    setValue('additionalTotal', formattedAdditionalTotal);
    setValue('additionalUnits', formattedAdditionalUnits);

    const percentDifference = calculatePercentDifference(currentAdditionalOrderPrice, currentOrderPrice);
    const roundedPercentDifference = Math.round(percentDifference);
  
    setValue('additionalOrderPricePercent', roundedPercentDifference.toString());
    setValue('additionalTriggerPricePercent', roundedPercentDifference.toString());
    setValue('additionalSlider', 10);
  }, [
    isAddEntryEnabled, 
    orderPrice,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.priceMin,
    currentSymbol.priceMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    setValue, 
    userWallet.free, 
    currentSymbol?.baseAssetPrecision, 
    currentSymbol?.quoteAssetPrecision,
  ]);
  
  return {
    handleAdditionalTriggerPriceChange,
    handleAdditionalOrderPriceChange,
    handleAdditionalOrderPricePercentChange,
    handleAdditionalUnitsChange,
    handleAdditionalTotalChange,
    handleAdditionalSliderChange,
    handleAddEntry,
    handleRemoveEntry,
    updateAdditionalEntryValues,
  };
};

import { SmartTrade } from 'entities/new-terminal/model/types/new-terminal-schema';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useFundsActionsForm } from '../../hooks/use-funds-actions-form';
import { FundsForm } from '../funds-form/funds-form';

interface FundsFormWrapperProps {
  isAddFunds: boolean;
  tradeSymbol: CurrentSymbol;
  trade: SmartTrade;
  exchangeAccount: any;
  onClose: () => void;
}

export const FundsFormWrapper = ({
  isAddFunds, 
  tradeSymbol,
  trade,
  exchangeAccount,
  onClose,
}: FundsFormWrapperProps) => {

  const isBuyTrade = trade.sideFirstStep === 'BUY';
  
  const chartLastPrice = useSelector(getChartLastPrice);

  const userWalletQuoteAsset = exchangeAccount.balances?.find(
    (el: { symbol: string; }) => el.symbol === tradeSymbol.quoteAsset,
  ) || {
    free: '0', 
  };
  
  const addFundsBuyTradeBaseAssetBalance = {
    free: (Number(trade.executedBaseFirst) - Number(trade.executedBaseSecond)).toString(),
  } || {
    free: '0',
  };
  
  const addFundsSellTradeBaseAssetBalance = exchangeAccount.balances?.find(
    (el: { symbol: string; }) => el.symbol === tradeSymbol.baseAsset,
  ) || {
    free: '0',
  };
  
  const userWalletBaseAsset = isBuyTrade ? addFundsBuyTradeBaseAssetBalance : addFundsSellTradeBaseAssetBalance;
    
  //@TODO нужен не currentSymbol а selectedSymbol
  const currentSymbol = tradeSymbol;
          
  const methods = useFundsActionsForm({
    chartLastPrice,
    currentSymbol,
    userWalletQuoteAsset,
    userWalletBaseAsset,
    smartTradeUuid: trade.smartTradeUuid,
    isAddFunds,
    isBuyTrade,
    side: trade.sideFirstStep,
  });

  return (
    <FormProvider {...methods}>
      <FundsForm
        isAddFunds={isAddFunds}
        isBuyTrade={isBuyTrade}
        currentSymbol={currentSymbol}
        userWalletBaseAssetBalance={userWalletBaseAsset}
        userWalletQuoteAssetBalance={userWalletQuoteAsset}
        onClose={onClose}
      />
    </FormProvider>
  );
};

import { SxProps, Theme } from '@mui/material';

export const blockRight: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  maxWidth: {
    xs: '100%',
    md: '371px',
  },
};

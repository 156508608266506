export const formatExchangeName = (exchangeName: string): string => {
  switch (exchangeName.toLowerCase()) {
  case 'binance':
    return 'Binance';
  case 'bybit':
    return 'Bybit';
  case 'okx':
    return 'OKX';
  case 'gateio':
    return 'Gate.io';
  case 'crypto-com':
    return 'Crypto.com';
  case 'bitmart':
    return 'Bitmart';
  default:
    return exchangeName.charAt(0).toUpperCase() + exchangeName.slice(1);
  }
};

import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  padding: {
    xs: '45px 0',
    md: '74px 0',
  },
  display: 'flex',
  flexDirection: 'column',
  gap: '55px',
  '&>h1': {
    textAlign: {
      xs: 'center',
      sm: 'initial',
    },
  },
};

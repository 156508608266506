import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { ActiveDeal } from './fetch-all-deals';

export const getReturnValue = (trade: ActiveDeal, exchangeSymbols: CurrentSymbol[]) => {
  const symbol = `${trade.baseSymbol}${trade.quoteSymbol}`;
  const returnQuote = trade.returnQuote !== null && trade.returnQuote !== undefined ? trade.returnQuote.toString() : '0';
  const returnBase = trade.returnBase !== null && trade.returnBase !== undefined ? trade.returnBase.toString() : '0';
  
  if (trade.sideFirstStep === 'BUY') {
    const precision = exchangeSymbols.find((element) => element.symbol === symbol);
    const returnQuoteValue = parseFloat(returnQuote);
    return returnQuoteValue === 0 ? '-' : currencyFormatter(+returnQuote, precision?.chartPrecision ?? 4);
  }

  const returnBaseValue = parseFloat(returnBase);
  const precision = exchangeSymbols.find((element) => element.symbol === symbol);
  return returnBaseValue === 0 ? '-' : currencyFormatter(+returnBase, precision?.baseAssetPrecision ?? 4);
};

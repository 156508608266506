import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const left: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  '&>span:last-of-type': {
    fontSize: getFontSize(12, 18),
  },
  alignItems: {
    xs: 'center',
    md: 'initial',
  },
  textAlign: {
    xs: 'center',
    md: 'initial',
  },
};

import { Box } from '@mui/material';
import { Modal } from 'antd';
import { ReactNode, useState } from 'react';
import { MainButton } from 'shared/ui';
import {
  button as buttonStyles,
  inner,
  modal,
  modalChildren,
  wrapper,
} from './styles';

interface IButton {
  title: string;
  action: () => void;
}

interface ModalWrapperProps {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  buttons?: IButton[];
  isError?: boolean;
  type?: string;
}

export const ModalWrapper = ({
  isOpen,
  handleClose,
  children,
  buttons,
  isError,
  type,
}: ModalWrapperProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleButtonClick = async (action: () => void) => {
    setLoading(true);
    await action();
    setLoading(false);
  };

  const shouldShowImage = type !== 'addFunds' && type !== 'reduceFunds';
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {shouldShowImage && (
          <img 
            src={isError ? '/images/subscriptions/robot-reject.png' : '/images/subscriptions/robot-success.png'} 
            alt='robot' 
          />
        )}

        <Box sx={inner}>
          {children}

          {buttons && (
            <Box sx={{
              display: 'flex', gap: '12px', 
            }}>
              {buttons.map((button, index) => (
                <MainButton
                  key={index}
                  type='primary'
                  styles={buttonStyles}
                  onClick={() => handleButtonClick(button.action)}
                  loading={loading}
                >
                  {button.title}
                </MainButton>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

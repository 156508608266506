import { Box } from '@mui/material';
import { Copy } from 'widgets';
import { Text } from 'shared/ui';
import { copyContent, copyLabelText, copyLabelWrapper } from 'shared/ui/modals/add-new-bot/styles';
import { strategyEntry, strategyExit } from '../../../consts';
import { ITab } from '../../../interfaces';

export const Strategy = (props: ITab) => {
  return (
    <Box sx={copyContent}>
      <Copy
        label={(
          <Box sx={copyLabelWrapper}>
            <Text
              type='secondary'
              styles={copyLabelText}
            >
              Add the parameter to your strategy entry functions
            </Text>

            <Text
              type='secondary'
              styles={{
                ...copyLabelText,
                fontSize: 10,
              }}
            >
              Replace "entry1" with "entry2", "entry3", etc. in the parameter and put it to additional entries
            </Text>
          </Box>
        )}
        value={strategyEntry(props.sourceUuid, props.secretToken)}
      />

      <Copy
        label='Add the parameter to your strategy exit or close functions'
        value={strategyExit(props.sourceUuid, props.secretToken)}
      />

      <Copy
        label='Create alert with the message to trigger strategy actions'
        value='{{strategy.order.alert_message}}'
      />

      <Text>
        Note: replace str.tostring with tostring for PineScript v4 strategies
      </Text>
    </Box>
  );
};

import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface FundsActionsFormProps {
  chartLastPrice: number;
  currentSymbol: any;
  userWalletQuoteAsset: WalletData;
  userWalletBaseAsset: Partial<WalletData>;
  isBuyTrade: boolean;
  isAddFunds: boolean;
  smartTradeUuid: string;
  side: string;
}

export const useFundsActionsForm = ({
  chartLastPrice, 
  currentSymbol, 
  userWalletQuoteAsset, 
  userWalletBaseAsset, 
  smartTradeUuid, 
  side,
  isBuyTrade,
}: FundsActionsFormProps) => {
      
  const initialAddFundsUnitsBuyTrade = (parseFloat(userWalletQuoteAsset.free) * 0.1) / chartLastPrice;
  const initialAddFundsUnitsSellTrade = parseFloat(userWalletBaseAsset.free || '0') * 0.1;
   
  const initialAddFundsUnits = isBuyTrade ? initialAddFundsUnitsBuyTrade : initialAddFundsUnitsSellTrade;
 
  const formattedAddFundsUnits = formatByPrecisionAndTrim(
    initialAddFundsUnits.toString(),
    currentSymbol.baseAssetPrecision,
    Number(currentSymbol.lotMin),
    Number(currentSymbol.lotMax),
  );
  
  const recalculatedAddFundsTotal = parseFloat(formattedAddFundsUnits) * chartLastPrice;
  const formattedAddFundsTotal = formatByPrecisionAndTrim(
    recalculatedAddFundsTotal.toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.minNotional),
    Number(currentSymbol.maxNotional),
  );
  
  const initialReduceFundsBuyTradeUnits = (parseFloat(userWalletBaseAsset.free || '0') * 0.1);
  const initialReduceFundsSellTradeUnits = (parseFloat(userWalletQuoteAsset.free) * 0.1) / chartLastPrice;
  
  const initialReduceFundsUnits = isBuyTrade ? initialReduceFundsBuyTradeUnits : initialReduceFundsSellTradeUnits;
  const initialReduceFundsTotal = initialReduceFundsUnits * chartLastPrice;
  
  const formattedReduceFundsUnits = formatByPrecisionAndTrim(
    initialReduceFundsUnits.toString(),
    currentSymbol.baseAssetPrecision,
    Number(currentSymbol.lotMin),
    Number(currentSymbol.lotMax),
  );
  
  const formattedReduceFundsTotal = formatByPrecisionAndTrim(
    initialReduceFundsTotal.toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.minNotional),
    Number(currentSymbol.maxNotional),
  );
  
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      orderPrice: formatByPrecisionAndTrim(
        chartLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        currentSymbol.minNotional,
        currentSymbol.maxNotional,
      ),
      onAddFundsUnits: formattedAddFundsUnits,
      onAddFundsTotal: formattedAddFundsTotal,
      onAddFundsSlider: 10,
      onReduceFundsUnits: formattedReduceFundsUnits,
      onReduceFundsTotal: formattedReduceFundsTotal,
      onReduceFundsSlider: 10,
      onAddFundsTriggerPrice: formatByPrecisionAndTrim(
        chartLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        currentSymbol.minNotional,
        currentSymbol.maxNotional,
      ),
      triggerPriceType: 'last',
      orderType: 'limit',
      conditionalOrderType: 'limit',
      smartTradeUuid,
      side,
    },
  });

  const {
    trigger, 
  } = methods;
  
  useEffect(() => {
    trigger();
  }, [trigger, smartTradeUuid]);
  
  return methods;
};

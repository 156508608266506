
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { calculatePercentageWithCeil, calculateTotal, calculateUnits, formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const useAddFundsMethods = ({
  currentSymbol,
  userWalletQuoteAssetBalance,
  userWalletBaseAssetBalance,
}: {
  currentSymbol: any;
  userWalletQuoteAssetBalance: WalletData;
  userWalletBaseAssetBalance: WalletData;
}) => {
    
  const {
    watch, setValue,
  } = useFormContext();
    
  const lastChangedField = watch('lastChangedField');
  const orderType = watch('orderType');
  const conditionalOrderType = watch('conditionalOrderType');
  const triggerPrice = watch('triggerPrice');
  const orderPrice = watch('orderPrice');
  const total = watch('onAddFundsTotal');
  const units = watch('onAddFundsUnits');
  
  const handleAddFundsBuyTradeTriggerPriceChange = useCallback((value: string) => {
    if (conditionalOrderType === 'limit') {
      return;
    }
    
    const triggerPrice = parseFloat(value);
    if (!isNaN(triggerPrice) && orderType === 'conditional' && conditionalOrderType === 'market') {
      
      if (lastChangedField === null || lastChangedField === 'onAddFundsTotal') {
        const newUnits = calculateUnits(parseFloat(total), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('onAddFundsUnits', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(units), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('onAddFundsTotal', formattedTotal);
        
        const percentageValue = (parseFloat(newTotal) / parseFloat(userWalletQuoteAssetBalance.free)) * 100;
        setValue('onAddFundsSlider', roundPercentage(percentageValue));
      }
    }
  }, [
    total,
    units,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    conditionalOrderType, 
    orderType, 
    lastChangedField, 
    setValue, 
    userWalletQuoteAssetBalance.free,
  ]);
  
  const handleAddFundsBuyTradeOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);

    if (!isNaN(price)) {
      if (!lastChangedField || lastChangedField === 'onAddFundsTotal') {
        const newUnits = calculateUnits(parseFloat(total), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('onAddFundsUnits', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(units), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('onAddFundsTotal', formattedTotal);
        const percentageValue = (parseFloat(newTotal) / parseFloat(userWalletQuoteAssetBalance.free)) * 100;
        setValue('onAddFundsSlider', roundPercentage(percentageValue));
      }
    }
    
  }, [
    total,
    units,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    lastChangedField, 
    setValue, 
    userWalletQuoteAssetBalance.free,
  ]);
  
  const handleAddFundsBuyTradeUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onAddFundsUnits');
    const newUnits = parseFloat(value);
    
    if (!isNaN(newUnits)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);
 
      const newTotal = calculateTotal(newUnits, priceToUse, currentSymbol.quoteAssetPrecision);
 
      let formattedTotal;
      
      if (newUnits === 0) {
        formattedTotal = '0';
      } else {
        formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      }
      
      setValue('onAddFundsTotal', formattedTotal);

      const userWalletFree = parseFloat(userWalletQuoteAssetBalance.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = (parseFloat(formattedTotal) / userWalletFree) * 100;
      }
      setValue('onAddFundsSlider', roundPercentage(percentageValue));
    }
  }, [
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    orderPrice,
    triggerPrice,
    conditionalOrderType, 
    orderType, 
    setValue, 
    userWalletQuoteAssetBalance.free,
  ]);
  
  const handleAddFundsBuyTradeTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onAddFundsTotal');
    const newTotal = parseFloat(value);
    
    if (!isNaN(newTotal)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);
      
      const newUnits = calculateUnits(newTotal, priceToUse, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('onAddFundsUnits', formattedUnits);
      
      const userWalletFree = parseFloat(userWalletQuoteAssetBalance.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newTotal, userWalletFree);
      }
      
      setValue('onAddFundsSlider', percentageValue);
    }
  }, [
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    orderPrice,
    triggerPrice,
    conditionalOrderType, 
    orderType, 
    setValue, 
    userWalletQuoteAssetBalance.free,
  ]);
  
  const handleAddFundsBuyTradeSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'onAddFundsTotal');
    const newTotal = (parseFloat(userWalletQuoteAssetBalance.free) * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('onAddFundsTotal', formattedTotal);
    
    const newUnits = calculateUnits(newTotal, parseFloat(orderPrice), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('onAddFundsUnits', formattedUnits);
  }, [
    currentSymbol.quoteAssetPrecision,
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    userWalletQuoteAssetBalance.free,
    orderPrice,
    setValue,
  ]);
    
  const handleAddFundsSellTradeTriggerPriceChange = useCallback((value: string) => {
    if (conditionalOrderType === 'limit') {
      return;
    }
    
    const triggerPrice = parseFloat(value);
    if (!isNaN(triggerPrice) && orderType === 'conditional' && conditionalOrderType === 'market') {
      
      if (lastChangedField === null || lastChangedField === 'onAddFundsUnits') {
        const newTotal = calculateTotal(parseFloat(units), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('onAddFundsTotal', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(total), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('onAddFundsUnits', formattedUnits);
            
        const percentageValue = (parseFloat(newUnits) / parseFloat(userWalletBaseAssetBalance.free || '0')) * 100;
        setValue('onAddFundsSlider', roundPercentage(percentageValue));
      }
    }
  }, [
    total,
    units,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    conditionalOrderType, 
    orderType, 
    lastChangedField,
    setValue,
    userWalletBaseAssetBalance.free,
  ]);
  
  const handleAddFundsSellTradeOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'onAddFundsUnits') {

        const newTotal = calculateTotal(parseFloat(units), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('onAddFundsTotal', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(total), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('onAddFundsUnits', formattedUnits);
        
        const userWalletFree = parseFloat(userWalletBaseAssetBalance.free);
        let percentageValue = 0;
        
        if (userWalletFree > 0) {
          percentageValue = calculatePercentageWithCeil(parseFloat(newUnits), userWalletFree);
        } 
        
        setValue('onAddFundsSlider', percentageValue);
      }
    }
  }, [
    total,
    units,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.maxNotional,
    currentSymbol.minNotional,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    lastChangedField,
    setValue,
    userWalletBaseAssetBalance.free,
  ]);
  
  const handleAddFundsSellTradeUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onAddFundsUnits');
    const newUnits = parseFloat(value);
    
    if (!isNaN(newUnits)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);

      const newTotal = calculateTotal(newUnits, priceToUse, currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('onAddFundsTotal', formattedTotal);

      const userWalletFree = parseFloat(userWalletBaseAssetBalance.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newUnits, userWalletFree);
      }
      setValue('onAddFundsSlider', percentageValue);
    }
  }, [
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    userWalletBaseAssetBalance.free,
    orderPrice,
    triggerPrice,
    conditionalOrderType, 
    orderType, 
    setValue,
  ]);
  
  const handleAddFundsSellTradeTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'total');
    const newTotal = parseFloat(value);
    
    if (!isNaN(newTotal)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);
      
      const newUnits = calculateUnits(newTotal, priceToUse, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('onAddFundsUnits', formattedUnits);
      
      const userWalletFree = parseFloat(userWalletBaseAssetBalance.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = (+newUnits / userWalletFree) * 100;
      }
      setValue('onAddFundsSlider', roundPercentage(percentageValue));
    }
  }, [
    currentSymbol.baseAssetPrecision,
    orderPrice,
    triggerPrice,
    conditionalOrderType, 
    orderType, 
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    userWalletBaseAssetBalance.free,
    setValue,
  ]);
  
  const handleAddFundsSellTradeSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'onAddFundsUnits');
    
    const newUnits = parseFloat(userWalletBaseAssetBalance.free) * value / 100;
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('onAddFundsUnits', formattedUnits);
    
    const newTotal = calculateTotal(newUnits, parseFloat(orderPrice), currentSymbol.quoteAssetPrecision);
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('onAddFundsTotal', formattedTotal);
  }, [
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    userWalletBaseAssetBalance.free,
    orderPrice,
    setValue,
  ]);
  
  return {
    handleAddFundsBuyTradeTriggerPriceChange,
    handleAddFundsBuyTradeOrderPriceChange,
    handleAddFundsBuyTradeUnitsChange,
    handleAddFundsBuyTradeTotalChange,
    handleAddFundsBuyTradeSliderChange,
    handleAddFundsSellTradeTriggerPriceChange,
    handleAddFundsSellTradeOrderPriceChange,
    handleAddFundsSellTradeUnitsChange,
    handleAddFundsSellTradeTotalChange,
    handleAddFundsSellTradeSliderChange,
  };
};

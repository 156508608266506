export const DemoIcon = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8608_7106)'>
      <path d='M16.0039 0.00195312H0.00390625V16.002H16.0039V0.00195312Z' fill='#2ECD99' stroke='white' strokeWidth='0.00532089' strokeMiterlimit='10'/>
      <circle cx='8' cy='8' r='8' fill='url(#paint0_radial_8608_7106)'/>
      <path d='M5.98399 10.336C6.96244 10.336 7.56786 9.75435 7.56786 8.91516C7.56786 8.19167 7.06808 7.76483 6.39231 7.46098L5.93475 7.25844C5.40682 7.01969 5.19564 6.76644 5.19564 6.39024C5.19564 5.9634 5.57199 5.72375 5.99431 5.72668C6.17078 5.72791 6.33062 5.75494 6.52799 5.88801H7.35999C7.13426 5.45989 6.60023 5.08801 6.01599 5.08801C5.27688 5.08801 4.55511 5.59446 4.55511 6.3758C4.55511 7.01969 4.88593 7.49718 5.64621 7.82998L6.10376 8.03258C6.63873 8.27132 6.92034 8.51732 6.92034 8.98757C6.92034 9.45781 6.55412 9.72519 5.97709 9.71101C5.5455 9.7004 5.32125 9.5509 5.11647 9.38146L4.28799 9.38247C4.5864 10.0198 5.25896 10.336 5.98399 10.336Z' fill='white'/>
      <path d='M3.616 15.264H4.44808L9.03501 9.07746L9.984 10.336H10.4H10.816L9.46841 8.57075L14.528 2.048H13.6961L9.04332 8.04713L7.68125 6.304H6.848L8.60155 8.57075L3.616 15.264Z' fill='white'/>
    </g>
    <defs>
      <radialGradient id='paint0_radial_8608_7106' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(8 8) rotate(90) scale(8)'>
        <stop offset='0.35' stopColor='#129997'/>
        <stop offset='1' stopColor='#14CB9B'/>
      </radialGradient>
      <clipPath id='clip0_8608_7106'>
        <rect width='16' height='16' rx='8' fill='white'/>
      </clipPath>
    </defs>
  </svg>
);

import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  width: '100%',
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
  alignItems: 'center',
  maxWidth: '100%',
  // justifyContent: 'center',
  // flexWrap: 'wrap',
  gap: '10px',
};

//@ts-nocheck
import { updateExchangeData } from 'entities/new-terminal/model/services/update-trading-data';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { useDispatch } from 'react-redux';
import { ModalType } from 'shared/hooks/use-modal-state';
import { CloseGold, Delete, EditIcon, Founds, Share } from 'shared/icons';
import { mapTradeToFormValues } from '../components/active-trade/helpers/map-editing-trade';
import { MenuItem } from '../components/active-trade/types/active-trade.types';

interface UseMenuItemsParams {
  trade: SmartTrade;
  tradeSymbol: CurrentSymbol;
  exchangeAccount: any;
  openModal: (params: {
    type: ModalType;
    additionalData?: Record<string, any>;
  }) => void;
  handleCancelTrade: () => Promise<void>;
  handleCloseTradeByMarket: () => Promise<void>;
  handleCloseTradeByLimit: () => Promise<void>;
  handleOpenTradeByMarket: () => Promise<void>;
  handleOpenTradeByLimit: () => Promise<void>;
}

interface MenuItemParams {
  key: string;
  label: string;
  icon: React.ReactNode;
  actionFn: () => Promise<void>;
  disabled: boolean;
}

export const useMenuItems = ({
  trade,
  tradeSymbol,
  exchangeAccount,
  openModal,
  handleCancelTrade,
  handleCloseTradeByMarket,
  handleCloseTradeByLimit,
  handleOpenTradeByMarket,
  handleOpenTradeByLimit,
}: UseMenuItemsParams): MenuItem[] => {
  const dispatch = useDispatch();
  
  const isBaseOrderCompleted = trade.orders.some(
    order => order.viewType === 'base_order' && order.status === 'completed',
  );
  
  const hasCompletedForcedOrder = trade.orders.some(
    order => order.viewType === 'force_order_step_first' && order.status === 'completed',
  );

  const createMenuItem = ({
    key,
    label,
    icon,
    actionFn,
    disabled,
    exchangeAccount,
  }: MenuItemParams): MenuItem => ({
    key,
    label,
    icon,
    disabled,
    onClick: () => {
      
      if (!disabled) {
        if (key === 'edit') {
          const formValues = mapTradeToFormValues(trade, exchangeAccount, tradeSymbol);
          dispatch(newTerminalActions.setEditingTrade(formValues));
          dispatch(newTerminalActions.scrollToPanel());
          dispatch(newTerminalActions.setSelectedExchange(exchangeAccount));
          dispatch(updateExchangeData({
            uuid: trade.exchangeAccountUuid,
            exchangeCode: exchangeAccount.exchangeCode,
            // previousMarket: trade.previousMarket,
            selectedExchange: exchangeAccount,
            tradeSymbol: {
              quote: tradeSymbol.quoteAsset,
              base: tradeSymbol.baseAsset,
            },
          }));
          return;
        }
        openModal({
          type: 'tradeActions',
          additionalData: { 
            actionType: key,
            actionFn, 
            trade,
            tradeSymbol,
            exchangeAccount,
          },
        });
      }
    },
  });

  return isBaseOrderCompleted || hasCompletedForcedOrder
    ? [
      createMenuItem({
        key: 'addFunds',
        label: 'Add funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'reduceFunds',
        label: 'Reduce funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'closeByMarket',
        label: 'Close by Market',
        icon: CloseGold,
        actionFn: handleCloseTradeByMarket,
        disabled: false,
      }),
      createMenuItem({
        key: 'closeByLimit',
        label: 'Close by Limit',
        icon: CloseGold,
        actionFn: handleCloseTradeByLimit,
        disabled: false,
      }),
      createMenuItem({
        key: 'edit',
        label: 'Edit',
        icon: EditIcon,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'share',
        label: 'Share',
        icon: Share,
        actionFn: () => Promise.resolve(),
        disabled: true,
      }),
      createMenuItem({
        key: 'cancel',
        label: 'Cancel',
        icon: Delete,
        actionFn: handleCancelTrade,
        disabled: false,
      }),
    ]
    : [
      createMenuItem({
        key: 'addFunds',
        label: 'Add funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'reduceFunds',
        label: 'Reduce funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'openByMarket',
        label: 'Open by Market',
        icon: CloseGold,
        actionFn: handleOpenTradeByMarket,
        disabled: false,
      }),
      createMenuItem({
        key: 'openByLimit',
        label: 'Open by Limit',
        icon: CloseGold,
        actionFn: handleOpenTradeByLimit,
        disabled: false,
      }),
      createMenuItem({
        key: 'edit',
        label: 'Edit',
        icon: EditIcon,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'share',
        label: 'Share',
        icon: Share,
        actionFn: () => Promise.resolve(),
        disabled: true,
      }),
      createMenuItem({
        key: 'cancel',
        label: 'Cancel',
        icon: Delete,
        actionFn: handleCancelTrade,
        disabled: false,
      }),
    ];
};

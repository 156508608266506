import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { getSingleExchangePageData } from 'entities/exchange/helpers/get-single-exchange-page-data';
import { getSingleExchangeSnapshotsByDates } from 'entities/exchange/helpers/set-single-exchange-snapshots-by-date';
import { snapshotsModificator } from 'entities/exchange/helpers/snapshots-modificator';
import { getSingleExchangeData } from 'entities/exchange/model/selectors/get-single-exchange-data/get-single-exchange-data';
import { getSnapshotsSingleExchange } from 'entities/exchange/model/selectors/get-snapshots-single-exchange/get-snapshots-single-exchange';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { Balance } from 'entities/exchange/model/types/currency-balance';
import { userActions } from 'entities/user/model/slices/user-slice';
import { Banner } from 'pages/home/components';
import { mapSnapshotsToAreaData } from 'pages/home/helpers/map-snapshots-to-area-data';
import { innerChartWrapper } from 'pages/home/styles/inner-chart-wrapper';
import { actionWrapper } from 'pages/trading-bots/my-bots/styles';
import { TradingStatisticCalculations } from 'pages/trading-bots/my-bots/types/bots-calculations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { AggregateBalance, TradingStatistic } from 'widgets';
import { EMPTY_ARRAY } from 'shared/consts/common-constants';
import { getAuthToken, getSkyrexUuid, getUserEmail } from 'shared/helpers/storage-helper';
import { Loader, RangePicker, Search, Table, Title, TooltipSingleSelect } from 'shared/ui';
import { columnsBtc, columnsUsdt } from '../consts';
import { exchangeAssetsDateFormat } from '../helpers/get-exchange-assets-date-format';
import { getTradingStatisticByDates } from '../helpers/get-trading-statistic-by-dates';
import { inner, title, titleWrapper, tooltip, wrapper } from '../styles';

const fakeData = [{
  value: 222,
  name: 'BTC',
}, {
  value: 111,
  name: 'USDT',
}, {
  value: 333,
  name: 'ETH',
}, {
  value: 14,
  name: 'TON',
}, {
  value: 100,
  name: 'BNB',
}];

const ConnectedExchangeAccountSingle = () => {
  const {
    id,
  } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const defaultStartDate = dayjs().subtract(30, 'day').startOf('day');
  const defaultEndDate = dayjs().endOf('day');
  
  const exchange = useSelector(getSingleExchangeData);
  const snapshotsArray = useSelector(getSnapshotsSingleExchange);
  const [selectedQuote, setSelectedQuote] = useState<string>('USDT');
  const [selectedPeriodOption, setSelectedPeriodOption] = useState<string>('summary');
  const [selectedCurrency, setSelectedCurrency] = useState<string>('USDT');
  const [selectedSource, setSelectedSource] = useState<string>('all');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quoteSymbols, setQuoteSymbols] = useState<string[]>([]);
  const [isPieChartLoading, setIsPieChartLoading] = useState<boolean>(false);
  const [isAreaChartLoading, setIsAreaChartLoading] = useState<boolean>(false);
  const [isTradingStatisticLoading, setIsTradingStatisticLoading] = useState<boolean>(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([defaultStartDate, defaultEndDate]);
  const [chartData, setChartData] = useState<[string, number][]>([]);
  const [tradingStatisticDates, setTradingStatisticDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([defaultStartDate, defaultEndDate]);
  const [allStatisticData, setAllStatisticData] = useState<TradingStatisticCalculations[]>([]);
  const [chosenStatisticData, setChosenStatisticData] = useState<TradingStatisticCalculations | null>(null);
  const [totalPnl, setTotalPnl] = useState<number>(0);
  const [unrealisedPnl, setUnrealisedPnl] = useState<number>(0);
  const [todayPnl, setTodayPnl] = useState<number>(0);
  const [totalTrades, setTotalTrades] = useState<number>(0);
  const [activeTrades, setActiveTrades] = useState<number>(0);
  const [fundsInTrades, setFundsInTrades] = useState<number>(0);
  
  const isBtc = selectedCurrency === 'BTC';
  
  const accountName = exchange?.accountName;
  const exchangeBalances = exchange?.balances;
  const totalBtc = exchange?.totalBtc;
  const totalUsdt = exchange?.totalUsdt;
  const btcDailyChangePercent = exchange?.btcDailyChangePercent;
  const usdtDailyChangePercent = exchange?.usdtDailyChangePercent;
  
  const handleSetCurrency = (value: string) => {
    setSelectedCurrency(value);
  };
  
  const handleSetPeriodOption = (value: string) => {
    setSelectedPeriodOption(value);
  };
  
  const handleSetNewDate = (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    setDates(date);
  };
  
  const handleSetNewStatisticDate = (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    setTradingStatisticDates(date);
  };
  
  const getNewSnapshots = async (totalBtc: number, totalUsdt: number) => {
    try {
      setIsAreaChartLoading(true);
      
      const authToken = getAuthToken();
      const skyrexUserUuid = getSkyrexUuid();
      const skyrexUserEmail = getUserEmail();
      
      if (authToken) {
        dispatch(userActions.auth(true));
        dispatch(userActions.setSkyrexUuid(skyrexUserUuid ?? ''));
        dispatch(userActions.setUserEmail(skyrexUserEmail ?? ''));
      }
      
      const snapshotsByDate = await getSingleExchangeSnapshotsByDates(skyrexUserUuid ?? '', id ?? '', dates);
      
      const snapshotsModificationData = {
        snapshots: snapshotsByDate, 
        dates, 
        totalBtc, 
        totalUsdt,
      };
      
      const modifiedSnapshots = snapshotsModificator(snapshotsModificationData);
      
      dispatch(exchangeActions.setSnapshotsSingleExchange(modifiedSnapshots));
    } finally {
      setIsAreaChartLoading(false);
    }
  };

  const handleSourceChange = (value: string) => {
    const selectedData = allStatisticData.find((element) => element.market === selectedQuote);
    
    if (!selectedData) {
      setTotalPnl(0);
      setUnrealisedPnl(0);
      setTodayPnl(0);
      setTotalTrades(0);
      setActiveTrades(0);
      setFundsInTrades(0);
      setChosenStatisticData(null);
      setSelectedSource(value);
      return;
    } 
    
    switch (value) {
    case 'all':
      setTotalPnl(parseFloat(selectedData.totalPnlAll));
      setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlAll));
      setTodayPnl(parseFloat(selectedData.todayPnlAll));
      setTotalTrades(parseInt(selectedData.totalTradesAll, 10));
      setActiveTrades(parseInt(selectedData.activeTradesAll, 10));
      setFundsInTrades(parseFloat(selectedData.fundsInTradesAll));
      break;
    case 'bots':
      setTotalPnl(parseFloat(selectedData.totalPnlBot));
      setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlBot));
      setTodayPnl(parseFloat(selectedData.todayPnlBot));
      setTotalTrades(parseInt(selectedData.totalTradesBot, 10));
      setActiveTrades(parseInt(selectedData.activeTradesBot, 10));
      setFundsInTrades(parseFloat(selectedData.fundsInTradesBot));
      break;
    case 'manual':
      setTotalPnl(parseFloat(selectedData.totalPnlManual));
      setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlManual));
      setTodayPnl(parseFloat(selectedData.todayPnlManual));
      setTotalTrades(parseInt(selectedData.totalTradesManual, 10));
      setActiveTrades(parseInt(selectedData.activeTradesManual, 10));
      setFundsInTrades(parseFloat(selectedData.fundsInTradesManual));
      break;
    default:
      setTotalPnl(0);
      setUnrealisedPnl(0);
      setTodayPnl(0);
      setTotalTrades(0);
      setActiveTrades(0);
      setFundsInTrades(0);
      break;
    }
      
    setChosenStatisticData(selectedData);      
    setSelectedSource(value);
  };
  
  const handleQuoteChange = (value: string) => {
    const selectedData = allStatisticData.find((element) => element.market === value);
  
    if (!selectedData) {
      setTotalPnl(0);
      setUnrealisedPnl(0);
      setTodayPnl(0);
      setTotalTrades(0);
      setActiveTrades(0);
      setFundsInTrades(0);
      setChosenStatisticData(null);
      setSelectedQuote(value);
      return;
    } 

    switch (selectedSource) {
    case 'all':
      setTotalPnl(parseFloat(selectedData.totalPnlAll));
      setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlAll));
      setTodayPnl(parseFloat(selectedData.todayPnlAll));
      setTotalTrades(parseInt(selectedData.totalTradesAll, 10));
      setActiveTrades(parseInt(selectedData.activeTradesAll, 10));
      setFundsInTrades(parseFloat(selectedData.fundsInTradesAll));
      break;
    case 'bots':
      setTotalPnl(parseFloat(selectedData.totalPnlBot));
      setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlBot));
      setTodayPnl(parseFloat(selectedData.todayPnlBot));
      setTotalTrades(parseInt(selectedData.totalTradesBot, 10));
      setActiveTrades(parseInt(selectedData.activeTradesBot, 10));
      setFundsInTrades(parseFloat(selectedData.fundsInTradesBot));
      break;
    case 'manual':
      setTotalPnl(parseFloat(selectedData.totalPnlManual));
      setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlManual));
      setTodayPnl(parseFloat(selectedData.todayPnlManual));
      setTotalTrades(parseInt(selectedData.totalTradesManual, 10));
      setActiveTrades(parseInt(selectedData.activeTradesManual, 10));
      setFundsInTrades(parseFloat(selectedData.fundsInTradesManual));
      break;
    default:
      setTotalPnl(0);
      setUnrealisedPnl(0);
      setTodayPnl(0);
      setTotalTrades(0);
      setActiveTrades(0);
      setFundsInTrades(0);
      break;
    }

    setChosenStatisticData(selectedData);
    setSelectedQuote(value);
  };
  
  const getSingleExchangeInitialData = async () => {
    try {
      setIsLoading(true);
      const authToken = getAuthToken();
      const skyrexUserUuid = getSkyrexUuid();
      const skyrexUserEmail = getUserEmail();

      if (authToken) {
        dispatch(userActions.auth(true));
        dispatch(userActions.setSkyrexUuid(skyrexUserUuid ?? ''));
        dispatch(userActions.setUserEmail(skyrexUserEmail ?? ''));
      }

      const exchangeAccount = await getSingleExchangePageData(skyrexUserUuid ?? '', id ?? '');

      dispatch(exchangeActions.setSingleExchangePage(exchangeAccount));
      
      const singleExchangeStatisticsData = await getTradingStatisticByDates(id ?? '', tradingStatisticDates);
          
      if (singleExchangeStatisticsData.length) {
        const allSymbols = singleExchangeStatisticsData.map((element: any) => element.market);
        const defaultData = singleExchangeStatisticsData.find((statElement : any) => statElement.market === 'USDT') ?? singleExchangeStatisticsData[0];

        setAllStatisticData(singleExchangeStatisticsData);
        setQuoteSymbols(allSymbols);
        setChosenStatisticData(defaultData);
        setTotalPnl(parseFloat(defaultData.totalPnlAll));
        setUnrealisedPnl(parseFloat(defaultData.unrealizedPnlAll));
        setTodayPnl(parseFloat(defaultData.todayPnlAll));
        setTotalTrades(parseInt(defaultData.totalTradesAll, 10));
        setActiveTrades(parseInt(defaultData.activeTradesAll, 10));
        setFundsInTrades(parseFloat(defaultData.fundsInTradesAll));
      }
   
      setInitialDataLoaded(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getStatByDates = async () => {
    setIsTradingStatisticLoading(true);
    try {
      const statistics = await getTradingStatisticByDates(id ?? '', tradingStatisticDates);
      
      const allSymbols = statistics.map((element: TradingStatisticCalculations) => element.market);
      setQuoteSymbols(allSymbols);
      
      const selectedData = statistics.find((element) => element.market === allSymbols[0]) ?? statistics[0];

      if (!selectedData) return;
      
      handleQuoteChange(allSymbols[0]);
      setAllStatisticData(statistics);
      setChosenStatisticData(selectedData);
      
      switch (selectedSource) {
      case 'all':
        setTotalPnl(parseFloat(selectedData.totalPnlAll));
        setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlAll));
        setTodayPnl(parseFloat(selectedData.todayPnlAll));
        setTotalTrades(parseInt(selectedData.totalTradesAll, 10));
        setActiveTrades(parseInt(selectedData.activeTradesAll, 10));
        setFundsInTrades(parseFloat(selectedData.fundsInTradesAll));
        break;
      case 'bots':
        setTotalPnl(parseFloat(selectedData.totalPnlBot));
        setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlBot));
        setTodayPnl(parseFloat(selectedData.todayPnlBot));
        setTotalTrades(parseInt(selectedData.totalTradesBot, 10));
        setActiveTrades(parseInt(selectedData.activeTradesBot, 10));
        setFundsInTrades(parseFloat(selectedData.fundsInTradesBot));
        break;
      case 'manual':
        setTotalPnl(parseFloat(selectedData.totalPnlManual));
        setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlManual));
        setTodayPnl(parseFloat(selectedData.todayPnlManual));
        setTotalTrades(parseInt(selectedData.totalTradesManual, 10));
        setActiveTrades(parseInt(selectedData.activeTradesManual, 10));
        setFundsInTrades(parseFloat(selectedData.fundsInTradesManual));
        break;
      default:
        setTotalPnl(0);
        setUnrealisedPnl(0);
        setTodayPnl(0);
        setTotalTrades(0);
        setActiveTrades(0);
        setFundsInTrades(0);
        break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTradingStatisticLoading(false);
    }
  };
  
  useEffect(() => {
    getStatByDates();
  }, [tradingStatisticDates]);
  
  const updatePieChart = async () => {
    try {
      setIsPieChartLoading(true);
      const authToken = getAuthToken();
      const skyrexUserUuid = getSkyrexUuid();
      const skyrexUserEmail = getUserEmail();

      if (authToken) {
        dispatch(userActions.auth(true));
        dispatch(userActions.setSkyrexUuid(skyrexUserUuid ?? ''));
        dispatch(userActions.setUserEmail(skyrexUserEmail ?? ''));
      }

      const exchangeAccount = await getSingleExchangePageData(skyrexUserUuid ?? '', id ?? '');

      setInitialDataLoaded(true);
      
      dispatch(exchangeActions.setSingleExchangePage(exchangeAccount));
    } catch (error) {
      console.log(error);
    } finally {
      setIsPieChartLoading(false);
    }
  };

  const updateChartAfterUpdatingPie = () => {

    const snapshotsModificationData = {
      snapshots: snapshotsArray,
      dates,
      totalBtc: +totalBtc,
      totalUsdt: +totalUsdt,
    };
    
    const modifiedSnapshots = snapshotsModificator(snapshotsModificationData);
    
    dispatch(exchangeActions.setSnapshotsSingleExchange(modifiedSnapshots));
  };
  
  
  useEffect(() => {
    updateChartAfterUpdatingPie();
  }, [totalBtc, totalUsdt, dates]);
  
  useEffect(() => {
    getSingleExchangeInitialData();
  }, []);

  useEffect(() => {
    if (initialDataLoaded) {
      getNewSnapshots(+totalBtc, +totalUsdt);
    }
  }, [dates, initialDataLoaded]);
  
  const getTitle = () => {
    const isMyAccountsPage = location.pathname.includes('my-accounts');
    if (isMyAccountsPage) {
      return (
        <Box sx={titleWrapper}>
          <Title
            level={4}
            styles={title}
          >
            {accountName}
          </Title>
        </Box>
      );
    }

    return null;
  };

  const handleUpdateStatistic = async () => {
    try {
      setIsTradingStatisticLoading(true);
      const statistics = await getTradingStatisticByDates(id ?? '', tradingStatisticDates);
      setAllStatisticData(statistics);
      
      const allSymbols = statistics.map((element: TradingStatisticCalculations) => element.market);
      setQuoteSymbols(allSymbols);
      
      const currentChosenSymbol = selectedQuote;
      const selectedData = allStatisticData.find((element) => element.market === currentChosenSymbol) ?? statistics[0];

      setChosenStatisticData(selectedData);
      
      switch (selectedSource) {
      case 'all':
        setTotalPnl(parseFloat(selectedData.totalPnlAll));
        setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlAll));
        setTodayPnl(parseFloat(selectedData.todayPnlAll));
        setTotalTrades(parseInt(selectedData.totalTradesAll, 10));
        setActiveTrades(parseInt(selectedData.activeTradesAll, 10));
        setFundsInTrades(parseFloat(selectedData.fundsInTradesAll));
        break;
      case 'bots':
        setTotalPnl(parseFloat(selectedData.totalPnlBot));
        setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlBot));
        setTodayPnl(parseFloat(selectedData.todayPnlBot));
        setTotalTrades(parseInt(selectedData.totalTradesBot, 10));
        setActiveTrades(parseInt(selectedData.activeTradesBot, 10));
        setFundsInTrades(parseFloat(selectedData.fundsInTradesBot));
        break;
      case 'manual':
        setTotalPnl(parseFloat(selectedData.totalPnlManual));
        setUnrealisedPnl(parseFloat(selectedData.unrealizedPnlManual));
        setTodayPnl(parseFloat(selectedData.todayPnlManual));
        setTotalTrades(parseInt(selectedData.totalTradesManual, 10));
        setActiveTrades(parseInt(selectedData.activeTradesManual, 10));
        setFundsInTrades(parseFloat(selectedData.fundsInTradesManual));
        break;
      default:
        setTotalPnl(0);
        setUnrealisedPnl(0);
        setTodayPnl(0);
        setTotalTrades(0);
        setActiveTrades(0);
        setFundsInTrades(0);
        break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTradingStatisticLoading(false);
    }
  };
  
  useEffect(() => {
    if (!chosenStatisticData) {
      return;
    }
    
    let chartData: [string, number][] = [];
      
    const getChartData = (): [string, number][] => {
      switch (selectedPeriodOption) {
      case 'day':
        switch (selectedSource) {
        case 'all':
          return chosenStatisticData.dayChartDataAll.map((element) => [exchangeAssetsDateFormat(element.date), element.return]);
        case 'bots':
          return chosenStatisticData.dayChartDataBot.map((element) => [exchangeAssetsDateFormat(element.date), element.return]);
        case 'manual':
          return chosenStatisticData.dayChartDataManual.map((element) => [exchangeAssetsDateFormat(element.date), element.return]);
        default:
          return [];
        }
      case 'pair':
        switch (selectedSource) {
        case 'all':
          return chosenStatisticData.pairChartDataAll.map((element) => [element.pair, element.return]);
        case 'bots':
          return chosenStatisticData.pairChartDataBot.map((element) => [element.pair, element.return]);
        case 'manual':
          return chosenStatisticData.pairChartDataManual.map((element) => [element.pair, element.return]);
        default:
          return [];
        }
      case 'summary':
      default:
        switch (selectedSource) {
        case 'all':
          return chosenStatisticData.sumChartDataAll.map((element) => [exchangeAssetsDateFormat(element.date), element.return]);
        case 'bots':
          return chosenStatisticData.sumChartDataBot.map((element) => [exchangeAssetsDateFormat(element.date), element.return]);
        case 'manual':
          return chosenStatisticData.sumChartDataManual.map((element) => [exchangeAssetsDateFormat(element.date), element.return]);
        default:
          return [];
        }
      }
    };

    chartData = getChartData();
    
    setChartData(chartData);
  }, [chosenStatisticData, selectedPeriodOption, tradingStatisticDates, selectedSource, isTradingStatisticLoading]);
  
  if (isLoading) {
    return (
      <Loader isContentOverflow={true} />
    ); 
  }
  
  return (
    <Box sx={wrapper}>
      {getTitle()}

      <Box sx={inner}>
        <TradingStatistic
          dates={tradingStatisticDates}
          selectedQuote={selectedQuote}
          handleUpdateStatistic={handleUpdateStatistic}
          listItems={[{
            title: 'Total P&L',
            type: 'today',
            tooltip: (
              <div style={tooltip}>
                Total lifetime profit generated from all closed deals.
                Please note: this statistic is affected by any filters you have active
              </div>
            ),
            value: totalPnl,
            currency: selectedQuote,
          }, {
            title: 'UP&L',
            type: 'unrealised',
            tooltip: (
              <div style={tooltip}>
                Current profit or loss total for any active deals your have open.
                Please note, this statistic is affected by any filters you have active
              </div>
            ),
            currency: selectedQuote,
            value: unrealisedPnl,
          }, {
            title: 'Today P&L',
            type: 'today',
            tooltip: (
              <div style={tooltip}>
                Profit taken from closed deals (resets at midnight UTC+0 each day).
                Please note: this statistic is affected by any filters you have active
              </div>
            ),
            currency: selectedQuote,
            value: todayPnl,
          }, {
            title: 'Total trades',
            type: 'trades',
            tooltip: (
              <div style={tooltip}>
                Profit taken from closed deals (resets at midnight UTC+0 each day).
                Please note: this statistic is affected by any filters you have active
              </div>
            ),
            value: totalTrades,
          }, {
            title: 'Active trades',
            type: 'active',
            tooltip: (
              <div style={tooltip}>
                Currently open deals. Please note: this statistic is affected by any filters you have active
              </div>
            ),
            value: activeTrades,
          }, {
            title: 'In trades',
            type: 'funds',
            tooltip: (
              <div style={tooltip}>
                Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals.
                Please note: this statistic is affected by any filters you have active
              </div>
            ),
            currency: selectedQuote,
            value: fundsInTrades,
          }]}
          chartAction={(
            <Box sx={actionWrapper}>
              <Box sx={innerChartWrapper}>
                <TooltipSingleSelect
                  tooltip={(
                    <div style={tooltip}>
                      Select the statistic you wish to view: manual trades, Bot deals, or a comprehensive summary
                    </div>
                  )}
                  options={[{
                    label: 'All',
                    value: 'all',
                  }, {
                    label: 'Manual',
                    value: 'manual',
                  }, {
                    label: 'Bots',
                    value: 'bots',
                  }]}
                  select={{
                    value: selectedSource,
                    placeholder: 'Source',
                    onChange: handleSourceChange,
                  }}
                />
          
                <TooltipSingleSelect
                  tooltip={(
                    <div style={tooltip}>
                      Select the market statistic you wish to view: All Markets of specific asset, like USDT
                    </div>
                  )}
                  options={quoteSymbols.map(symbol => ({
                    label: symbol, value: symbol, 
                  }))}
                  select={{
                    value: selectedQuote,
                    placeholder: 'Market',
                    onChange: handleQuoteChange,
                  }}
                  dropdown={(menu) => (
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap={0.5}
                    >
                      <Search
                        value=''
                        maxWidth={450}
                        placeholder='Search'
                        onChange={() => {}}
                      />
          
                      {menu}
                    </Box>
                  )}
                />
              </Box>
          
              <RangePicker
                value={tradingStatisticDates!}
                handleSetNewDate={handleSetNewStatisticDate}
              />
            </Box>              
          )}
          chartData={chartData}
          selectedSegment={selectedPeriodOption}
          setSelectedSegment={handleSetPeriodOption}
          loading={isTradingStatisticLoading}
        />

        <Banner />
        
        <AggregateBalance
          setCurrency={handleSetCurrency}
          selectedCurrency={selectedCurrency}
          totalBtc={+totalBtc}
          totalUsdt={+totalUsdt}
          usdtDailyChangePercent={usdtDailyChangePercent}
          btcDailyChangePercent={btcDailyChangePercent}
          dates={dates}
          isAreaChartLoading={isAreaChartLoading}
          isPieChartLoading={isPieChartLoading}
          handleSetNewDate={handleSetNewDate}
          pieData={exchangeBalances ? exchangeBalances?.map((balance: Balance) => ({
            value: isBtc ? +balance.btc.total : +balance.usdt.total,
            amountForTooltip: balance.total,
            name: balance.symbol,
            totalUsdt: balance.usdt.total,
            totalBtc: balance.btc.total,
            overallBtcValue: exchange?.totalBtc,
            overallUsdtValue: exchange?.totalUsdt,
            overallBtcShare: (+balance.btc.total / +exchange?.totalBtc) * 100,
            overallUsdtShare: (+balance.usdt.total / +exchange?.totalUsdt) * 100,
          })) : fakeData}
          areaData={snapshotsArray 
            ? mapSnapshotsToAreaData(snapshotsArray, isBtc) 
            : []
          }
          handleUpdateFinancialData={updatePieChart}
        />

        <Box>
          <Table
            title='Assets'
            columns={selectedCurrency === 'USDT' ? columnsUsdt : columnsBtc}
            items={exchangeBalances ?? EMPTY_ARRAY}
            itemsCount={10}
            action={(
              <Search
                value=''
                maxWidth={480}
                placeholder='Search by asset name'
                onChange={() => {}}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectedExchangeAccountSingle;

import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Text } from 'shared/ui';
import { Card } from '../components';
import { IAddNewBotProps } from '../interfaces';
import { inner, modal, modalContent, title, wrapper } from '../styles';

export const AddNewBot = (props: IAddNewBotProps) => {
  const {
    isOpen,
    closeModal, 
  } = props;

  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    return () => {
      closeModal();
      navigate(route);
    };
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      closeIcon={false}
      style={modal}
      styles={modalContent}
      centered={true}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
    >
      <Box sx={wrapper}>
        <Text styles={title}>
          Choose bot type
        </Text>

        <Box sx={inner}>
          <Card
            image='/images/create-bot/copy-bot.png'
            title='Copy bot'
            description='Copy top performing bots with protected profit sharing'
            onClickHandler={handleNavigate('/trading-bots/marketplace')}
          />

          <Card
            image='/images/create-bot/alert-bot.png'
            title='Alert bot'
            description='Turn webhook alerts into automated trades at exchanges'
            onClickHandler={handleNavigate('/trading-bots/create')}
          />
        </Box>
      </Box>
    </Modal>
  );
};

import { SmartTradeStatus } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { useMemo } from 'react';

export const useTradeStatuses = () => {
  return useMemo(() => ({
    cancelledStatuses: ['cancelled'] as SmartTradeStatus[],
    sliderStatuses: ['new', 'active', 'request_completed', 'requested_cancelled', 'failed', 'in_progress_update', 'completed'] as SmartTradeStatus[],
    loadingStatuses: ['requested_cancelled', 'in_progress_update'] as SmartTradeStatus[],
  }), []);
};

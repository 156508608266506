import { Box } from '@mui/material';
import { Switch as Root } from 'antd';
import { ISwitch } from '../interfaces';
import { wrapper } from '../styles';

export const Switch = (props: ISwitch) => {
  const {
    prefixText,
    suffixText,
    value,
    onClick,
    onChange,
    size,
    disabled,
    checkedChildren,
    unCheckedChildren,
  } = props;

  return (
    <Box sx={wrapper}>
      {prefixText}

      <Root
        onClick={onClick}
        value={value}
        onChange={onChange}
        size={size}
        disabled={disabled}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
      />

      {suffixText}
    </Box>
  );
};

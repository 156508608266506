import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bar, ResolutionString } from '../../charting_library/datafeed-api';
import { WebSocketHandlerParams, WebSocketHandler } from '../types/chart-view.types';
import { createBaseWebSocketHandler } from './websocket-utils';

export const createBybitHandler = (params: WebSocketHandlerParams): WebSocketHandler => {
  const {
    symbolInfo, resolution, dispatch, onTick, 
  } = params;
  let ws: WebSocket | null = null;

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    if (message.topic?.startsWith('kline')) {
      const kline = message.data?.[0];
      if (kline) {
        const bar: Bar = {
          time: kline.start,
          low: +kline.low,
          high: +kline.high,
          open: +kline.open,
          close: +kline.close,
          volume: +kline.volume,
        };
        dispatch(terminalActions.setLimitLastPrice(bar.close));
        onTick(bar);
      }
    }
  };

  const getSubscriptionMessage = () => ({
    op: 'subscribe',
    args: [`kline.${resolution as ResolutionString}.${symbolInfo.full_name}`],
  });

  return {
    connect: () => {
      ws = createBaseWebSocketHandler(
        'wss://stream.bybit.com/v5/public/spot',
        handleMessage,
        getSubscriptionMessage(),
      );
      return ws;
    },
    disconnect: () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
};

export const BinanceIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='#F3BA2F'/>
    <path d='M6.32656 7.31251L8 5.63907L9.67344 7.31407L10.6469 6.34064L8 3.6922L5.35313 6.33907L6.32656 7.31251Z' fill='white'/>
    <path d='M3.6924 7.99924L4.66576 7.02584L5.63916 7.9992L4.6658 8.9726L3.6924 7.99924Z' fill='white'/>
    <path d='M6.32656 8.68751L8 10.3609L9.67344 8.68594L10.6484 9.65938H10.6469L8 12.3078L5.35313 9.66094L5.35156 9.65938L6.32656 8.68751Z' fill='white'/>
    <path d='M10.3605 8.00089L11.3338 7.02749L12.3072 8.00085L11.3339 8.97424L10.3605 8.00089Z' fill='white'/>
    <path d='M8.9875 7.99999L8 7.01093L7.27031 7.74218L7.18594 7.82499L7.0125 7.99843L7.01094 7.99999L7.0125 8.00155L8 8.98905L8.9875 7.99999Z' fill='white'/>
  </svg>
);

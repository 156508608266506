import { Box } from '@mui/material';
import { Text } from 'shared/ui';
import { ICard } from '../interfaces';
import { button, img, text, textWrapper, wrapper } from '../styles';

export const Card = (props: ICard) => {
  const {
    image,
    title,
    description,
    onClickHandler,
  } = props;

  return (
    <div
      onClick={onClickHandler}
      style={wrapper}
    >
      <Box sx={button}>
        <Box sx={img}>
          <img src={image} alt={title} />
        </Box>

        <Box sx={textWrapper}>
          <Text
            styles={text({
              fontSize: 24,
            })}
            type='success'
          >
            {title}
          </Text>

          <Text
            styles={text({
              fontSize: 12,
            })}
          >
            {description}
          </Text>
        </Box>
      </Box>
    </div>
  );
};

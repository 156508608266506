import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  gap: {
    xs: '16px',
    md: '34px',
  },
};

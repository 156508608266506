import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const text = (styles: CSSProperties) => {
  const {
    fontSize,
    ...rest
  } = styles;

  const text: CSSProperties = {
    fontSize: getFontSize(11, (fontSize as number) || 12),
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'center',
    ...rest,
  };

  return text;
};

import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'initial',
    md: 'flex-start',
  },
  gap: {
    xs: '24px',
    md: '32px',
  },
  flex: 1,
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

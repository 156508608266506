export const items = [{
  criteria: 'Days running',
  kpi: '30',
  now: 0,
  status: 'success',
  key: 'days',
}, {
  criteria: 'Profit (ROI)',
  kpi: '1',
  now: 0,
  status: 'success',
  key: 'roi',
}, {
  criteria: 'Max drawdown',
  kpi: '-15',
  now: 0,
  status: 'success',
  key: 'maxDrawdown',
}, {
  criteria: 'Trade count',
  kpi: '10',
  now: 0,
  status: 'success',
  key: 'totalTrades',
}, {
  criteria: 'Account plan',
  kpi: 'Advanced',
  now: '',
  status: 'alert',
  key: 'planName',
}];

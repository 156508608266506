export const Wallet = (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <g clipPath="url(#clip0_7112_7632)">
      <path d="M19.8198 15.3577C19.6005 15.3577 19.3861 15.4228 19.2038 15.5446C19.0214 15.6664 18.8793 15.8396 18.7953 16.0422C18.7114 16.2448 18.6895 16.4678 18.7322 16.6829C18.775 16.898 18.8806 17.0956 19.0357 17.2507C19.1908 17.4057 19.3884 17.5113 19.6035 17.5541C19.8186 17.5969 20.0415 17.575 20.2441 17.491C20.4468 17.4071 20.6199 17.265 20.7418 17.0826C20.8636 16.9003 20.9286 16.6859 20.9286 16.4666C20.9286 16.1725 20.8118 15.8905 20.6039 15.6825C20.3959 15.4746 20.1139 15.3577 19.8198 15.3577Z" fill="#2ECD99" />
      <path d="M22.36 6.93316V0H4.24658C3.12071 0.00129094 2.04133 0.449112 1.24522 1.24522C0.449112 2.04133 0.00129094 3.12071 0 4.24658L0 22.879C0.000913851 23.7064 0.33001 24.4997 0.915089 25.0848C1.50017 25.6699 2.29345 25.9991 3.12089 26H22.879C23.7064 25.9991 24.4998 25.67 25.0849 25.0849C25.67 24.4998 25.9991 23.7064 26 22.879V6.93316H22.36ZM4.24658 1.56H20.8V6.93316H4.24658C3.53405 6.93316 2.85071 6.65011 2.34688 6.14628C1.84305 5.64245 1.56 4.95911 1.56 4.24658C1.56 3.53405 1.84305 2.85071 2.34688 2.34688C2.85071 1.84305 3.53405 1.56 4.24658 1.56ZM24.44 22.879C24.4395 23.2928 24.2749 23.6896 23.9823 23.9823C23.6896 24.2749 23.2928 24.4395 22.879 24.44H3.12089C2.70705 24.4395 2.3103 24.2749 2.01768 23.9822C1.72506 23.6896 1.56046 23.2928 1.56 22.879V7.532C2.31702 8.15416 3.26671 8.49392 4.24658 8.49316H24.44V22.879Z" fill="#2ECD99" />
    </g>
    <defs>
      <clipPath id="clip0_7112_7632">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

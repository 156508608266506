import { Box } from '@mui/material';
import { Text, Title, Tooltip } from 'shared/ui';
import { formatByPrecisionAndTrim } from '../../helpers/helpers';
//@TODO перенести импорты в рамках оптимизации
import { InfoIcon, Wallet } from '../buy/icons';
import { price, priceWrapper, title, tooltipText } from '../buy/styles';

interface WalletInfoProps {
  isSkipBaseOrder: boolean;
  currentSymbol: {
    quoteAsset: string;
    baseAsset: string;
    chartPrecision: number;
    baseAssetPrecision: number;
    minNotional: string;
    maxNotional: string;
    lotMin: string;
    lotMax: string;
  };
  userWalletQuoteAsset: {
    free: string;
  };
  userWalletBaseAsset: {
    free: string;
  };
}

export const WalletInfo = ({
  isSkipBaseOrder,
  currentSymbol,
  //@TODO добавить *balance в нейминг
  userWalletQuoteAsset,
  userWalletBaseAsset,
}: WalletInfoProps) => (
  <>
    <Title styles={title}>
      {isSkipBaseOrder ? `Bought ${currentSymbol?.quoteAsset}` : `Buy ${currentSymbol?.quoteAsset}`}
    </Title>

    <Box sx={priceWrapper}>
      <Box width={12} height={12}>
        {Wallet}
      </Box>

      <Text type='success' styles={price}>
        {!isSkipBaseOrder
          ? `${formatByPrecisionAndTrim(
            userWalletQuoteAsset?.free?.toString(),
            currentSymbol?.chartPrecision,
            Number(currentSymbol.minNotional),
            Number(currentSymbol.maxNotional),
          )} ${currentSymbol?.quoteAsset}`
          : `${formatByPrecisionAndTrim(
            userWalletBaseAsset?.free?.toString(),
            currentSymbol?.baseAssetPrecision,
            Number(currentSymbol.lotMin),
            Number(currentSymbol.lotMax),
          )} ${currentSymbol?.baseAsset}`}
      </Text>

      <Tooltip
        title={(
          <Box maxWidth={188}>
            <Title level={5} styles={tooltipText}>
              {currentSymbol?.quoteAsset} amount currently <br /> available on Exchange account
            </Title>
          </Box>
        )}
      >
        <Box width={12} height={12}>
          {InfoIcon}
        </Box>
      </Tooltip>
    </Box>
  </>
);

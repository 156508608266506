import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const buttonLink: CSSProperties = {
  fontSize: getFontSize(12, 16),
  fontWeight: 500,
  cursor: 'pointer',
  textDecoration: 'underline',
  color: '#2ECD99',
};

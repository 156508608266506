import { Box } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { ICard } from '../interfaces';
import {
  list,
  listItem,
  listItemTitle,
  listItemValue,
  title as titleStyles,
  titleWrapper,
  wrapper,
} from '../styles';

export const Card = (props: ICard) => {
  const {
    title,
    items,
    action,
  } = props;

  const getTitle = () => {
    if (!action) {
      return (
        <Title
          level={4}
          styles={titleStyles}
        >
          {title}
        </Title>
      );
    }

    return (
      <Box sx={titleWrapper}>
        <Title
          level={4}
          styles={titleStyles}
        >
          {title}
        </Title>

        {action}
      </Box>
    );
  };

  return (
    <Box sx={wrapper}>
      {getTitle()}

      <Box sx={list}>
        {items.map((item: ICard['items'][0]) => (
          <Box
            key={item.title}
            sx={listItem}
          >
            <Text
              type='secondary'
              styles={listItemTitle}
            >
              {item.title}
            </Text>

            <Text
              type='secondary'
              styles={listItemValue}
            >
              {item.value}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const initialData = {
  main: {
    name: '',
    exchangeAccount: '',
    market: '',
    tradingAmount: 0,
    maxActivePositions: 0,
    baseOrderType: '',
    conditionalPriceType: '',
  },
  additionalEntryOrders: {
    isOpened: false,
    maxOrders: 1,
    priceChange: -3,
    orderType: '',
    alertOrderType: '',
    orderSizeScale: 1,
    orderPriceChangeScale: 1,
    conditionalPriceType: '',
    type: 'preset',
  },
  closeOrders: {
    type: 'preset',
    orderType: '',
    takeProfit: {
      isOpened: true,
      orders: 1,
      priceChange: 5,
      orderType: '',
      orderSizeScale: 1,
      orderPriceChangeScale: 1,
      conditionalPriceType: '',
      type: 'preset',
    },
    stopLoss: {
      isOpened: true,
      priceChange: -5,
      orderType: '',
      conditionalPriceType: '',
      type: 'preset',
    },
  },
  sharing: {
    isOpened: false,
    minAmount: 0,
    profit: 20,
    type: 'free',
  },
};

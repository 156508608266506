import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
  flex: 1,
};

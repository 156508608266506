import { SxProps, Theme } from '@mui/material';

export const headerItem: SxProps<Theme> = {
  flex: 1,
  fontSize: 14,
  fontWeight: 500,
  '&:not(:last-of-type)': {
    maxWidth: 64,
  },
};

import { SxProps, Theme } from '@mui/material';

export const titleInner: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '&>div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

import { SxProps, Theme } from '@mui/material';

export const contentButtons: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: {
    xs: '16px',
    md: '48px',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

import { IUserMenuLink } from '../interfaces';

export const userMenuLinks: IUserMenuLink[] = [{
  link: 'https://www.trustpilot.com/review/skyrexio.com',
  label: 'Rate us',
}, {
  link: 'https://t.me/skyrexio_chat',
  label: 'Chat',
}, {
  link: 'https://kamvvjb40tw.typeform.com/to/HvdNnr8Q',
  label: 'Suggest feature',
}, {
  link: 'https://docs.skyrexio.com/introduction',
  label: 'Knowledge base',
}];

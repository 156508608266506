import { Box } from '@mui/material';
import { Link, MainButton, Text } from 'shared/ui';
import { button as buttonStyles } from '../../../styles';
import { IActionBlockProps } from '../interfaces';
import { createAccount as createAccountStyles } from '../styles';

export const ActionBlock = (props: IActionBlockProps) => {
  const {
    button,
    createAccount,
  } = props;

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={2.5}
    >
      <MainButton
        type='primary'
        onClick={button.action}
        styles={buttonStyles}
        disabled={button.disabled}
      >
        {button.label}
      </MainButton>

      {createAccount && (
        <Box sx={createAccountStyles}>
          <Text>Don’t have an account?</Text>
          
          <Link
            type='success'
            href={createAccount.link}
            target='_blank'
          >
            {createAccount.label}
          </Link>
        </Box>
      )}
    </Box>
  );
};

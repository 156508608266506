import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const title: CSSProperties = {
  fontSize: getFontSize(22, 24),
  fontWeight: 400,
  textTransform: 'capitalize',
  display: 'block',
  width: '100%',
  maxWidth: 284,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

import { typeMapping } from 'shared/helpers/order-type-mappings';

export function prepareDataForCreateTrade(data: any): any {
  const processFields = (obj: any) => {
    const numericFields = ['unitsBase', 'limitPrice', 'triggerPrice', 'boughtPrice'];
    const result: any = {};
      
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'total' || key === 'orderPricePercent' || key === 'triggerPricePercent') {
        continue;
      }
        
      if (numericFields.includes(key) && typeof value === 'string') {
        result[key] = parseFloat(value);
      } else if (key === 'typeTriggerPrice' && typeof value === 'string') {
        result[key] = value.toUpperCase();
      } else if (key === 'priceRecalculation' && typeof value === 'string') {
        result[key] = value.toLowerCase() === 'average' ? 'FROM_AVERAGE' : value.toUpperCase();
      } else if (key === 'type' && typeof value === 'string') {
        result[key] = typeMapping[value] || value;
      } else if (Array.isArray(value)) {
        result[key] = value.map(item => processFields(item));
      } else if (typeof value === 'object' && value !== null) {
        result[key] = processFields(value);
      } else {
        result[key] = value;
      }
    }
  
    return result;
  };
  
  return processFields(data);
}

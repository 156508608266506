export const collectFundsFormData = (isAddFunds: boolean, formValues: any) => {
  if (!isAddFunds) {
    return {
      smartTradeUuids: [formValues.smartTradeUuid],
      unitsBase: parseFloat(formValues.onReduceFundsUnits),
    };
  }
    
  const {
    smartTradeUuid,
    side,
    orderType,
    conditionalOrderType,
    orderPrice,
    onAddFundsUnits,
    onAddFundsTriggerPrice,
    triggerPriceType,
  } = formValues;

  const additionalBaseOrder: any = {
    side: side,
    type: orderType,
  };
  
  switch (orderType) {
  case 'limit':
    additionalBaseOrder.type = orderType.toUpperCase();
    additionalBaseOrder.limitPrice = parseFloat(orderPrice);
    additionalBaseOrder.unitsBase = parseFloat(onAddFundsUnits);
    break;
  case 'market':
    additionalBaseOrder.type = orderType.toUpperCase();
    additionalBaseOrder.unitsBase = parseFloat(onAddFundsUnits);
    break;
  case 'conditional':
    if (conditionalOrderType === 'limit') {
      additionalBaseOrder.type = 'COND.LIMIT';
      additionalBaseOrder.limitPrice = parseFloat(orderPrice);
      additionalBaseOrder.triggerPrice = parseFloat(onAddFundsTriggerPrice);
      additionalBaseOrder.typeTriggerPrice = triggerPriceType.toUpperCase();
      additionalBaseOrder.unitsBase = parseFloat(onAddFundsUnits);
    } else if (conditionalOrderType === 'market') {
      additionalBaseOrder.type = 'COND.MARKET';
      additionalBaseOrder.triggerPrice = parseFloat(onAddFundsTriggerPrice);
      additionalBaseOrder.typeTriggerPrice = triggerPriceType.toUpperCase();
      additionalBaseOrder.unitsBase = parseFloat(onAddFundsUnits);
    }
    break;
  }
  
  const result = {
    smartTradeUuid,
    additionalBaseOrders: [additionalBaseOrder],
  };
  
  return result;
};

export const BitMartLogo = (
  <svg xmlns='http://www.w3.org/2000/svg' width='123' height='36' viewBox='0 0 123 36' fill='none'>
    <path d='M22.8527 7.18392C21.099 5.7367 18.6984 5.02161 15.6337 5.02161H8.00599V6.09425H15.4975C18.1024 6.09425 20.452 6.62205 21.8993 7.88198C23.3465 9.14191 24.4191 10.9807 24.4191 13.2622C24.4191 15.4756 23.4657 17.0931 21.9844 18.336C20.5031 19.5789 17.813 20.1237 15.1399 20.1237H6.54175V21.1964H15.208C18.4259 21.1964 20.9458 20.4983 22.7676 19.1022C24.5723 17.706 25.4918 15.731 25.4918 13.1941C25.4918 10.6062 24.6064 8.6141 22.8527 7.18392Z' fill='#1C1E1D'/>
    <path d='M22.2047 8.71631C21.115 7.88203 19.8721 7.21802 16.3307 7.21802H10.4397V8.25661C10.4397 8.25661 13.0787 8.22256 16.1945 8.25661C19.2081 8.29066 20.3148 8.63118 21.3364 9.38033C22.6304 10.3338 23.4476 11.5256 23.4476 13.1771C23.4476 14.7776 22.6304 15.9013 21.3193 16.8718C19.9913 17.8593 17.9482 17.8593 17.1139 17.8934C16.8074 17.8934 12.7382 17.8934 12.7382 17.8934V18.9319C12.7382 18.9319 14.7473 18.966 16.0072 18.9319C18.8676 18.8638 20.5702 18.6936 22.1536 17.5188C23.6349 16.4121 24.4181 14.9649 24.4181 13.1261C24.4181 10.7084 23.1922 9.49951 22.2047 8.71631Z' fill='#1C1E1D'/>
    <path d='M21.6773 10.095C20.86 9.58417 20.1449 9.24365 16.7568 9.24365H12.5173V9.92469H16.6376C19.6342 9.92469 20.7579 10.4525 21.1154 10.6738C21.7965 11.0825 22.6818 11.7635 22.6818 13.1256C22.6818 13.9939 22.171 15.1006 21.4049 15.5263C20.7068 15.9179 19.4298 16.2754 17.1994 16.2754H3.37427V16.9565H17.2335C19.9406 16.9565 20.86 16.65 21.8135 16.1222C22.7669 15.5944 23.4139 14.5047 23.4139 13.1256C23.4139 11.5592 22.5456 10.6398 21.6773 10.095Z' fill='#1C1E1D'/>
    <path d='M24.3669 17.6379C22.6132 16.1907 20.2125 15.4756 17.1478 15.4756H12.04V16.5482H16.9946C19.5996 16.5482 21.9492 17.0931 23.3964 18.336C24.8436 19.5789 25.9333 21.4347 25.9333 23.7162C25.9333 25.9296 24.9968 27.5471 23.4985 28.79C22.0002 30.0329 19.3272 30.5777 16.6541 30.5777H8.05591V31.6503H16.7222C19.9401 31.6503 22.46 30.9523 24.2817 29.5561C26.1035 28.16 27.0059 26.185 27.0059 23.6481C26.9889 21.0772 26.1206 19.0851 24.3669 17.6379Z' fill='#1C1E1D'/>
    <path d='M23.7197 19.1703C22.6301 18.353 21.3872 17.672 17.8458 17.672H9.84351V18.7106C9.84351 18.7106 14.5938 18.6765 17.7095 18.7106C20.7232 18.7446 21.8298 19.0852 22.8514 19.8343C24.1454 20.7878 24.9626 21.9796 24.9626 23.6311C24.9626 25.2316 24.1454 26.3553 22.8344 27.3258C21.5064 28.3133 19.4632 28.3133 18.629 28.3473C18.3225 28.3473 14.2533 28.3473 14.2533 28.3473V29.3859C14.2533 29.3859 16.2794 29.42 17.5223 29.3859C20.3826 29.3178 22.0852 29.1476 23.6687 27.9728C25.1499 26.8661 25.9331 25.4189 25.9331 23.58C25.9331 21.1794 24.7243 19.9535 23.7197 19.1703Z' fill='#1C1E1D'/>
    <path d='M11.5464 22.7117H19.0549V22.1328H11.5464V22.7117Z' fill='#1C1E1D'/>
    <path d='M14.645 24.7378H19.0037V24.244H14.645V24.7378Z' fill='#1C1E1D'/>
    <path d='M8.05591 22.7117H10.2693V22.1328H8.05591V22.7117Z' fill='#1C1E1D'/>
    <path d='M6.65991 24.7718H13.4193V24.1929H6.65991V24.7718Z' fill='#1C1E1D'/>
    <path d='M8.32837 9.87395H10.9674V9.39722H8.32837V9.87395Z' fill='#1C1E1D'/>
    <path d='M6.50659 13.7559H12.5168V13.2111H6.50659V13.7559Z' fill='#1C1E1D'/>
    <path d='M6.65991 29.3178H13.0106V28.6367H6.65991V29.3178Z' fill='#1C1E1D'/>
    <path d='M5.09351 31.5822H6.67693V30.5776H5.09351V31.5822Z' fill='#1C1E1D'/>
    <path d='M9.04346 12.0534H19.0207V11.5085H9.04346V12.0534Z' fill='#1C1E1D'/>
    <path d='M14.0322 13.7559H18.8336V13.2111H14.0322V13.7559Z' fill='#1C1E1D'/>
    <path d='M23.1922 20.6515C22.3749 20.1578 21.6598 19.8173 18.2716 19.8173H12.8914V20.4813H18.1695C21.1661 20.4813 22.3068 20.9921 22.6473 21.1964C23.3114 21.588 24.2137 22.235 24.2137 23.563C24.2137 24.4143 23.703 25.4699 22.9368 25.8785C22.2387 26.2531 20.9618 26.5936 18.7313 26.5936H4.88916V27.2576H18.7484C21.4555 27.2576 22.3749 26.9682 23.3284 26.4574C24.2818 25.9466 24.9118 24.9081 24.9118 23.563C24.9288 22.0817 24.0605 21.1964 23.1922 20.6515Z' fill='#1C1E1D'/>
    <path d='M37.3919 19.1702V25.2485H40.712C42.1422 25.2485 43.2659 24.9761 44.0491 24.4312C44.8153 23.9375 45.258 23.0862 45.241 22.1668C45.241 20.1747 43.5554 19.1702 40.2012 19.1702H37.3919ZM37.3919 11.9341V17.3824H39.8948C41.2398 17.3824 42.2784 17.127 43.0446 16.5992C43.7937 16.1225 44.2364 15.2712 44.2024 14.3859C44.2024 12.7514 42.8743 11.9171 40.2012 11.9171L37.3919 11.9341ZM34.9402 27.0362V10.1464H40.8823C42.687 10.1464 44.1172 10.5039 45.1728 11.219C46.2285 11.9341 46.7563 12.8705 46.7563 14.0113C46.7733 14.9307 46.4328 15.8331 45.8028 16.5141C45.1558 17.2292 44.2875 17.723 43.1638 18.0294V18.0805C44.5769 18.2167 45.7007 18.6424 46.5349 19.3745C47.3692 20.0726 47.8289 21.1111 47.7949 22.2008C47.7949 23.648 47.1479 24.8058 45.8709 25.7082C44.594 26.6106 42.9765 27.0532 41.0185 27.0532H34.9402V27.0362Z' fill='#1C1E1D'/>
    <path d='M50.5188 27.0362H52.9024V14.9817H50.5188V27.0362ZM51.7276 11.9C51.336 11.917 50.9444 11.7808 50.6379 11.5425C50.3485 11.3381 50.1782 10.9976 50.1953 10.6571C50.1953 10.2996 50.3655 9.95904 50.6379 9.75473C50.9444 9.51636 51.336 9.38015 51.7276 9.39718C52.1362 9.38015 52.5278 9.51636 52.8343 9.75473C53.3281 10.1123 53.4302 10.7933 53.0897 11.2871C53.0216 11.3892 52.9365 11.4744 52.8343 11.5425C52.5278 11.7979 52.1362 11.917 51.7276 11.9Z' fill='#1C1E1D'/>
    <path d='M63.4418 26.9171C62.8799 27.1724 62.1308 27.2916 61.2114 27.2916C58.6064 27.2916 57.2954 26.1168 57.2954 23.7502V16.6163H54.7415V14.9648H57.2954V12.0192L59.679 11.3893V14.9648H63.4247V16.6163H59.679V23.4097C59.679 24.2099 59.8493 24.7888 60.1898 25.1463C60.5303 25.5039 61.0922 25.6571 61.8754 25.6571C62.4202 25.6741 62.965 25.5379 63.4247 25.2485L63.4418 26.9171Z' fill='#1C1E1D'/>
    <path d='M85.1159 27.0362H82.8855V16.6163C82.9025 15.6118 82.9536 14.5902 83.0728 13.5857H83.0217C82.8855 14.0964 82.6812 14.6072 82.4428 15.084L75.8878 27.0192H74.7811L68.2431 15.1691C67.9877 14.6583 67.8004 14.1305 67.6642 13.5686H67.6131C67.6983 14.5732 67.7323 15.5947 67.7153 16.6163V27.0192H65.553V11.4915H68.5325L74.4236 22.32C74.7641 22.9159 75.0705 23.5459 75.3089 24.1929H75.394C75.7856 23.3416 76.0921 22.7116 76.3134 22.286L82.3236 11.4915H85.1329V27.0362' fill='#1C1E1D'/>
    <path d='M97.2385 20.9239L93.646 21.3325C92.5393 21.4517 91.705 21.6731 91.1432 21.9966C90.5813 22.3201 90.2919 22.8819 90.2919 23.6821C90.2919 24.261 90.5813 24.8059 91.0751 25.1294C91.5858 25.5039 92.2839 25.6912 93.1522 25.6912C94.3441 25.6912 95.3146 25.3507 96.0807 24.6867C96.8299 24.0567 97.2555 23.1203 97.2385 22.1328V20.9239ZM99.6221 27.0363H97.2385V25.1464H97.1874C96.1488 26.5936 94.6165 27.3087 92.6074 27.3087C91.1261 27.3087 89.9684 26.9852 89.1171 26.3552C88.2998 25.7763 87.8401 24.8229 87.8571 23.8184C87.8571 21.5709 89.4916 20.2599 92.7777 19.8853L97.2385 19.3745C97.2385 17.3314 96.2169 16.3099 94.1738 16.3099C92.4372 16.2928 90.7516 16.8036 89.3214 17.7911V15.8161C90.7856 15.067 92.4712 14.6924 94.3781 14.6924C97.8855 14.6924 99.6221 16.1907 99.6221 19.1873V27.0363Z' fill='#1C1E1D'/>
    <path d='M110.689 16.9228C110.264 16.6674 109.668 16.5312 108.884 16.5312C107.897 16.5142 106.96 16.9398 106.331 17.706C105.649 18.4892 105.309 19.5448 105.309 20.8899V27.0363H102.925V14.9818H105.309V17.4676H105.36C105.666 16.6674 106.211 15.9693 106.926 15.4756C107.607 14.9988 108.425 14.7605 109.276 14.7605C109.753 14.7435 110.247 14.7945 110.706 14.9307L110.689 16.9228Z' fill='#1C1E1D'/>
    <path d='M118.998 26.9171C118.436 27.1724 117.687 27.2916 116.767 27.2916C114.162 27.2916 112.851 26.1168 112.851 23.7502V16.6163H110.297V14.9648H112.851V12.0192L115.235 11.3893V14.9648H118.981V16.6163H115.235V23.4097C115.235 24.2099 115.405 24.7888 115.746 25.1463C116.086 25.5039 116.648 25.6571 117.431 25.6571C117.976 25.6741 118.521 25.5379 118.981 25.2485L118.998 26.9171Z' fill='#1C1E1D'/>
  </svg>
);

import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  fontSize: 14,
  fontWeight: 500,
  padding: '7px 0',
  width: '100%',
  maxWidth: 180,
  alignSelf: 'center',
};

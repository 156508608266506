interface IDescription {
  basic: string;
  advanced: string;
  pro: string;
}
export const descriptions: IDescription = {
  basic :'Enjoy essential features with no subcription fee',
  advanced :'Extend usage limits and share your bots at Marketplace',
  pro :'Ultimate tools, reduced Skyrexio bot fee, private PineScript strategies',
};

import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const cancelManualTrade = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/smartTrade/cancel',
    data: {
      smartTradeUuids: [smartTradeUuid],
    },
  });

  return response.data;
};

export const closeManualTradeByMarket = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/smartTrade/closeByMarket',
    data: {
      smartTradeUuids: [smartTradeUuid],
    },
  });
  
  return response.data;
};

export const closeManualTradeByLimit = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/smartTrade/closeByLimit',
    data: {
      smartTradeUuids: [smartTradeUuid],
    },
  });
  
  return response.data;
};

export const openManualTradeByMarket = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/smartTrade/openByMarket',
    data: {
      smartTradeUuids: [smartTradeUuid],
    },
  });
  
  return response.data;
};

export const openManualTradeByLimit = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/smartTrade/openByLimit',
    data: {
      smartTradeUuids: [smartTradeUuid],
    },
  });
  
  return response.data;
};

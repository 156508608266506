import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getExchangeAccounts = async () => {
  const response = await axios.request({
    url: '/exchangeAccount/GetByFilter',
    data: {
      skyrexUserUuids: [getSkyrexUuid()!],
      table: 'exchange_accounts',
      statuses: ['active'],
    },
  });
    
  return response.data;
};

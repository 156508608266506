import { Box } from '@mui/material';
import { SingleSelect, Text } from 'shared/ui';

interface TradingPairSelectProps {
  tradingPairs: any[];
  selectedTradingPair: any;
  isLoading: boolean;
  onSelect: (value: string) => void;
}

export const TradingPairSelect = ({
  tradingPairs,
  selectedTradingPair,
  isLoading,
  onSelect,
}: TradingPairSelectProps) => {
  return (
    <SingleSelect
      showSearch={true}
      label='Trading Pair'
      options={tradingPairs}
      select={{
        value: selectedTradingPair,
        placeholder: 'Select trading pair',
        onChange: onSelect,
      }}
      optionRender={(option) => (
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text type='success'>
            {option.label}
          </Text>
          <Box
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <Text>
              {/* @ts-ignore */}
              {option?.value?.split('-')[0]}
            </Text>
            <Text type='secondary'>
              {option.label?.toString().split(' ')[0]}
            </Text>
          </Box>
        </Box>
      )}
      labelRender={(props) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap={1}
        >
          <Text type='success'>{props.label}</Text>
          <Box
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            {/* @ts-ignore */}
            <Text>{props.value?.split('-')[0]}</Text>
            <Text type='secondary'>{props.label?.toString().split(' ')[0]}</Text>
          </Box>
        </Box>
      )}
      loading={isLoading}
    />
  );
};

import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedExchanges } from 'shared/api/axios/helpers/fetch-paginated-exchanges';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getExchangesIds = async (skyrexUserUuid: string) => {
  try {
    const response = await axios.request({
      url: '/exchangeAccount/getByFilter',
      data: {
        table: 'exchange_accounts',
        skyrexUserUuids: [skyrexUserUuid],
        statuses: ['active'],
        page: 1,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching exchange IDs:', error);
    return {
      success: false, data: {
        accounts: [],
      },
    };
  }
};

export const getExchanges = async (exchangeAccountUuids: string[]) => {
  try {
    const endPoint = '/exchangeAccount/terminalGetByFilter';
    
    const requestData = {
      exchangeAccountUuids,
      page: 1,
    };
    
    return fetchPaginatedExchanges(axios, endPoint, requestData);
  } catch (error) {
    console.error('Error fetching exchanges:', error);
    return {
      success: false, data: {
        accounts: [],
      },
    };
  }
};

import { SxProps, Theme } from '@mui/material';

export const row: SxProps<Theme> = {
  display: 'table-row',
  gap: 2,
  '&>span': {
    display: 'table-cell',
    whiteSpace: 'nowrap',
    minWidth: '100%',
    '&:first-of-type': {
      padding: '4px 8px 4px 0',
    },
    '&:not(:first-of-type)': {
      padding: '4px 8px',
    },
  },
};

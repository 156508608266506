export const BinanceLogo = (
  <svg width='100%' height='100%' viewBox='0 0 633.04 126.61' xmlns='http://www.w3.org/2000/svg'>
    <g fill='#f3ba2f'>
      <path d='m38.72 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z' />
      <path d='m3.64 53.19h20.23v20.23h-20.23z' transform='matrix(.70710678 -.70710678 .70710678 .70710678 -40.19 28.27)' />
      <path d='m38.72 73.41 24.59 24.59 24.6-24.6 14.31 14.29-.01.01-38.9 38.91-38.9-38.89-.02-.02z' />
      <path d='m101.64 53.19h20.23v20.23h-20.23z' transform='matrix(.70710678 -.70710678 .70710678 .70710678 -11.49 97.57)' />
      <path d='m77.82 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54-.02.02.02.03 14.51 14.5 14.51-14.52.01-.01z' />
      <g transform='translate(.55)'>
        <path d='m148.37 30.68h31.12q11.58 0 17.52 6a15.5 15.5 0 0 1 4.59 11.32v.19a16.67 16.67 0 0 1 -.71 5.08 15.6 15.6 0 0 1 -1.91 4 14.77 14.77 0 0 1 -2.76 3.12 17.92 17.92 0 0 1 -3.39 2.3 22.66 22.66 0 0 1 9.17 6q3.34 3.8 3.34 10.5v.19a17.44 17.44 0 0 1 -1.77 8.06 15.72 15.72 0 0 1 -5.07 5.76 24.05 24.05 0 0 1 -7.95 3.45 42.7 42.7 0 0 1 -10.29 1.15h-31.89zm28 27.14a15.39 15.39 0 0 0 7.77-1.68 5.8 5.8 0 0 0 2.86-5.42v-.19a5.93 5.93 0 0 0 -2.49-5.13q-2.49-1.77-7.19-1.77h-14.56v14.19zm3.93 27.05a14.2 14.2 0 0 0 7.7-1.77 6.08 6.08 0 0 0 2.78-5.52v-.19a6.31 6.31 0 0 0 -2.59-5.32q-2.58-2-8.34-2h-17.09v14.8z' />
        <path d='m223.88 30.68h14.77v67.14h-14.77z' />
        <path d='m261 30.68h13.62l31.48 41.32v-41.32h14.58v67.14h-12.57l-32.51-42.68v42.68h-14.6z' />
        <path d='m365.4 30.2h13.6l28.77 67.62h-15.42l-6.14-15.06h-28.39l-6.14 15.06h-15.06zm15.54 39.52-8.94-21.78-8.9 21.78z' />
        <path d='m423.74 30.68h13.62l31.46 41.32v-41.32h14.58v67.14h-12.57l-32.51-42.68v42.68h-14.58z' />
        <path d='m536.56 99a34.93 34.93 0 0 1 -13.72-2.68 33.17 33.17 0 0 1 -18.13-18.32 35.54 35.54 0 0 1 -2.59-13.53v-.19a34.84 34.84 0 0 1 9.79-24.51 33.23 33.23 0 0 1 11-7.48 35.9 35.9 0 0 1 14.19-2.73 44.49 44.49 0 0 1 8.58.77 35.66 35.66 0 0 1 7.06 2.11 30.5 30.5 0 0 1 5.85 3.26 39.52 39.52 0 0 1 5 4.22l-9.39 10.84a35.71 35.71 0 0 0 -8-5.57 20.47 20.47 0 0 0 -9.16-2 18.58 18.58 0 0 0 -14 6.14 21.09 21.09 0 0 0 -4.04 6.67 22.87 22.87 0 0 0 -1.43 8.11v.19a23.21 23.21 0 0 0 1.43 8.1 21.32 21.32 0 0 0 4 6.71 18.46 18.46 0 0 0 14 6.24 20.52 20.52 0 0 0 9.73-2.11 37.62 37.62 0 0 0 7.91-5.76l9.4 9.5a47.93 47.93 0 0 1 -5.37 5 32 32 0 0 1 -6.09 3.79 31.56 31.56 0 0 1 -7.24 2.39 43.11 43.11 0 0 1 -8.78.84z' />
        <path d='m581.47 30.68h50.53v13.14h-36v13.62h31.7v13.14h-31.65v14.1h36.45v13.14h-51z' />
      </g>
    </g>
  </svg>
);

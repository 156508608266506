import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  width: '100%',
  maxWidth: '100%',
  minWidth: '100%',
  overflowY: 'hidden',
  flex: 1,
};

import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const useSectionTogglers = () => {
  const {
    setValue, 
  } = useFormContext();
  
  const setIsSkipAdditionalEntry = useCallback((value: boolean) => {
    setValue('addEntryEnabled', value);
  }, []);
  
  const setIsSkipTakeProfit = useCallback((value: boolean) => {
    setValue('takeProfitEnabled', value);
  }, []);
  
  const setIsSkipStopLoss = useCallback((value: boolean) => {
    setValue('stopLossEnabled', value);
  }, []);
  
  return {
    setIsSkipAdditionalEntry,
    setIsSkipTakeProfit,
    setIsSkipStopLoss,
  };
};

import { BaseType } from 'antd/es/typography/Base';

interface IStatus {
  text: string;
  color: BaseType;
}

interface IStatuses {
  [key: string]: IStatus;
}
export const statuses: IStatuses = {
  request_completed: {
    text: 'Pending',
    color: 'warning',
  },
  requested_cancelled: {
    text: 'Pending',
    color: 'warning',
  },
  in_progress_update: {
    text: 'Pending',
    color: 'warning',
  },
  cancelled: {
    text: 'Cancelled',
    color: 'secondary',
  },
  new: {
    text: 'Active',
    color: 'success',
  },
  active: {
    text: 'Active',
    color: 'success',
  },
  completed: {
    text: 'Completed',
    color: 'secondary',
  },
  failed: {
    text: 'Failed',
    color: 'danger',
  },
};

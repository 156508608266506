import { addFundsThunk } from 'entities/new-terminal/model/services/add-funds';
import { reduceFundsThunk } from 'entities/new-terminal/model/services/reduce-funds';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from 'app/providers/store-provider/config/store';
import { collectFundsFormData } from '../helpers/collect-funds-form-data';

export const useFundsFormSubmission = ({
  onClose,
  isAddFunds,
}: {
  onClose: () => void;
  isAddFunds: boolean;
}) => {
  
  const dispatch = useAppDispatch();
  const {
    trigger, handleSubmit, formState: {
      isValid,
      isSubmitting,
    }, setError,
  } = useFormContext();
  
  const onSubmit = async (data: any) => {
    try {
      const collectedFormData = collectFundsFormData(isAddFunds, data);
      
      if (isAddFunds) {
        await dispatch(addFundsThunk(collectedFormData)).unwrap();
      } else {
        await dispatch(reduceFundsThunk(collectedFormData)).unwrap();
      }
    } catch (error) {
      console.log('error', error);
      if (error.response?.data?.message) {
        setError('root', {
          type: 'submit',
          message: error.response.data.message,
        });
      } else {
        setError('root', {
          type: 'submit',
          message: 'An error occurred while submitting the form',
        });
      }
    } finally {
      onClose();
    }
  };
  
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    trigger();
    event.preventDefault();
    handleSubmit(onSubmit)(event);
  };
    
  return {
    handleFormSubmit,
    isValid,
    isSubmitting,
  };
};

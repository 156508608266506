import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 'normal',
  padding: '5px 0',
  width: '100%',
  maxWidth: 160,
};

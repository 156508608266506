export const typeMapping: { [key: string]: string; } = {
  'limit': 'LIMIT',
  'market': 'MARKET',
  'cond.limit': 'CONDITIONAL_LIMIT',
  'cond.market': 'CONDITIONAL_MARKET',
  'Skip first step': 'SKIP_FIRST_STEP',
};

export const reverseTypeMapping: { [key: string]: string; } = {
  'LIMIT': 'limit',
  'MARKET': 'market',
  'CONDITIONAL_LIMIT': 'cond.limit',
  'CONDITIONAL_MARKET': 'cond.market',
  'SKIP_FIRST_STEP': 'Skip first step',
};

import { Modal } from 'antd';
import { Card } from 'pages/trading-bots/marketplace/components';
import { ISignalSource } from '../interfaces';
import { modal, modalChildren } from '../styles';

export const SignalSource = (props: ISignalSource) => {
  const {
    isOpen,
    handleClose,
    bot,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={false}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Card
        bots={[bot]}
        isSignalSource={true}
      />
    </Modal>
  );
};

import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { numberSorter, stringSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';
import { getTextType } from '../helpers/get-text-type';

export const columnsBtc: TableColumnsType = [
  {
    title: 'Token',
    dataIndex: 'symbol',
    key: 'symbol',
    sorter: (a, b) => stringSorter(a.symbol, b.symbol),
    width: 190,
    render: (value: string) => (
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Text>{value}</Text>
      </Box>
    ),
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;

      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  },
  {
    title: 'Share',
    dataIndex: 'share',
    key: 'share',
    sorter: (a, b) => numberSorter(a.share, b.share),
    width: 190,
    render: (value: string) => {
      const share = parseFloat(value);
      return (
        <Text>{`${share.toFixed(2)}%`}</Text>
      );
    },
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;

      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  },
  {
    title: 'Change 24h',
    dataIndex: 'btc',
    key: 'btcChange',
    sorter: (a, b) => numberSorter(a?.usdt?.priceChangePercent, b?.usdt?.priceChangePercent),
    width: 190,
    render: (value: any) => {
      const priceChangePercent = parseFloat(value.priceChangePercent);
      const textType = getTextType(priceChangePercent);
      return (
        <Text type={textType}>
          {priceChangePercent.toFixed(2)}
          %
        </Text>
      );
    },
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;

      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  },
  {
    title: 'Price',
    dataIndex: 'btc',
    key: 'btcPrice',
    width: 190,
    render: (value: any) => {
      const price = parseFloat(value.price);
      return (
        <Text>{currencyFormatter(price, 6)}</Text>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'total',
    key: 'usdtAmount',
    width: 190,
    render: (value: any) => {
      const amount = currencyFormatter(parseFloat(value), 4);
      return (
        <Text>{amount}</Text>
      );
    },
  },
  {
    title: 'Total',
    dataIndex: 'btc',
    key: 'btcTotal',
    sorter: (a, b) => numberSorter(a.usdt.total, b.usdt.total),
    width: 190,
    render: (value: any) => (
      <Text>{currencyFormatter(+value.total, 6)}</Text>
    ),
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;

      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  },
];

import { Box } from '@mui/material';
import { Form, Modal } from 'antd';
import { LabelInput, MainButton, Text, Title } from 'shared/ui';
import { botNameValidator, tradingBalanceValidator } from '../../copy-bot/consts';
import { formItem } from '../../copy-bot/styles';
import { IUpdateCopyBot } from '../interfaces';
import { button, description, inner, modal, modalChildren, title, topWrapper, wrapper } from '../styles';

export const UpdateCopyBot = (props: IUpdateCopyBot) => {
  const {
    minInvestment,
    tradingAmount,
    botTitle,
    setTitle,
    setTradingAmount,
    isOpen,
    handleClose,
    handleUpdate,
  } = props;

  const [form] = Form.useForm();
  
  const handleTradingAmountChange = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    if (/^\d/.test(numericValue)) {
      setTradingAmount(numericValue);
      form.setFieldValue('tradingBalance', numericValue);
    } else if (numericValue === '') {
      setTradingAmount('');
      form.setFieldValue('tradingBalance', '');
    }
  };
  
  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      handleUpdate();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      onOk={handleFormSubmit}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Form
          form={form}
          layout='vertical'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '35px',
          }}
          initialValues={{
            botName: botTitle,
            tradingBalance: tradingAmount,
          }}
        >
          <Box sx={topWrapper}>
            <Title styles={title}>
              Update copy bot
            </Title>

            <Text styles={description}>
              Trading amount change will affect only new positions
            </Text>
          </Box>

          <Box sx={inner}>
            <Form.Item
              name='botName'
              style={formItem}
              rules={botNameValidator}
            >
              <LabelInput
                label='Name'
                value={botTitle}
                onChange={(value) => setTitle(value)}
                placeholder=''
              />
            </Form.Item>
          
            <Form.Item
              style={formItem}
              name='tradingBalance'
              rules={tradingBalanceValidator(+minInvestment)}
            >
              <LabelInput
                label='Trading amount'
                value={tradingAmount}
                onChange={handleTradingAmountChange}
                placeholder=''
              />
            </Form.Item>

            <MainButton
              type='primary'
              ghost={true}
              styles={button}
              onClick={handleFormSubmit}
            >
              Update
            </MainButton>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
};

import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const header: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
  '&>span': {
    flex: 1,
    fontSize: getFontSize(10, 12),
    fontWeight: 400,
  },
};

import dayjs from 'dayjs';
import { getDemoAccount } from 'shared/helpers';
import { getExchanges, getExchangesIds } from '../../../widgets/exchanges/api/get-exchanges';
import { getSnapshots } from '../api/get-snapshots';
import { ExchangeAccount } from '../model/types/exchange-account';

export async function setExchangeData(skyrexUserUuid: string, isDemoMode?: boolean) {
  const exchangeDataResponse = await getExchangesIds(skyrexUserUuid);
  if (!exchangeDataResponse.success) {
    throw new Error(exchangeDataResponse.data.message);
  }

  const {
    accounts,
  } = exchangeDataResponse.data;

  const accountsIds = getDemoAccount(accounts, isDemoMode || false).map((account: ExchangeAccount) => account.exchangeAccountUuid);

  const allAccountsData = await getExchanges(accountsIds);

  const allAccountsSnapshots = await getSnapshots(skyrexUserUuid, accountsIds);

  if (!allAccountsData || !allAccountsData?.data?.accounts?.length) {
    return [];
  }

  const now = dayjs();
  const twentyFourHoursAgo = now.subtract(24, 'hour');

  const snapshotMapping = allAccountsSnapshots.data.accounts.reduce((acc: any, snapshot: any) => {
    const snapshotDate = dayjs(snapshot.createdDate);

    if (snapshotDate.isAfter(twentyFourHoursAgo)) {
      const existingSnapshot = acc[snapshot.exchangeAccountUuid];
      const existingSnapshotDate = existingSnapshot ? dayjs(existingSnapshot.createdDate) : null;

      if (!existingSnapshot || snapshotDate.isAfter(existingSnapshotDate)) {
        acc[snapshot.exchangeAccountUuid] = snapshot;
      }
    }
    return acc;
  }, {});

  const snapshotsArray = allAccountsSnapshots.data.accounts.map((snapshot: any) => ({
    date: snapshot.createdDate,
    btcValue: snapshot.totalBtc,
    usdtValue: snapshot.totalUsdt,
  }));

  const mergedAccounts = allAccountsData.data.accounts.map((account: ExchangeAccount) => {
    const snapshot = snapshotMapping[account.exchangeAccountUuid];

    if (snapshot) {
      const usdtDailyChangeValue = parseFloat(account.totalUsdt) - parseFloat(snapshot.totalUsdt);
      const usdtDailyChangePercent = ((usdtDailyChangeValue / parseFloat(snapshot.totalUsdt)) * 100).toFixed(2);
      const btcDailyChangeValue = parseFloat(account.totalBtc) - parseFloat(snapshot.totalBtc);
      const btcDailyChangePercent = ((btcDailyChangeValue / parseFloat(snapshot.totalBtc)) * 100).toFixed(2);

      return {
        ...account,
        usdtDailyChangePercent,
        usdtDailyChangeValue: usdtDailyChangeValue.toFixed(2),
        btcDailyChangePercent,
        btcDailyChangeValue: btcDailyChangeValue.toFixed(8),
        snapshotsArray,
      };
    }
    return account;
  });

  return mergedAccounts;
}

import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getSubscriptions = async () => {    
  const response = await axios.request({
    url: '/providerPayout/totalSubscriptions',
    data: {
      skyrexUserUuid: getSkyrexUuid()!,
    },
  });
    
  return response?.data;
};

import { Box } from '@mui/material';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { formatReturnPercent, getFormattedAndTrimmedReturnValue, getTextColorByReturn, isReturnEmpty, showActionButtons } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { formatDate, formatTime } from 'pages/manual-trading/trading-terminal/helpers/trade-date-formatter';
import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { capitalizeFirstLetter } from 'pages/trading-bots/trading-history/helpers/get-capitalized-first-letter';
import { useEffect, useState } from 'react';
import { Text } from 'shared/ui';
import { getAccountName } from '../active-trade/helpers/get-account-name';
import { dateWrapper, progressWrapper, returnWrapper, text, volumeWrapper } from '../active-trade/styles';
import { TradeActionsProps } from '../active-trade/types/active-trade.types';
import { TradeActions } from '../active-trade/ui/trade-actions';
import { TradeProgress } from '../trade-progress/trade-progress';

interface BriefTradeDataProps {
  trade: SmartTrade;
  tradeSymbol: CurrentSymbol;
  menuItems: TradeActionsProps['menuItems'];
  onUpdate: TradeActionsProps['onUpdate'];
}

export const BriefTradeData = ({
  trade,
  tradeSymbol,
  menuItems,
  onUpdate,
}: BriefTradeDataProps) => {
  const [exchangeAccountName, setExchangeAccountName] = useState<string | null>(null);
  
  const {
    baseSymbol,
    quoteSymbol,
    executedBaseFirst,
    executedQuoteFirst,
    returnPercent,
  } = trade;

  const {
    quoteAssetPrecision,
    baseAssetPrecision,
    priceMin,
    priceMax,
  } = tradeSymbol;
  
  const startDate = formatDate(trade.createdDate);
  const startTime = formatTime(trade.createdDate);
  
  const getExchangeAccountName = (exchangeAccountUuid: string) => {
    const accountName = getAccountName(exchangeAccountUuid);
    return accountName;
  };
  
  useEffect(() => {
    try {
      const fetchExchangeAccountName = async () => {
        const exchangeAccountName = await getExchangeAccountName(trade.exchangeAccountUuid);
        setExchangeAccountName(exchangeAccountName);
      };
      
      fetchExchangeAccountName();
    } catch (error) {
      console.error('Error fetching exchange account name:', error);
    }
  }, [trade.exchangeAccountUuid]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flex: 1,
        justifyContent: 'space-between',
        '&>div': {
          width: '100%',
          '&>span': {
            fontSize: '12px !important',
          },
          '&:first-of-type': {
            maxWidth: 64,
          },
          '&:nth-child(2)': {
            maxWidth: 85,
          },
          '&:nth-child(3)': {
            maxWidth: 75,
          },
          '&:nth-child(4)': {
            maxWidth: 120,
          },
          '&:nth-child(5)': {
            maxWidth: 346,
          },
          '&:nth-child(6)': {
            maxWidth: 70,
          },
          '&:nth-child(7)': {
            maxWidth: 95,
          },
          '&:last-of-type': {
            maxWidth: 60,
          },
        },
      }}
    >
      <Box display='flex'>
        <Text styles={text}>{baseSymbol}</Text>
        <Text type='success' styles={text}>{quoteSymbol}</Text>
      </Box>
      
      <Box sx={text}>
        <Text type='secondary' styles={text}>{exchangeAccountName || ''}</Text>
      </Box>
      
      <Box sx={dateWrapper}>
        <Text type='secondary' styles={text}>{startDate}</Text>
        <Text type='secondary' styles={text}>{startTime}</Text>
      </Box>

      <Box sx={volumeWrapper}>
        <Text type='secondary' styles={text}>{`${formatByPrecisionAndTrim(executedBaseFirst, baseAssetPrecision, Number(priceMin), Number(priceMax))} ${baseSymbol}`}</Text>
        <Text type='secondary' styles={text}>{`${formatByPrecisionAndTrim(executedQuoteFirst, quoteAssetPrecision, Number(priceMin), Number(priceMax))} ${quoteSymbol}`}</Text>
      </Box>

      <Box sx={progressWrapper}>
        <TradeProgress
          trade={trade}
          tradeSymbol={tradeSymbol}
        />
      </Box>
      
      <Box>
        <Text type='secondary' styles={text}>{capitalizeFirstLetter(trade.status)}</Text>
      </Box>

      <Box
        sx={returnWrapper}
        style={isReturnEmpty(trade) ? {
          visibility: 'hidden', 
        } : {}}
      >
        <Text type={getTextColorByReturn(returnPercent)} styles={text}>{getFormattedAndTrimmedReturnValue(trade, tradeSymbol)}</Text>
        <Text type={getTextColorByReturn(returnPercent)} styles={text}>{`${formatReturnPercent(returnPercent)}%`}</Text>
      </Box>

      {showActionButtons(trade) ? (
        <TradeActions
          menuItems={menuItems} 
          onUpdate={onUpdate} 
        />
      ) : (
        <div
          style={{
            width: 95, 
          }}
        />
      )}
    </Box>
  );
};

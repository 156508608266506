import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: 3,
    sm: 5,
  },
  padding: {
    xs: '20px 0',
    sm: '40px 0',
    md: '55px 0',
  },
};

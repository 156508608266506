import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '20px',
  '&>*': {
    lineHeight: 'normal',
    '&:first-of-type': {
      fontSize: 20,
    },
    '&:last-of-type': {
      fontSize: 12,
    },
  },
};

import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createDemoExchangeAccount = async () => {
  const response = await axios.request({
    url: '/exchangeAccount/create',
    data: {
      'skyrexUserUuid': getSkyrexUuid()!,
      'exchangeCode': 'demo',
      'accountName': 'My Demo',
      'apiKey': 'demo',
      'apiSecret': 'demo',
    },
  });
    
  return response.data;
};

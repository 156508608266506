import { SxProps, Theme } from '@mui/material';

export const img: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  maxWidth: 254,
  alignSelf: 'center',
  display: 'block',
  '&>img': {
    width: '100%',
    height: '100%',
    aspectRatio: '1/1',
    objectFit: 'cover',
    objectPosition: 'center',
  },
};

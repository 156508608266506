
import { Box } from '@mui/material';
import { TradingViewChart } from 'pages/trading-terminal-page/trading-chart/trading-view-chart/trading-view-chart';
import { memo } from 'react';
import { Loader } from 'shared/ui';
import { ActiveTrades as ActiveTradesComponent } from '../components';
import { ExchangeSelect } from '../components/exchange-select/ui/exchange-select';
import { MarketSelect } from '../components/market-select/ui/market-select';
import { TradingPairSelect } from '../components/trading-pair-select/ui/trading-pair-select';
import { TradingPanel } from '../components/trading-panel/trading-panel';
import { WalletTable } from '../components/wallet-table/wallet-table';
import { useTrading } from '../hooks/use-terminal-page-trading-data';
import { filters, graphicWrapper, tableWrapper, wrapper } from '../styles';

const MemoizedWalletTable = memo(WalletTable);

export const TradingTerminal = () => {
  const {
    exchanges,
    selectedExchange,
    currentSymbol,
    dailyChange,
    marketOptions,
    selectedMarket,
    tradingPairs,
    chartSymbol,
    selectedTradingPair,
    userWalletBalances,
    isLoading,
    handleExchangeSelect,
    handleMarketSelect,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    chosenExchange,
    handleTradingPairSelect,
  } = useTrading();

  const getTradingPanel = () => {
    return (
      <TradingPanel
        currentSymbol={currentSymbol}
        // @TODO поменять нейминг, 
        //использовать только userWalletQuoteAssetBalance везде
        userWalletData={userWalletQuoteAssetBalance}
        userWalletBaseAsset={userWalletBaseAssetBalance}
        exchangeAccountUuid={chosenExchange?.exchangeAccountUuid}
      />
    );
  };

  return (
    <Box sx={wrapper}>
      <Box sx={tableWrapper}>
        <Box sx={filters}>
          <ExchangeSelect
            exchanges={exchanges}
            selectedExchange={selectedExchange}
            isLoading={isLoading}
            onSelect={handleExchangeSelect}
          />
          
          <MarketSelect
            marketOptions={marketOptions}
            selectedMarket={selectedMarket}
            isLoading={isLoading}
            onSelect={handleMarketSelect}
          />
          
          <TradingPairSelect
            tradingPairs={tradingPairs}
            selectedTradingPair={selectedTradingPair}
            isLoading={isLoading}
            onSelect={handleTradingPairSelect}
          />
        </Box>
        <MemoizedWalletTable
          userWalletBalances={userWalletBalances}
          chosenExchange={chosenExchange}
          dailyChange={dailyChange}
        />
      </Box>

      <Box sx={graphicWrapper}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            gap: 3,
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            position: 'relative',
          }}
        > 
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
              height: '100dvh',
              maxHeight: '661px',
            }}
          > 
            {!selectedExchange?.exchangeCode ? (
              <Box sx={{ 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
              }}>
                <Loader isContentOverflow={true} />
              </Box>
            ) : (
              <TradingViewChart
                exchangeName={selectedExchange.exchangeCode}
                currentSymbol={chartSymbol}
              />
            )}
          </Box>
          
          {getTradingPanel()}
        </Box>
      </Box>

      <ActiveTradesComponent exchanges={exchanges} />
    </Box>
  );
};


import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  baseURL: 'https://api.trongrid.io',
  method: HttpMethod.Get,
});

export const validateWallet = async (wallet: string) => {    
  try {
    const response = await axios.request({
      url: `/v1/accounts/${wallet}`,
    });
      
    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

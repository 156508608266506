import { SxProps, Theme } from '@mui/material';

export const leftInner: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 3.5,
  flex: 1,
  justifyContent: 'space-between',
};
